import React, { FunctionComponent } from 'react';
import { Row } from 'styled-bootstrap-grid'

import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import LazyImage from 'view/components/content/LazyImage';
import { useBreakpoints } from 'features/hooks'

import Collage1 from 'assets/images/about-us/collage-photo-1.jpg';
import Collage2 from 'assets/images/about-us/collage-photo-2.jpg';
import Collage3 from 'assets/images/about-us/collage-photo-3.jpg';
import Collage4 from 'assets/images/about-us/collage-photo-4.jpg';
import Collage5 from 'assets/images/about-us/collage-photo-5.jpg';


import {
  CollageContainer,
  TextCol,
  Text,
  Hashtag,
  PhotoCollage,
  PhotoCol,
  Photo1,
  Photo1Collage,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
} from './styles';


export const PhotoCollageComponent: FunctionComponent = () => {
  const { isLarge } = useBreakpoints();

  return <CollageContainer>
    <Container>
      <Row alignItems="start">
        <TextCol lg={9} md={12} sm={12} >
          <h2>Let's #makehistory</h2>
          <Text><MultilingualText tKey="collageText" area="aboutUs" /></Text>
          <Hashtag>#workinPMI</Hashtag>
        </TextCol>
        {isLarge &&
          <PhotoCol col={3}>
            <LazyImage src={Collage1} sccss={Photo1} alt="" />
          </PhotoCol>
        }
      </Row>
    </Container>
    <PhotoCollage>
      <LazyImage src={Collage2} sccss={Photo2} alt="" />
      <LazyImage src={Collage3} sccss={Photo3} alt="" />
      <LazyImage src={Collage4} sccss={Photo4} alt="" />
      <Photo5 backImage={Collage5} />
      {!isLarge &&
        <LazyImage src={Collage1} sccss={Photo1Collage} alt="" />
      }
    </PhotoCollage>
  </CollageContainer>;

};
