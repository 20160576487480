import { useState, useEffect } from 'react';

export const useTopImage = (imageSrc) => {
  const [topImage, setTopImage] = useState<string>('');

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = imageSrc;
    imageToLoad.onload = () => {
      setTopImage(imageSrc);
    };
  }, [imageSrc]);

  return topImage;
};
