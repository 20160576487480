import styled from 'styled-components';
import { media } from 'core/theme';

export const HeaderPrivacy = styled.h2`
  font-weight: 400;
  margin-top: 4.375rem;
  margin-bottom: 2.5rem;

  ${ media.down('md') } {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
`;

export const ContentPrivacy = styled.div`
  margin-bottom: 3.75rem;
  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 2rem;
  }
 a {
    text-decoration: unset;
    color:${ props => props.theme.colors.blueButtonBackground };
  }
  ul {
    li::before {
      content: '\\2022';
      color: ${(props) => props.theme.colors.blueButtonBackground};
      font-weight: bold;
      display: inline-block;
      width: 2.3rem;
      margin-left: -2.3rem;
    }
  }
  ${ media.up('md') } {
    margin-bottom: 9.375rem;
  }

  p {
    line-height: 1.75;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  ol {
    list-style: none;
    counter-reset: custom-counter;
    margin-top: 0.5rem;

    li {
      counter-increment: custom-counter;
      margin-bottom: 1rem;

      ::before {
        color: unset;
        font-weight: normal;
        content: '(' counter(custom-counter) ')';
      }
    }
  }
`;
export  const CustomTable = styled.table`
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%;

  ${ media.down('md') } {
    font-size: 0.7rem;
  }

  ul {
    ${ media.down('md') } {
      padding-left: 0.375rem;
    }
  }

  li::before {
    ${ media.down('md') } {
      width: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  tr, tbody {

    vertical-align: baseline;
  }

  td {
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 3px solid ${(props) => props.theme.colors.blueButtonBackgroundHover};
    border-left: 1px solid ${(props) => props.theme.colors.lines};
    border-right: 1px solid ${(props) => props.theme.colors.lines};

    ${ media.down('md') } {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

  }

  tr:first-child td:first-child {
    border: 1px solid ${(props) => props.theme.colors.links};
    background: ${(props) => props.theme.colors.links};
    color: ${(props) => props.theme.colors.lightText};
  }

  tr:first-child td {
    color: ${(props) => props.theme.colors.lightText};
    border: 1px solid ${(props) => props.theme.colors.blueButtonBackground};
    background: ${(props) => props.theme.colors.blueButtonBackground};
  }

  tr td:first-child {
    border-bottom: 3px solid ${(props) => props.theme.colors.links};
  }
`;