import React, { FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid'

import { useMultilingualText } from 'features/hooks';
import { IRecruitmentStageComponentProps } from 'view/pages/recruitment/interfaces';
import { RStageContainer, ImageCol, Image, StageNumber, TitleCol, Title, DescCol, Description } from './styles';

export const RecruitmentStageComponent: FunctionComponent<IRecruitmentStageComponentProps> = ({ stage, isLast, id }) => {
  const { image } = stage;

  const recruitmentHeader = useMultilingualText(`recruitmentProcess.${id}.header`, 'recruitment');
  const recruitmentName = useMultilingualText(`recruitmentProcess.${id}.name`, 'recruitment');
  const recruitmentDescription = useMultilingualText(`recruitmentProcess.${id}.description`, 'recruitment');

  return <RStageContainer isLast={isLast}>
    <Row>
      <Col lgOffset={1} lg={2} mdOffset={3} md={3} smOffset={6} sm={3}>
        <StageNumber>{recruitmentHeader}</StageNumber>
      </Col>
    </Row>
    <Row>
      <ImageCol lg={1} md={2} sm={2}>
        <Image src={image} />
      </ImageCol>
      <TitleCol lg={5} md={10} sm={10}>
        <Title>{recruitmentName}</Title>
      </TitleCol>
      <DescCol lg={5} md={12} sm={12}>
        <Description>{recruitmentDescription}</Description>
      </DescCol>
    </Row>
  </RStageContainer>;
};