import React, { FunctionComponent } from 'react';
import { getOnlyMenuRoutes } from 'core/router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { languageSelect } from 'features/language'
import { IRoute } from 'core/router/router';
import {
  Menu as StyledMenu, MenuDivider,
  MenuWrapper
} from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'
import { MenuItem, SubMenu } from './';

const Menu: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const { t } = useTranslation("common");

  const getMultilingualItem = (key) => t(key);

  const getPath = (route, subRoute = {} as IRoute) => {
    const isEnglish = lang === 'en';
    const currentRoute = isEnglish ? route.path.find(x => x.startsWith('/en/')) : route.path.find(x => !x.startsWith('/en/'));
    let result = currentRoute;
    if (subRoute.path)
      result += subRoute.path[0]
    return result
  }
  return <MenuWrapper>
    <StyledMenu>
      {getOnlyMenuRoutes().map((item: IRoute, idx: number) => {
        if (item.children !== undefined) {
          return <SubMenu key={idx} title={item.menu.title} link={getPath(item)} >
            {item.children.filter(item => item === 'divider' || item.menu.visible === true).map((subItem: IRoute | 'divider', subIdx: number) => {
              return subItem === 'divider' ? <MenuDivider key={subIdx} /> : <MenuItem key={subIdx} link={getPath(item, subItem)} itemText={getMultilingualItem(subItem.menu.title)}>
                <MultilingualText tKey={subItem.menu.title} area="common" /></MenuItem>;
            })}
          </SubMenu>;
        }
        return <MenuItem key={idx} link={getPath(item)} itemText={getMultilingualItem(item.menu.title)}>
          <MultilingualText tKey={item.menu.title} area="common" />
        </MenuItem>;
      })}
    </StyledMenu>
  </MenuWrapper>;
};

export default Menu;
