export const whereWorking = {
  whereWorkingLoaded :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/general-information',
  },
  whereWorkingNavigation: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  whereWorkingLinkTo: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  whereWorkingOffers: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  }
}