import * as React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { ContactInfoUl, InfoAdresUl, PhoneIconBlue } from '../styles';
import GTM from "features/services";


export const ContactInfo = (props) => {

  return <Row>
    <Col lg={7} xs={12} md={7} >
      <InfoAdresUl>
        <li>{props.businessName}</li>
        <li>{props.addres}</li>
        <li>{props.secondAdress} </li>
        <li>{props.postcode}</li>
      </InfoAdresUl>
    </Col>
    <Col lg={5} xs={12} md={5}>
      <ContactInfoUl>
        <li>
          <PhoneIconBlue />
          <a onMouseUp={() => GTM('contactPageCall', `${props.businessName}`, `tel:${props.phoneNamber}`)} href={`tel:${props.phoneNamber}`}>{props.phoneNamber}</a>
        </li>
      </ContactInfoUl>
    </Col>
  </Row>;

};