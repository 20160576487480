import styled from 'styled-components';
import { media } from 'core/theme/media';
import arrow from 'assets/images/home/frame-c-arrow-right.svg';
import arrowWhite from 'assets/images/home/frame-c-arrow-right-white.svg';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  width: 100%;
  height: 3.5rem;
  font-family: Poppins;
  font-size: 2.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 1.875rem;
  ${media.down('md')} {
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 3.75rem;
    letter-spacing: -0.3px;
  }
`;
export const Description = styled.div`
  white-space: pre-line;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 3.125rem;
  ${media.down('sm')} {
    font-size: 0.875rem;
    height: auto;
    width: 100%;
    margin-bottom: 1.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;
export const AllOffers = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 35.625rem;
  height: 11.25rem;
  background-color: ${(props) => props.theme.colors.blueButtonBackground};
  height: 11.25rem;
  margin-bottom: 9.375rem;
  ${media.down('sm')} {
    width: 100%;
    margin-bottom: 3.75rem;
    height: 5rem;
    margin-top: 1.875rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) {
    width: 100%;
  }
`;
export const AllOffersText = styled.div`
  max-width: 10.5rem;
  text-transform: uppercase;
  margin-top: 4.375rem;
  margin-left: 4.375rem;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.lightText};
  ${media.down('sm')} {
    font-size: 0.875rem;
    width: 7.313rem;
    margin-left: 1.875rem;
    margin-top: 1.438rem;
  }
`;
export const AllOffersCounter = styled.div`
  cursor: pointer;
  margin-left: 8rem;
  margin-top: 3.625rem;
  width: 4.313rem;
  height: 4rem;
  font-family: Poppins;
  font-size: 2.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.colors.lightText};
  ${media.down('sm')} {
    margin-top: 1.125rem;
    font-size: 1.875rem;
    margin-left: 3.938rem;
    width: 2.25rem;
    height: 2.688rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) {
    margin-left: 3rem;
  }
`;
export const AllOffersArrow = styled.div`
  margin-left: 1.875rem;
  margin-top: 4.063rem;
  background-image: url(${arrow});
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: 3rem 3rem;
  transition: 0.1s;
  ${AllOffers}:hover & {
    background-image: url(${arrowWhite});
  }
  ${media.down('sm')} {
    margin-top: 1.75rem;
    font-size: 1.875rem;
    margin-left: 1.938rem;
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem 1.5rem;
  }
`;
