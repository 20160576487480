import styled, { css } from 'styled-components';
import { media } from 'core/theme/media';
import { Row } from 'styled-bootstrap-grid';
import { ContainerVariants } from 'core/theme/'

export const MainRow = styled(Row)`
margin-top: 6.25rem;
padding-bottom: 10rem;

${media.down('md')}{
  padding-bottom: 0;
  margin-top: 0;
}
`,
  JobDescriptionParagraph = styled.div`
  margin: 10px 0;
`,
  Header = styled.h3<{ isNotFirst: boolean }>`
  margin-bottom: 1.875rem;
  margin-top: 0;
  ${(props) => props.isNotFirst && `
  margin-top: 3.125rem;
  `}
`,
  JobDescription = styled.div`
  margin: 0;
  min-height: 79rem;
  ${media.down('md')}{
    min-height: auto;
  }
  ${media.down('sm')}{
    font-size: 0.875rem;
  }
`,
  ImageBox = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: -18.1rem;
  z-index: -1;
  width: calc(calc(100% + calc(calc(100vw - ${ContainerVariants.default.maxWidth.xl}px)* 0.5)) - 38px);
  ${media.down('md')}{
    display: none;
    height: 0;
  }
  `,
  Image = css`
  width: 100%;
  `,
  Text = styled.div`
  z-index: 100;
  color: ${(props) => props.theme.colors.lightText};
  position: absolute;
  top: 16%;
  left: 5rem;
  `,
  Quote = styled.h3`
  width: 16.625rem;
  margin: 0;
  padding-top: 2.5rem;
  padding-bottom: 2.25rem;
  `,
  Name = styled.p`
  font-size: ${(props) => props.theme.signature.fontSize};
  font-family: ${(props) => props.theme.signature.font};
  line-height: 0.67;
  margin: 0;
  padding-bottom: 0.875rem;
  `,
  Position = styled.p`
  font-size: 0.938rem;
  margin: 0;
  `,
  JoinUsBannerBox = styled.div`
  margin-bottom: 9.375rem;
  
  ${media.down('md')}{
    margin-bottom: 0;
  }
  `;
