import styled from 'styled-components';
import { media } from '../../../../core/theme';

export const ImageBox = styled.div`

  height: auto;
  width: 845px;
  margin-top: -36.4rem;
  margin-left: 43rem;

  ${ media.down('md') } {
    margin-top: 2.5rem;
    margin-left: 0;
  }

  ${ media.down('sm') } {
    margin-left: -30px;
    width: 100vw;
  }
`;
export const WrapperDescriptionHappy = styled.div`
  margin-top: 40px;
  max-width: 570px;
  white-space: pre-wrap;
  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 2rem;
    padding-left:  1.3rem;
  }

  li::before {
    content: '\\2022';
    color:#1d9fd4;
    font-weight: bold;
    display: inline-block;
    width: 1.3rem;
    margin-left: -1.3rem;
  }

  h2 {
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  p {
    margin: 20px 0;
  }

  ${ media.up('md') } {
    margin-top: 150px;

    h2 {
      letter-spacing: -0.46px;
      font-weight: 300;
    }
    h3 {
      margin: 50px 0;
    }
    p {
      margin: 30px 0;
    }
    ul {
      margin-bottom: 55px;
    }

  }
`;
