import React, {FunctionComponent, useEffect} from 'react';
import {TopBannerComponent} from "./TopBanner/topBanner";
import Container from "../../components/content/container";
import {Col, Row} from "styled-bootstrap-grid";
import {Header, WrapperTextBenefit, WrapperBenefits} from "./styles";
import MultilingualText from "../../components/content/MultilingualText/MultilingualText";
import {HowWeWorkDescription} from "./HowWeWorkDescription/HowWeWorkDescription";
import {EmployeePrograms} from "./EmployeePrograms/EmployeePrograms";
import {Benefits} from "../teams/components/Benefits/Benefits";
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";
import {useLocation} from "react-router-dom";
import GTM from "features/services";




export const HowWeWorkPage: FunctionComponent = () => {
    const {lang} = useSelector(languageSelect);
    const location = useLocation();
    useEffect(() => {
        GTM('howWeWorkLoaded', undefined, undefined, location.pathname);
    }, [lang]);

    return <>
        <TopBannerComponent>
        </TopBannerComponent>
        <Container>
            <Row>
                <Col col={12}>
                    <Header><MultilingualText tKey="description" area="howWeWork"/></Header>
                </Col>
            </Row>
        </Container>
        <HowWeWorkDescription/>
        <EmployeePrograms/>

        <WrapperBenefits>
            <Container>
                <WrapperTextBenefit>
                    <h3><MultilingualText tKey="titleBenefit" area="howWeWork"/></h3>
                    <p><MultilingualText tKey="descriptionBenefit" area="howWeWork"/></p>
                </WrapperTextBenefit>
            </Container>
            <Benefits/>
        </WrapperBenefits>

    </>
}