import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import { Col, Row } from 'styled-bootstrap-grid';
import Quote from '../../../../assets/images/shared/quote.png';
import FirstPersoneImg from 'assets/images/employee-programs/review-salary-worker-first.png';
import SecondPersoneImg from 'assets/images/employee-programs/review-salary-worker-second.jpg';
import ThirdPersoneImg from 'assets/images/employee-programs/review-salary-worker-third.jpg';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';
import { RowItemReview, ItemReviewImg, ItemReviewDescription, ItemReviewIcon } from './styles';
import styled from 'styled-components';
import { media } from 'core/theme/media';

export const RowTitleWorkersSalary = styled(Row)`
  margin-top: 50px;
  margin-bottom: 40px;

  h2 {
    line-height: normal;
    letter-spacing: -0.3px;
    font-weight: normal;
  }

  ${ media.up('md') } {
    margin: 100px 0;

    h2 {
      font-weight: 300;
      letter-spacing: -0.46px;
    }
  }
`;
export const ReviewsWorkersSalary: FunctionComponent = () => {

  return <>
    <Container>
      <RowTitleWorkersSalary><Col sm={ 12 } md={ 12 } lg={ 7 }>
        <h2><MultilingualText tKey="title-workers-salary" area="equal_salary" /></h2>
      </Col>
      </RowTitleWorkersSalary>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={ true }>
          <ItemReviewImg backgroundImage={ FirstPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="first-person-desc" area="equal_salary" /></p>

          </ItemReviewDescription>
        </Col>

      </RowItemReview>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={ true }>
          <ItemReviewImg backgroundImage={ SecondPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="second-person-desc" area="equal_salary" /></p>

          </ItemReviewDescription>
        </Col>

      </RowItemReview>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={ true }>
          <ItemReviewImg backgroundImage={ ThirdPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="third-person-desc" area="equal_salary" /></p>
          </ItemReviewDescription>
        </Col>

      </RowItemReview>
    </Container>

  </>;
};
