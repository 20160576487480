import styled from 'styled-components';
import { media } from 'core/theme/media';
import Container from 'view/components/content/container';
import { Row } from 'styled-bootstrap-grid';
import icon from 'assets/images/icons/arrow-right.svg';
import iconHover from 'assets/images/icons/arrow-right-blue-hover.svg';

export const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.blueButtonBackground};
  font-family: ${(props) => props.theme.font};
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    border: 0;
  }
`;

const arrowIcon = styled.div`
  width: 2.125rem;
  content: url(${icon});

  ${media.down('md')} {
    width: 1.25rem;
  }
`;

export const BannerContainer = styled(Container)`
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    height: 22.5rem;
    width: 100%;

    ${media.down('md')} {
      height: auto;
    }
    ${media.down('sm')} {
      padding: 0;
    }
  `,
  InsideContainer = styled(Container)`
    height: 100%;

    ${media.down('md')} {
      padding: 3.5rem 1.875rem;
    }
    ${media.down('sm')} {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  `,
  BannerRow = styled(Row)`
    height: 100%;

    ${media.down('md')} {
      height: auto;
    }
  `,
  Button = styled.button`
    background-color: ${(props) => props.theme.colors.lightText};
    border: none;
    cursor: pointer;
    height: 6.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;

    &:focus {
      outline: none;
    }

    ${media.down('md')} {
      height: 3.75rem;
    }

    ${media.down('sm')} {
      padding: 0 1.375rem;
    }

    :hover ${arrowIcon} {
      content: url(${iconHover});
    }
  `,
  ButtonText = styled.h3`
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.blueButtonBackground};
    font-weight: 600;
    font-family: ${(props) => props.theme.font};
  `,
  ArrowIcon = arrowIcon,
  BigHeader = styled.h2`
    color: ${(props) => props.theme.colors.lightText};
    padding-bottom: 1.25rem;
  `,
  SmallHeader = styled.h3`
    color: ${(props) => props.theme.colors.lightText};
    width: 75%;
    min-width: 14.5rem;
    line-height: normal;

    ${media.down('md')} {
      height: auto;
      margin-bottom: 4rem !important;
    }
  `;
