import styled from 'styled-components';
import { BannerWrapper, InfoAdresUl } from '../../../contact/styles';
import { media } from 'core/theme';

export const InfoAdressDepartment = styled(InfoAdresUl)`
  margin-bottom: 1.875rem;

  li {
    line-height: 1.46;
  }

  ${media.up('sm')} {
    margin-top: 0.5rem;
  }

  ${media.up('lg')} {
    margin-top: 1.688rem;
  }
`;
export const BannerWrapperDepartment = styled(BannerWrapper)`
  margin-top: 1.875rem;
  padding: 0;
  border: unset;

  ${media.up('lg')} {
    margin-top: 6.25rem;
  }
`;
