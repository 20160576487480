import MainBannerFactoryKrakow from 'assets/images/locations/banner-factoryKrakow.jpg';
import MainBannerFactoryKrakowPhone from 'assets/images/locations/banner-factoryKrakow-mobile.jpg';
import placeholderKracow from 'assets/images/locations/placeholderKracowFactory.jpg';
import img1 from 'assets/images/locations/gallery/factory-krakow/img1.jpg';
import img2 from 'assets/images/locations/gallery/factory-krakow/img2.jpg';
import img3 from 'assets/images/locations/gallery/factory-krakow/img3.jpg';
import img4 from 'assets/images/locations/gallery/factory-krakow/img4.jpg';
import img5 from 'assets/images/locations/gallery/factory-krakow/img5.jpg';
import img6 from 'assets/images/locations/gallery/factory-krakow/img6.jpg';
import img7 from 'assets/images/locations/gallery/factory-krakow/img7.jpg';
import img8 from 'assets/images/locations/gallery/factory-krakow/img8.jpg';
import img9 from 'assets/images/locations/gallery/factory-krakow/img9.jpg';
import img10 from 'assets/images/locations/gallery/factory-krakow/img10.jpg';
import { ILocation } from './interface';
import { Theme } from 'core/theme';

export const FactoryKracow: ILocation = {
  key: 'krakowFactory',
  name: 'krakow-factory',
  slug: 'krakowFactory',
  bannerImagePhone: MainBannerFactoryKrakowPhone,
  bannerImageDesktop: MainBannerFactoryKrakow,
  addressLocation: {
    color: `${Theme.colors.location.yellow}`,
    iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.8152816656207!2d20.018746315885824!3d50.07102092251223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471645224d194871%3A0x85569064e45f65ed!2sPhilip%20Morris%20Polska%20S.A.!5e0!3m2!1spl!2spl!4v1600948285720!5m2!1spl!2spl',
    phoneNumber: '123 456 789',
  },
  teamsWorking: ['it', 'procurement', 'finances', 'people-culture', 'administration', 'production', 'legal-ethics-compliance'],
  gallery: {
    firstImg: img1,
    secondImg: img10,
    allImgGallery: [img1, img10, img2, img3, img4, img5, img6, img7, img8, img9],
  },
  workLooks: {
    image: placeholderKracow,
  },
};
