import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid'

import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { useTopImage } from 'features/hooks';

import imageDesktop from 'assets/images/recruitment/banner.jpg';
import imageMobile from 'assets/images/recruitment/bannerMobile.jpg';
import {
  TopBanner,
  PageTitle,
} from './styles';


export const TopBannerComponent: FunctionComponent = () => {
  const backgroundDesktop = useTopImage(imageDesktop);
  const backgroundMobile = useTopImage(imageMobile);

  return <div>
    <TopBanner imageDesktop={backgroundDesktop} imageMobile={backgroundMobile}>
      <Container >
        <Row alignItems="end">
          <Col lg={6} md={12} sm={12}>
            <PageTitle><MultilingualText tKey="title" area="recruitment" /></PageTitle>
          </Col>
        </Row>
      </Container>
    </TopBanner>
  </div>;

};