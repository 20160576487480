import React, { FunctionComponent } from 'react';

import { StyledLanguage, LangItem } from './styles';
import { LanguageHighOrderComponent } from '../LanguageHighOrderComponent'

const LanguageButtons: FunctionComponent<{ lang: string, onLanguageChange: Function }> = ({ lang, onLanguageChange }) => {

  const langugages = [{ shortcut: 'pl', name: 'Polski' }, { shortcut: 'en', name: 'English' }]

  const onClickHandler = (shortcut) => {
    if (shortcut === lang) return;
    onLanguageChange();
  }

  return <StyledLanguage>
    {langugages.map((language => <LangItem key={language.shortcut} onClick={() => onClickHandler(language.shortcut)} active={lang === language.shortcut}>
      {language.name}</LangItem>))}
  </StyledLanguage>;
}
export const Language: FunctionComponent = () => {


  return <LanguageHighOrderComponent renderComponent={(lang, onLanguageChange) =>
    <LanguageButtons lang={lang} onLanguageChange={onLanguageChange} />} />

};