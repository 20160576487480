import React, { FunctionComponent } from 'react';

import { Container as BootstrapContainer, GridThemeProvider } from 'styled-bootstrap-grid';
import { GridTheme, ContainerVariants, media } from 'core/theme';
import { IContainerProps, IContainerVariant, IStyledBootstrapContainerProps } from './interfaces';
import styled from 'styled-components';

const StyledBootstrapContainer = styled(BootstrapContainer)<IStyledBootstrapContainerProps>`
   padding-left: 1.875rem;
   padding-right: 1.875rem;
   
   ${media.up('sm')}{
      padding-left: ${props => props.leftPadding !== undefined && props.leftPadding !== null && (props.leftPadding + 'px')};
      padding-right: 0;
   }
`;

const Container: FunctionComponent<IContainerProps> = (props) => {

  const { type, right } = props;
  const containerVariant: IContainerVariant = ContainerVariants[type ? type : 'default'];

  const leftPadding: number = right === true && containerVariant.margin !== undefined ? containerVariant.margin : 0;

  return <GridThemeProvider gridTheme={Object.assign(GridTheme, { container: containerVariant })}>
    <StyledBootstrapContainer leftPadding={leftPadding} {...props} />
  </GridThemeProvider>;
};

Container.defaultProps = {
  type: 'default',
  right: false
};

export default Container;