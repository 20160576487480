export const teamData = {
  teamLoaded: {
    event: 'virtualPageView',
  },
  teamButtonClick: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  teamExpand: {
    event: 'backendMicroconversion',
    category: 'Clicks - expand',
  },
  teamCollapse: {
    event: 'backendMicroconversion',
    category: 'Clicks - collapse',
  },
  teamLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
  teamSeeMoreOffers: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
};
