import React, { useEffect, useState } from 'react';
import { CheckboxLabel, Main } from './styles';

export const FilterSection = ({ type, defaultSelected, header, options: availableOptions, onClick }) => {

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(defaultSelected);
    }, [defaultSelected]);

    return <Main>
        {header}
        <ul>
            {availableOptions.map((option, index) => (
                <CheckboxLabel key={`checkbox-label-${index}`}>
                    <input type="checkbox" id={option.id}
                        onChange={event => onClick(option.id, type, event, option.name)}
                        checked={selected.some(value => value === option.id)} />
                    <span>{option.name}</span>
                </CheckboxLabel>
            ))
            }
        </ul>
    </Main>;
};
