import styled from 'styled-components';
import {media} from 'core/theme/media';
import {ITopBanner} from '../interfaces';

export const TopBanner = styled.div<ITopBanner>`
          height: 43.75rem;
          width: 100%;
          background-image: url(${(props) => props.imageDesktop});
          background-size: cover;
          display: flex;
          align-items: flex-end;

          ${media.down('md')} {
            height: 27.5rem;
          }

          ${media.down('sm')} {
            background-image: url(${(props) => props.imageMobile});
          }
    `,
    PageTitle = styled.h1`
      color: ${(props) => props.theme.colors.lightText};
      line-height: normal;
      padding-bottom: 3.8rem;
      width: 91%;

      ${media.down('md')} {
        padding-bottom: 1rem;
        width: 84%;
      }
    `;
