import styled from 'styled-components';
import { media } from '../../../../core/theme';
import { Row } from 'styled-bootstrap-grid';

export const WrraperBunnerEqualSalary = styled.div`

  ${ media.up('md') } {
    max-height: 280px;
  }
`;
export const RowBannersHappyParent = styled(Row)`
  margin-top: 30px;
  margin-bottom: 60px;

  h3 {
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    margin: 20px 0;
  }

  ${ media.up('md') } {
    margin-top: 50px;
    margin-bottom: 150px;
    justify-content: space-between;

    h3 {
      margin: 0 0 30px 0;
      line-height: 1.46;
      letter-spacing: -0.28px;
    }
  }
`;
export const LogoInsideBanner = styled.img`
  max-height: 66px;

  ${ media.up('lg') } {
    max-height: 100px;
  }
`;
export const BannerImportantInitiatives = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 140px;
  border: solid 1px #c9d2e3;

  ${ media.up('lg') } {
    height: 280px;
  }
`;
