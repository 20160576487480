import styled from 'styled-components';
import Container from 'view/components/content/container';
import { Col } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';

interface IRow {
  isLast: boolean;
}

export const RStageContainer = styled(Container)<IRow>`
    padding-top: 2.938rem;
    padding-bottom: 3.5rem;
    ${(props) =>
      !props.isLast &&
      `
    border-bottom: 0.063rem solid ${props.theme.colors.lines};    
    `}
    ${media.down('sm')} {
      padding: 1.875rem 0;
    }
  `,
  ImageCol = styled(Col)`
    padding-left: 0;
    padding-right: 0;
  `,
  Image = styled.img`
    height: 4.125rem;
    width: auto;

    ${media.down('sm')} {
      height: 2.313rem;
      width: auto;
      margin-top: 0.7rem;
    }
  `,
  StageNumber = styled.div`
    line-height: 1.56;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.colors.lightBlue};
    padding-bottom: 0.625rem;
    margin-left: 3.69rem;
    width: 100%;

    ${media.down('md')} {
      padding-bottom: 0.313rem;
    }
  `,
  TitleCol = styled(Col)`
    padding-left: 0;
    padding-right: 0;
  `,
  Title = styled.h3`
    padding-left: 4.375rem;
    font-weight: 400;
    width: 25.875rem;
    white-space: break-spaces;
    margin: 0;
    ${media.down('md')} {
      padding-left: 0;
      line-height: normal;
    }
    ${media.down('sm')} {
      width: 100%;
    }

  `,
    SubTitle = styled(Title)`
    font-size: 16px;
      margin-top: 6px;
    `,
  DescCol = styled(Col)`
    ${media.down('md')} {
      padding: 0.8rem 0 0 0;
    }
  `,
  Description = styled.div`
    width: 100%;
    max-width: 56rem;
    white-space: pre-wrap;
  `;
