import styled from 'styled-components';
import {Row} from 'styled-bootstrap-grid';
import {media} from '../../../../core/theme';

export const RowItemReview = styled(Row)`
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;

          ${media.up('md')} {
            margin: 50px 0;
            max-width: 1070px;
          }
    `,
    ItemReviewImg = styled.img<{ backgroundImage: string }>`
      width: 100%;

      padding-top: 100%;
      background-image: url('${(props) => props.backgroundImage}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `,
    ItemReviewIcon = styled.img`
      width: 3.75rem;
      height: auto;
      margin-bottom: 14px;

      ${media.down('md')} {
        margin-bottom: 0;
        position: absolute;
        right: 87px;
        top: -63px;

      }

      ${media.down('sm')} {
        width: 1.875rem;
        right: 84px;
        top: -35px;
      }
    `,
    ItemReviewDescription = styled.div`
      white-space: pre-wrap;

      ${media.down('md')} {
        margin-top: 30px;
      }
    `,
    HeaderReviewsWorker = styled.h2`
      ${media.up('md')} {
        margin-bottom: 99px;
        margin-top: 152px;
      }
    `
;
