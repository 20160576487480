export const equalSalary = {
    equalSalaryLoaded :{
        event: 'virtualPageView',
        virtualPageView: '/how-we-work/equal-salary',
    },
    equalSalaryNavigation: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    equalSalaryLinkTo: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    equalSalaryVideoEnters: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie enters',
    },
    equalSalaryVideoPlays: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie plays',
    },
    equalSalaryVideoExits: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie exits',
    },
}