import React, {FunctionComponent, useEffect} from "react";
import {TopBannerSmartWork} from "./TopBannerSmartWork/TopBannerSmartWork";
import {MainTextSmartWork} from "./MainTextSmartWork/MainTextSmartWork";
import {VideoSmartWork} from "./VideoSmartWork/VideoSmartWork";
import {ReviewsWorkers} from "./ReviewsWorkers/ReviewsWorkers";
import {BannersSmartWorkBottom} from "./BannersSmartWorkBottom/BannersSmartWorkBottom";
import {SmartWorkProcess} from "./SmartWorkProcess/SmartWorkProcess";
import {InclusionHappyParents} from "../happy-parents/InclusionHappyParents/InclusionHappyParents";
import GTM from "features/services";
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";
import {useLocation} from 'react-router-dom';

export const SmartWork: FunctionComponent = () => {
    const {lang} = useSelector(languageSelect);
    const location = useLocation();
    useEffect(() => {
        GTM('smartWorkLoaded', undefined, undefined, location.pathname);
    }, [lang]);

    return <>
        <TopBannerSmartWork/>
        <MainTextSmartWork/>
        <VideoSmartWork/>
        <SmartWorkProcess/>
        <ReviewsWorkers/>
        <InclusionHappyParents/>
        <BannersSmartWorkBottom/>
    </>
}