export const officeWarszawa = {
  warsaw :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/warsaw-office',
  },
  warsawClickMap : {
    event: 'backendMicroconversion',
    category: 'Clicks - map',
  },
  warsawClickOffer: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  warsawClickMovieOpen: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  warsawClickMoviePlay: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  warsawClickMovieExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  warsawClickSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  warsawLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
}