import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  const ref: any = useRef(history.location);
  useEffect(() => {
    const listen = history.listen(() => {
      if (!ref.current) return null;
      if (ref.current.pathname !== history.location.pathname) {
        window.scrollTo(0, 0);
      }
      ref.current = history.location;
    });
    return () => {
      listen();
    };
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);
