import React, { FunctionComponent } from 'react';
import { Col } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { useMultilingualText } from 'features/hooks';
import { IJoinUsBanner } from './interfaces';
import {
  ArrowIcon,
  BannerContainer,
  BannerRow,
  BigHeader,
  Button,
  ButtonText,
  InsideContainer,
  SmallHeader, StyledLink
} from './styles';

export const JoinUsBanner: FunctionComponent<IJoinUsBanner> = ({ tKey, area, link, position, gtmPosition }) => {
  const bannerHeader = useMultilingualText(`${tKey}.header`, area);
  const bannerSmallText = useMultilingualText(`${tKey}.smallText`, area);
  const bannerButtonText = useMultilingualText(`${tKey}.buttonText`, area);

  return <BannerContainer type="large">
    <InsideContainer>
      <BannerRow alignItems="center">
        <Col lg={7} md={10} sm={12}>
          <BigHeader>{bannerHeader}</BigHeader>
          <SmallHeader>{bannerSmallText}{position}</SmallHeader>
        </Col>
        <Col lg={5} md={6} sm={12}>
          <StyledLink href={link} onMouseUp={() => GTM('recruitmentOffersBannerClick', `${bannerButtonText} ${gtmPosition ? gtmPosition : `(${bannerHeader})`}`, link)}>
            <Button>
              <ButtonText>{bannerButtonText}</ButtonText>
              <ArrowIcon />
            </Button>
          </StyledLink>
        </Col>
      </BannerRow>
    </InsideContainer>
  </BannerContainer>;
};
