import React, { useState, useRef, useEffect, FunctionComponent } from "react";

import { useOnScreen, useBreakpoints } from 'features/hooks';
import { Placeholder } from 'view/components/content/LazyImage/styles';

export const LazyloadBackgroundImage: FunctionComponent<{ image: string, width?: string, height?: string, children: any }> =
  ({ image, width, height, children }) => {

    const refPlaceholder = useRef<HTMLDivElement>(null);
    const [shownOnce, setShownOnce] = useState(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { isLarge } = useBreakpoints();
    const isVisible = useOnScreen(refPlaceholder, isLarge ? '-300px' : '-30px');


    useEffect(() => {
      if (!isVisible || shownOnce) return;
      setShownOnce(true);
      const imageToLoad = new Image();
      imageToLoad.src = image;
      imageToLoad.onload = () => {
        setIsLoaded(true);
      };
    }, [isVisible])

    return isLoaded ? children : (
      <Placeholder ref={refPlaceholder} height={height ? height : 'auto'} width={width ? width : 'auto'} />
    );
  };
