import * as React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { BrowLine, DataNews, HeaderNews, ImgNews, ShortContent } from '../styles';

export const ShortDescription = (props) => {

  return <Row>
    <Col lg={ 5 }>
      <DataNews>{ props.data }</DataNews>
      <HeaderNews>{ props.title }</HeaderNews>
      <ShortContent>{ props.content }</ShortContent>
    </Col>
    <Col lg={ 7 } >
      <ImgNews src={ props.MainImg } />
    </Col>
    <Col>
      <BrowLine />
    </Col>
  </Row>;
};