import React, {FunctionComponent, useEffect} from 'react';
import { Main } from '../about-us/styles';
import { TopBannerEqualSalary } from './TopBannerEqualSalary/TopBannerEqualSalary';
import { MainTextEqualSalary } from './MainTextEqualSalary/MainTextEqualSalary';
import { ReviewsWorkersSalary } from './ReviewsWorkersSalary/ReviewsWorkersSalary';
import { BannersEqualSalaryBottom } from './BannersEqualSalaryBottom/BannersEqualSalaryBottom';
import { InclusionHappyParents } from '../happy-parents/InclusionHappyParents/InclusionHappyParents';
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";
import {useLocation} from "react-router-dom";
import GTM from "features/services";

export const EqualSalaryPage: FunctionComponent = () => {
  const {lang} = useSelector(languageSelect);
  const location = useLocation();
  useEffect(() => {
    GTM('equalSalaryLoaded', undefined, undefined, location.pathname);
  }, [lang]);

  return <Main>
    <TopBannerEqualSalary />
    <MainTextEqualSalary />
    <ReviewsWorkersSalary />
    <InclusionHappyParents />
    <BannersEqualSalaryBottom />
  </Main>;
};
