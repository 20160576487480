import React, { FunctionComponent } from "react";
import Modal from 'react-modal';

import GTM from 'features/services';
import ModalClose from 'assets/images/about-us/modal-close.png';
import { Main, ModalButton } from './styles';

const customStyles = {
  content: {
    padding: 0,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'hidden',
    width: '100vw',
    height: '45vw',
    maxHeight: '56.25rem',
    maxWidth: '100rem',
    border: 0,
  },
  overlay: {
    backgroundColor: 'rgba(25,25,25,0.89)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 999,
  },
};

Modal.setAppElement('body')

type Props = {
  open: boolean,
  closeModal: () => void;
  youtubeVideoId: string;
  header: string;
  GTMName:string
}

export const VideoModal: FunctionComponent<Props> = ({ GTMName,youtubeVideoId, open, closeModal, header }: Props) => {

  let ytplayer;

  const onPlayerStateChange = () => {
    const state = ytplayer.getPlayerState();
    if (state === 1) {
      GTM(`${GTMName}`, header, `https://www.youtube.com/embed/${youtubeVideoId}`)
    }
  }

  const onYouTubeIframeAPIReady = () => {
    ytplayer = new YT.Player('ytplayer', {
      events: {
        'onStateChange': onPlayerStateChange
      }
    })
  };

  return (
    <Main>
      <ModalButton notDisplayed={!open} onMouseUp={() => closeModal()} src={ModalClose} />
      <Modal
        isOpen={open}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <iframe id="ytplayer" data-type="text/html" title={`video-${youtubeVideoId}`} style={{ border: 0 }} width="100%" height="100%" src={`https://www.youtube.com/embed/${youtubeVideoId}?enablejsapi=1`}
          data-frameborder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture enablejsapi"
          data-allowfullscreen onLoad={onYouTubeIframeAPIReady} ></iframe>
      </Modal>
    </Main>
  );
};
