import { media } from 'core/theme/media';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const Main = styled.div`
    overflow-x: hidden;
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  `,
  ContentRow = styled(Row)`
    padding-top: 2.1875rem;
    width: 100vw;
    ${media.up('md')} {
      margin: 0;
    }
    ${media.down('md')} {
      padding-top: 0;
    }
  `,
  BannerBox = styled.div`
    margin-top: 5rem;
    ${media.down('md')} {
      margin-top: 1.938rem;
    }
  `,
  PagingRow = styled(Row)`
    width: 100%;
    margin: 0;
  `,
  ProcessBox = styled.div`
    padding-bottom: 10rem;
    ${media.down('md')} {
      padding-bottom: 2.563rem;
    }
  `,
  FiltersCol = styled(Col)`
    padding-left: 0;
  `;
