import {IhistoryImg} from './interfaces'
import HistoryImg1 from "../../../assets/images/history/s4.jpg";
import HistoryImg2 from "../../../assets/images/history/s5.jpg";
import HistoryImg3 from "../../../assets/images/history/s6.jpg";
import HistoryImg4 from "../../../assets/images/history/s7.jpg";
import HistoryImg5 from "../../../assets/images/history/s26.jpg";
import HistoryImg6 from "../../../assets/images/history/s27.jpg";
import HistoryImg7 from "../../../assets/images/history/s28.jpg";
import HistoryImg8 from "../../../assets/images/history/s29.jpg";
import HistoryImg9 from "../../../assets/images/history/s30.jpg";
import HistoryImg10 from "../../../assets/images/history/s36.jpg";
import HistoryImg11 from "../../../assets/images/history/s37.jpg";
import HistoryImg12 from "../../../assets/images/history/s38.jpg";
import HistoryImg13 from "../../../assets/images/history/s39.jpg";
import HistoryImg14 from "../../../assets/images/history/s40.jpg";
import HistoryImg15 from "../../../assets/images/history/s42.jpg";
import HistoryImg16 from "../../../assets/images/history/s43.jpg";
import HistoryImg17 from "../../../assets/images/history/s44.jpg";
import HistoryImg18 from "../../../assets/images/history/s50.jpg";
import HistoryImg19 from "../../../assets/images/history/s51.jpg";
import HistoryImg20 from "../../../assets/images/history/s52.jpg";
import HistoryImg21 from "../../../assets/images/history/s53.jpg";
import HistoryImg22 from "../../../assets/images/history/s54.jpg";
import HistoryImg23 from "../../../assets/images/history/s58.jpg";
import HistoryImg24 from "../../../assets/images/history/s59.jpg";
import HistoryImg25 from "../../../assets/images/history/s60.jpg";
import HistoryImg26 from "../../../assets/images/history/s61.jpg";
import HistoryImg27 from "../../../assets/images/history/s62.jpg";
import HistoryImg28 from "../../../assets/images/history/s63.jpg";
import HistoryImg29 from "../../../assets/images/history/s64.jpg";
import HistoryImg30 from "../../../assets/images/history/s65.jpg";
import HistoryImg31 from "../../../assets/images/history/s66.jpg";
import HistoryImg32 from "../../../assets/images/history/s68.jpg";
import HistoryImg33 from "../../../assets/images/history/s102.jpg";
import HistoryImg34 from "../../../assets/images/history/s103.jpg";
import HistoryImg35 from "../../../assets/images/history/s104.jpg";
import HistoryImg36 from "../../../assets/images/history/s105.jpg";

import HistoryENImg1 from "../../../assets/images/history/EN/s4.jpg";
import HistoryENImg2 from "../../../assets/images/history/EN/s5.jpg";
import HistoryENImg3 from "../../../assets/images/history/EN/s6.jpg";
import HistoryENImg4 from "../../../assets/images/history/EN/s7.jpg";
import HistoryENImg5 from "../../../assets/images/history/EN/s26.jpg";
import HistoryENImg6 from "../../../assets/images/history/EN/s27.jpg";
import HistoryENImg7 from "../../../assets/images/history/EN/s28.jpg";
import HistoryENImg8 from "../../../assets/images/history/EN/s29.jpg";
import HistoryENImg9 from "../../../assets/images/history/EN/s30.jpg";
import HistoryENImg10 from "../../../assets/images/history/EN/s36.jpg";
import HistoryENImg11 from "../../../assets/images/history/EN/s37.jpg";
import HistoryENImg12 from "../../../assets/images/history/EN/s38.jpg";
import HistoryENImg13 from "../../../assets/images/history/EN/s39.jpg";
import HistoryENImg14 from "../../../assets/images/history/EN/s40.jpg";
import HistoryENImg15 from "../../../assets/images/history/EN/s42.jpg";
import HistoryENImg16 from "../../../assets/images/history/EN/s43.jpg";
import HistoryENImg17 from "../../../assets/images/history/EN/s44.jpg";
import HistoryENImg18 from "../../../assets/images/history/EN/s50.jpg";
import HistoryENImg19 from "../../../assets/images/history/EN/s51.jpg";
import HistoryENImg20 from "../../../assets/images/history/EN/s52.jpg";
import HistoryENImg21 from "../../../assets/images/history/EN/s53.jpg";
import HistoryENImg22 from "../../../assets/images/history/EN/s54.jpg";
import HistoryENImg23 from "../../../assets/images/history/EN/s58.jpg";
import HistoryENImg24 from "../../../assets/images/history/EN/s59.jpg";
import HistoryENImg25 from "../../../assets/images/history/EN/s60.jpg";
import HistoryENImg26 from "../../../assets/images/history/EN/s61.jpg";
import HistoryENImg27 from "../../../assets/images/history/EN/s62.jpg";
import HistoryENImg28 from "../../../assets/images/history/EN/s63.jpg";
import HistoryENImg29 from "../../../assets/images/history/EN/s64.jpg";
import HistoryENImg30 from "../../../assets/images/history/EN/s65.jpg";
import HistoryENImg31 from "../../../assets/images/history/EN/s66.jpg";
import HistoryENImg32 from "../../../assets/images/history/EN/s68.jpg";
import HistoryENImg33 from "../../../assets/images/history/EN/s102.jpg";
import HistoryENImg34 from "../../../assets/images/history/EN/s103.jpg";
import HistoryENImg35 from "../../../assets/images/history/EN/s104.jpg";
import HistoryENImg36 from "../../../assets/images/history/EN/s105.jpg";
export  const historyItemsPL : IhistoryImg[] = [
    {
        id:'1',
        url:HistoryImg1,
        nameAlt:'HistoryImg1'
    },
    {
        id: '2',
        url: HistoryImg2,
        nameAlt: 'HistoryImg2'
    },
    {
        id:'3',
        url:HistoryImg3,
        nameAlt:'HistoryImg3'
    },
    {
        id: '4',
        url: HistoryImg4,
        nameAlt: 'HistoryImg4'
    },
    {
        id:'5',
        url:HistoryImg5,
        nameAlt:'HistoryImg5'
    },
    {
        id: '6',
        url: HistoryImg6,
        nameAlt: 'HistoryImg6'
    },
    {
        id:'7',
        url:HistoryImg7,
        nameAlt:'HistoryImg7'
    },
    {
        id: '8',
        url: HistoryImg8,
        nameAlt: 'HistoryImg8'
    },
    {
        id:'9',
        url:HistoryImg9,
        nameAlt:'HistoryImg9'
    },
    {
        id:'10',
        url:HistoryImg10,
        nameAlt:'HistoryImg10'
    },
    {
        id: '11',
        url: HistoryImg11,
        nameAlt: 'HistoryImg11'
    },
    {
        id:'12',
        url:HistoryImg12,
        nameAlt:'HistoryImg12'
    },
    {
        id: '13',
        url: HistoryImg13,
        nameAlt: 'HistoryImg13'
    },
    {
        id:'14',
        url:HistoryImg14,
        nameAlt:'HistoryImg14'
    },
    {
        id: '15',
        url: HistoryImg15,
        nameAlt: 'HistoryImg15'
    },
    {
        id:'16',
        url:HistoryImg16,
        nameAlt:'HistoryImg16'
    },
    {
        id: '17',
        url: HistoryImg17,
        nameAlt: 'HistoryImg17'
    },
    {
        id:'18',
        url:HistoryImg18,
        nameAlt:'HistoryImg18'
    },
    {
        id: '19',
        url: HistoryImg19,
        nameAlt: 'HistoryImg19'
    },
    {
        id:'20',
        url:HistoryImg20,
        nameAlt:'HistoryImg20'
    },
    {
        id: '21',
        url: HistoryImg21,
        nameAlt: 'HistoryImg21'
    },
    {
        id:'22',
        url:HistoryImg22,
        nameAlt:'HistoryImg22'
    },
    {
        id: '23',
        url: HistoryImg23,
        nameAlt: 'HistoryImg23'
    },
    {
        id:'24',
        url:HistoryImg24,
        nameAlt:'HistoryImg24'
    },
    {
        id: '25',
        url: HistoryImg25,
        nameAlt: 'HistoryImg25'
    },
    {
        id:'26',
        url:HistoryImg26,
        nameAlt:'HistoryImg26'
    },
    {
        id:'27',
        url:HistoryImg27,
        nameAlt:'HistoryImg27'
    },
    {
        id: '28',
        url: HistoryImg28,
        nameAlt: 'HistoryImg28'
    },
    {
        id:'29',
        url:HistoryImg29,
        nameAlt:'HistoryImg29'
    },
    {
        id: '30',
        url: HistoryImg30,
        nameAlt: 'HistoryImg30'
    },
    {
        id:'31',
        url:HistoryImg31,
        nameAlt:'HistoryImg31'
    },
    {
        id:'32',
        url:HistoryImg32,
        nameAlt:'HistoryImg32'
    },
    {
        id:'33',
        url:HistoryImg33,
        nameAlt:'HistoryImg33'
    },
    {
        id: '34',
        url: HistoryImg34,
        nameAlt: 'HistoryImg34'
    },
    {
        id:'35',
        url:HistoryImg35,
        nameAlt:'HistoryImg35'
    },
    {
        id:'36',
        url:HistoryImg36,
        nameAlt:'HistoryImg36'
    },
]

export  const historyItemsEN : IhistoryImg[] = [
    {
        id:'1',
        url:HistoryENImg1,
        nameAlt:'HistoryENImg1'
    },
    {
        id: '2',
        url: HistoryENImg2,
        nameAlt: 'HistoryENImg2'
    },
    {
        id:'3',
        url:HistoryENImg3,
        nameAlt:'HistoryENImg3'
    },
    {
        id: '4',
        url: HistoryENImg4,
        nameAlt: 'HistoryENImg4'
    },
    {
        id:'5',
        url:HistoryENImg5,
        nameAlt:'HistoryENImg5'
    },
    {
        id: '6',
        url: HistoryENImg6,
        nameAlt: 'HistoryENImg6'
    },
    {
        id:'7',
        url:HistoryENImg7,
        nameAlt:'HistoryENImg7'
    },
    {
        id: '8',
        url: HistoryENImg8,
        nameAlt: 'HistoryENImg8'
    },
    {
        id:'9',
        url:HistoryENImg9,
        nameAlt:'HistoryENImg9'
    },
    {
        id:'10',
        url:HistoryENImg10,
        nameAlt:'HistoryENImg10'
    },
    {
        id: '11',
        url: HistoryENImg11,
        nameAlt: 'HistoryENImg11'
    },
    {
        id:'12',
        url:HistoryENImg12,
        nameAlt:'HistoryENImg12'
    },
    {
        id: '13',
        url: HistoryENImg13,
        nameAlt: 'HistoryENImg13'
    },
    {
        id:'14',
        url:HistoryENImg14,
        nameAlt:'HistoryENImg14'
    },
    {
        id: '15',
        url: HistoryENImg15,
        nameAlt: 'HistoryENImg15'
    },
    {
        id:'16',
        url:HistoryENImg16,
        nameAlt:'HistoryENImg16'
    },
    {
        id: '17',
        url: HistoryENImg17,
        nameAlt: 'HistoryENImg17'
    },
    {
        id:'18',
        url:HistoryENImg18,
        nameAlt:'HistoryENImg18'
    },
    {
        id: '19',
        url: HistoryENImg19,
        nameAlt: 'HistoryENImg19'
    },
    {
        id:'20',
        url:HistoryENImg20,
        nameAlt:'HistoryENImg20'
    },
    {
        id: '21',
        url: HistoryENImg21,
        nameAlt: 'HistoryENImg21'
    },
    {
        id:'22',
        url:HistoryENImg22,
        nameAlt:'HistoryENImg22'
    },
    {
        id: '23',
        url: HistoryENImg23,
        nameAlt: 'HistoryENImg23'
    },
    {
        id:'24',
        url:HistoryENImg24,
        nameAlt:'HistoryENImg24'
    },
    {
        id: '25',
        url: HistoryENImg25,
        nameAlt: 'HistoryENImg25'
    },
    {
        id:'26',
        url:HistoryENImg26,
        nameAlt:'HistoryENImg26'
    },
    {
        id:'27',
        url:HistoryENImg27,
        nameAlt:'HistoryENImg27'
    },
    {
        id: '28',
        url: HistoryENImg28,
        nameAlt: 'HistoryENImg28'
    },
    {
        id:'29',
        url:HistoryENImg29,
        nameAlt:'HistoryENImg29'
    },
    {
        id: '30',
        url: HistoryENImg30,
        nameAlt: 'HistoryENImg30'
    },
    {
        id:'31',
        url:HistoryENImg31,
        nameAlt:'HistoryENImg31'
    },
    {
        id:'32',
        url:HistoryENImg32,
        nameAlt:'HistoryENImg32'
    },
    {
        id:'33',
        url:HistoryENImg33,
        nameAlt:'HistoryENImg33'
    },
    {
        id: '34',
        url: HistoryENImg34,
        nameAlt: 'HistoryENImg34'
    },
    {
        id:'35',
        url:HistoryENImg35,
        nameAlt:'HistoryENImg35'
    },
    {
        id:'36',
        url:HistoryENImg36,
        nameAlt:'HistoryENImg36'
    },
]