import React, { FunctionComponent, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Col } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { languageSelect } from 'features/language'
import GTM from 'features/services';
import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

import TextComponent from 'view/components/content/TextComponent/TextComponent';
import { teamsData } from 'core/router/teams-data';
import { IMoreDivisions } from '../../interface'
import { HeaderSeeMore, Division, DivisionName, DivisionIcon, SeeMore, ArrowIcon, MoreDivisionRow } from './styles';

import ArrowDownIcon from 'assets/images/recruitment/question-arrow.svg';
import { useMultilingualText } from 'features/hooks';

export const MoreDivisions: FunctionComponent<IMoreDivisions> = ({ name }) => {
  const { lang } = useSelector(languageSelect);
  const history = useHistory();
  const location = useLocation();
  const [isListShort, setIsListShort] = useState(true);
  const filterDivisions = () => teamsData.filter(x => x.path !== name).slice(0, isListShort ? 4 : teamsData.length);
  const redirectText = useMultilingualText('redirect', 'common');
  const { t } = useTranslation("teams");
  const getMultilingualItem = (key) => t(key);

  const onCollapseExpandClick = () => {
    GTM((isListShort ? 'teamExpand' : 'teamCollapse'), `${getMultilingualItem(isListShort ? 'expand' : 'collapse')} (${getMultilingualItem('moreDivisionsHeader')})`, `${window.location.href}`);
    setIsListShort(!isListShort);
  }
  const onAnotherTeamClick = (division) => {
    GTM('teamLinkToAnother', `${redirectText}  ${division.name}`, `${window.location.host}${lang === 'en' ? '/en' : ''}/teams/${division.path}`);
    history.push(division.path)
  }
  return <Container>
    <MoreDivisionRow>
      <Col lg={6} md={12} sm={12}>
        <HeaderSeeMore><MultilingualText tKey="moreDivisionsHeader" area="teams" /></HeaderSeeMore>
      </Col>
      <Col lg={6} md={12} sm={12}>
        {filterDivisions().map((division) =>
          <Division key={division.id} onMouseUp={() => onAnotherTeamClick(division)}>
            <DivisionName><TextComponent>{division.name}</TextComponent></DivisionName>
            <DivisionIcon />
          </Division>
        )}
        <Division onMouseUp={onCollapseExpandClick}>
          {isListShort &&
            <SeeMore >
              <MultilingualText tKey="expand" area="teams" />
            </SeeMore>}
          {!isListShort &&
            <SeeMore >
              <MultilingualText tKey="collapse" area="teams" />
            </SeeMore>}
          <ArrowIcon src={ArrowDownIcon} isRotated={isListShort} />
        </Division>
      </Col>
    </MoreDivisionRow>
  </Container>
};
