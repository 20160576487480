import arrowDown from 'assets/images/icons/dropdown-arrow.svg';
import React from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={arrowDown} alt='' />
      </components.DropdownIndicator>
    )
  );
};

const dot = () => ({
  alignItems: 'center',
  display: 'flex',
  height: '2.2rem',

  ':before': {
    backgroundColor: '#ffffff',
    border: '1px solid #c9d2e3',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 0,
    display: 'block',
    fontSize: '1rem',
    color: '#000000',
    cursor: 'pointer',
  },
});

const colourStyles = {
  dropdownIndicator: (provided, { selectProps }) => ({
    ...provided,
    transform: selectProps.menuIsOpen && 'rotate(180deg)'
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: '#fff', padding: '0 0.4rem', borderRadius: 0, width: '4.5rem', boxShadow: 0,
    border: isFocused ? '1px solid #c9d2e3' : '1px solid #c9d2e3',
    '&:hover': {
      border: isFocused ? '1px solid #c9d2e3' : '1px solid #c9d2e3'
    }
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#eaeef4'
          : isFocused
            ? '#0074c2'
            : null,
      color: isDisabled
        ? '#000'
        : isSelected
          ? '#000'
          : isFocused
            ? '#fff' : '#000',
      cursor: isDisabled ? 'not-allowed' : isSelected ? 'default' : 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#eaeef4' : '#0884d7'),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles) => ({ ...styles, ...dot, }),
};

export const PMISelect = ({ value, options, onChange }) => {

  return <Select value={value} options={options} styles={colourStyles}
    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
    onChange={onChange} isSearchable={false} />;
};

