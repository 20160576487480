import { IBenefitsList } from '../../interface';

import certificates from 'assets/images/teams/benefits-icons/certificates.png';
import crossFunctionalProjects from 'assets/images/teams/benefits-icons/cross-functional-projects.png';
import employment from 'assets/images/teams/benefits-icons/employment.png';
import happyParents from 'assets/images/teams/benefits-icons/happy-parents.png';
import healthcare from 'assets/images/teams/benefits-icons/healthcare.png';
import internationality from 'assets/images/teams/benefits-icons/internationality.png';
import lifeInsurance from 'assets/images/teams/benefits-icons/life-insurance.png';
import location from 'assets/images/teams/benefits-icons/location.png';
import meals from 'assets/images/teams/benefits-icons/meals.png';
import multisport from 'assets/images/teams/benefits-icons/multisport.png';
import mybenefitCafeteria from 'assets/images/teams/benefits-icons/mybenefit-cafeteria.png';
import retirement from 'assets/images/teams/benefits-icons/retirement.png';
import volunteering from 'assets/images/teams/benefits-icons/volunteering.png';
import workingHours from 'assets/images/teams/benefits-icons/working-hours.png';
import workshops from 'assets/images/teams/benefits-icons/workshops.png';

export const BenefitsList: IBenefitsList[] = [
  {
    id: 'employment1',
    image: employment,
  },
  {
    id: 'workingHours2',
    image: workingHours,
  },
  {
    id: 'happyParents3',
    image: happyParents,
  },
  {
    id: 'certificates4',
    image: certificates,
  },
  {
    id: 'workshops5',
    image: workshops,
  },
  {
    id: 'crossFunctionalProjects6',
    image: crossFunctionalProjects,
  },
  {
    id: 'internationality7',
    image: internationality,
  },
  {
    id: 'volunteering8',
    image: volunteering,
  },
  {
    id: 'healthcare9',
    image: healthcare,
  },
  {
    id: 'lifeInsurance10',
    image: lifeInsurance,
  },
  {
    id: 'multisport11',
    image: multisport,
  },
  {
    id: 'retirement12',
    image: retirement,
  },
  {
    id: 'location13',
    image: location,
  },
  {
    id: 'mybenefitCafeteria14',
    image: mybenefitCafeteria,
  },
  {
    id: 'meals15',
    image: meals,
  },
];
