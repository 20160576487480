import bannerAcrossCountry from 'assets/images/locations/bannerAcrossCountry.jpg';
import bannerAcrossCountryPhone from 'assets/images/locations/banner-phone-AcrossCountry.jpg';
import placeholderAcrossCountry from 'assets/images/locations/placeholderAcrossCountry.jpg';
import { Theme } from 'core/theme';
import { ILocation } from './interface';
import img1 from 'assets/images/locations/gallery/across-country/img1.jpg';
import img2 from 'assets/images/locations/gallery/across-country/img2.jpg';
import img4 from 'assets/images/locations/gallery/across-country/img4.jpg';
import img3 from 'assets/images/locations/gallery/across-country/img3.jpg';
import img5 from 'assets/images/locations/gallery/across-country/img5.jpg';

export const AcrossCountry: ILocation = {
  key: 'other',
  bannerImagePhone: bannerAcrossCountryPhone,
  slug: 'other',
  addressLocation: {
    color: `${Theme.colors.location.turquoise} `,
    iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.8152816656207!2d20.018746315885824!3d50.07102092251223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471645224d194871%3A0x85569064e45f65ed!2sPhilip%20Morris%20Polska%20S.A.!5e0!3m2!1spl!2spl!4v1600948285720!5m2!1spl!2spl',
    phoneNumber: '123 456 789',
  },
  bannerImageDesktop: bannerAcrossCountry,
  gallery: {
    firstImg: img1,
    secondImg: img2,
    allImgGallery: [img1, img2, img4, img3, img5],
  },
  name: 'sales-poland',
  teamsWorking: ['sales'],
  workLooks: {
    image: placeholderAcrossCountry,
  },
};
