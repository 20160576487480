import styled from 'styled-components';
import { media } from 'core/theme/media';

import { ITopBanner } from '../../interfaces';

export const TopBanner = styled.div<ITopBanner>`
    height: 43.75rem;
    width: 100%;
    background-image: url(${(props) => props.imageDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    background-position: right;
    ${media.down('sm')} {
      background-image: url(${(props) => props.imageMobile});
      height: 27.5rem;
    }
  `,
  PageTitle = styled.h1`
    color: ${(props) => props.theme.colors.lightText};
    padding-bottom: 6rem;

    ${media.down('md')} {
      padding-bottom: 2.8rem;
    }
  `,
  VideoButton = styled.div`
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    cursor: pointer;
    height: 10rem;
    padding-left: 3.563rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    margin-left: 3.2rem;
    width: 92%;

    ${media.down('md')} {
      padding-left: 2.188rem;
      margin-left: 0;
      width: 100vw;
    }
    ${media.down('sm')} {
      height: 5rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
  `,
  ButtonIcon = styled.img`
    width: 3.125rem;
    height: auto;
    margin-right: 1.875rem;

    ${media.down('sm')} {
      margin-right: 0.938rem;
      width: 1.875rem;
    }
  `,
  ButtonText = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    color: ${(props) => props.theme.colors.lightText};

    ${media.down('sm')} {
      font-size: 0.875rem;
      line-height: 1.07;
    }
  `;
