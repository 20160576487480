import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import { useMultilingualArray } from 'features/hooks';
import { useLocationData } from 'features/hooks/useLocationData';
import { languageSelect } from 'features/language/';
import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { Divisions, MoreAbout, MoreAboutPhone, NameOfTeam, WrapperDivisions } from 'view/pages/locationPages/styles';

const TeamWorking = ({ id, area }) => {
  const { lang } = useSelector(languageSelect);
  const divisionsList = useMultilingualArray(`teamsWorking.${id}.divisions`, `locations_${area}`);

  return <WrapperDivisions>
    <NameOfTeam>
      <h2> <MultilingualText tKey={`teamsWorking.${id}.name`} area={`locations_${area}`} /></h2>
      <MoreAbout to={`${lang === 'en' ? '/en' : ''}/teams/${id}`}>
        <MultilingualText tKey="moreAboutTeam" area="whereWork" /></MoreAbout>
    </NameOfTeam>
    <Divisions>
      <h3> <MultilingualText tKey="departments" area="whereWork" /></h3>
      <ul>
        {divisionsList && divisionsList.map((division, idx) =>
          <li key={idx}>{division}</li>,
        )}
      </ul>
    </Divisions>
    <MoreAboutPhone to={`${lang === 'en' ? '/en' : ''}/teams/${id}`}>
      <MultilingualText tKey="moreAboutTeam" area="whereWork" />
    </MoreAboutPhone>
  </WrapperDivisions>
}

export const TeamsWorking: FunctionComponent = () => {
  const { key, teamsWorking } = useLocationData();
  return <>
    <Container>
      <Row>
        <Col>
          {teamsWorking.map((teamWorking, index) => <TeamWorking key={index} area={key} id={teamWorking} />)}
        </Col>
      </Row>
    </Container>
  </>;
};
