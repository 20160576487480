import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme';

export const SubTitle = styled.h3`
  font-weight: 400;
  max-width: 28.5rem;
  width: 100%;
  margin-top: 0;
`;

export const RowManiDescription = styled(Row)`
  white-space: break-spaces;
  border-top: 0.063rem solid ${(props) => props.theme.colors.linesLight};

  padding-top: 0.375rem;
  padding-bottom: 4rem;

  ${media.up('md')} {
    padding-top: 6.25rem;
    padding-bottom: 8.75rem;
  }
`;
export const DivDescription = styled.div`
  p {
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  li::before {
    content: '\\2022';
    color: ${(props) => props.theme.colors.blueButtonBackground};
    font-weight: bold;
    display: inline-block;
    width: 2.3rem;
    margin-left: -2.3rem;
  }

  ${media.up('md')} {
    font-size: 1rem;
    line-height: 1.56;
    font-weight: 400;
    max-width: 32.5rem;
    width: 100%;
  }
`;
