import styled from 'styled-components';
import { PlayButton } from 'view/pages/about-us/components/Feature/styles';
import { media } from 'core/theme';
import { Row } from 'styled-bootstrap-grid';
import { IBackgroundImage } from '../../interface';

export const Placeholder = styled.div<IBackgroundImage>`
  background-image: url(${(props) => props.BackgroundPhone});
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12.5rem;
  cursor: pointer;
  background-size: cover;
  ${media.up('lg')} {
    background-image: url(${(props) => props.BackgroundDesktop});
    width: 41.875rem;
    height: 23.563rem;
  }
`;
export const Play = styled(PlayButton)`
  transition-duration: 0.3s;
  ${Placeholder}:hover & {
    opacity: 0.3;
  }
`;
export const RowContentWorks = styled(Row)`
  border-top: 0.063rem solid ${(props) => props.theme.colors.linesLight};
  padding-top: 3.75rem;
  margin-bottom: 0;

  h2 {
    margin-top: 0;
    letter-spacing: -0.3px;
  }

  ${media.up('md')} {
    h2 {
      letter-spacing: -0.46px;
    }
    margin-bottom: 3.75rem;
    padding-top: 9.375rem;
  }
`;
