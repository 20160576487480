export const krakowIt = {
  krakowIt :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/it-center-krakow',
  },
  krakowItClickMap : {
    event: 'backendMicroconversion',
    category: 'Clicks - map',
  },
  krakowItClickOffer: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  krakowItClickMovieOpen: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  krakowItClickMoviePlay: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  krakowItClickMovieExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  krakowItClickSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  krakowItLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
}