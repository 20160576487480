import styled from 'styled-components';
import closeImage from 'assets/images/home/close.svg';
import { media } from 'core/theme';
import { Col } from 'styled-bootstrap-grid';
import arrow from 'assets/images/home/ctrl-right.svg';

export const Close = styled.div`
  height: 6.875rem;
  width: 6.875rem;
  background-image: url(${closeImage});
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
  ${media.down('sm')} {
    width: 3.75rem;
    height: 3.75rem;
    background-size: 3.75rem;
    right: 0;
  }
`;
export const Title = styled.div`
  margin-top: 18.5rem;
  font-size: 2.313rem;
  margin-bottom: 1.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.46px;
  color: ${(props) => props.theme.colors.lightText};
  ${media.down('sm')} {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: -0.3px;
    margin-bottom: 1.25rem;
    margin-top: 3.75rem;
  }
  @media (max-height: 900px) and (min-width: 900px) {
    margin-top: 5.5rem;
  }
`;
export const Text = styled.div`
  width: 31.688rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.lightText};
  ${media.down('sm')} {
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 1200px) and (min-width: 800px) {
    width: 100%;
  }
`;
export const Buttons = styled.div`
  display: flex;
  position: absolute;
  bottom: 11.25rem;
  ${media.down('sm')} {
    padding: 0 5rem;
    width: 100%;
    bottom: 5.5rem;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-height: 720px) and (min-width: 800px) {
    bottom: 7.25rem;
  }
  @media (max-height: 720px) and (min-width: 800px) {
    bottom: 7.25rem;
  }
`;
export const ArrowNext = styled.div`
  background: url(${arrow});
  width: 1rem;
  height: 3.5rem;
  background-size: 1rem 3.5rem;
  cursor: pointer;
`;
export const ArrowPrev = styled.div`
  transform: rotate(180deg);
  margin-right: 6.25rem;
  background: url(${arrow});
  width: 1rem;
  height: 3.5rem;
  background-size: 1rem 3.5rem;
  cursor: pointer;
  ${media.down('sm')} {
    margin-right: 0;
  }
`;
export const Counter = styled.div`
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${(props) => props.theme.colors.lightText};
`;
export const WrapperModalText = styled(Col)`
  margin-top: 1.25rem;
  padding: 0;
`;
