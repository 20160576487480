import React, { FunctionComponent, useState } from 'react';

import { StyledSubMenu, SubmenuWrapper, SubMenuTitle } from './styles';
import { ISubmenuProps } from './interfaces';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'

import { ReactComponent as DropdownIcon } from 'assets/images/footer/dropdown-mobile.svg';

export const SubMenu: FunctionComponent<ISubmenuProps> = ({ children, title }) => {

  const [open, setOpen] = useState(false);

  return <StyledSubMenu open={open}>
    <SubMenuTitle onClick={() => {
      setOpen(!open);
    }}>
      <span><MultilingualText tKey={title} area="common" /></span> <DropdownIcon />
    </SubMenuTitle>
    <SubmenuWrapper>
      {children}
    </SubmenuWrapper>
  </StyledSubMenu>;
};