import React, { FunctionComponent, useState } from 'react';
import { Col } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { useMultilingualText } from 'features/hooks';
import { useBreakpoints } from 'features/hooks';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import VideoModal from '../VideoModal';

import { IFeature } from '../../interfaces';
import playButton from 'assets/images/about-us/play-button.png';

import {
  ContentRow,
  Title,
  Description,
  LocationBox,
  LocationDecoration,
  Video,
  WatchItBox,
  PlayButton,
  ActionText
} from './styles';


export const AboutUsFeatureComponent: FunctionComponent<{ feature: IFeature }> = (props: { feature: IFeature }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLarge } = useBreakpoints();

  const { feature } = props;
  const {
    decorationColor,
    tKey,
    photo,
    youtubeVideoId,
  } = feature;

  const aboutUsFeatureHeader = useMultilingualText(`features.${tKey}.header`, 'aboutUs');
  const aboutUsFeatureDescription = useMultilingualText(`features.${tKey}.description`, 'aboutUs');
  const aboutUsFeatureLocation = useMultilingualText(`features.${tKey}.location`, 'aboutUs');

  const aboutUsHeaderString = aboutUsFeatureHeader.toString();

  const setTags = (id) => {
    GTM(id, aboutUsHeaderString, `https://www.youtube.com/watch?v=${youtubeVideoId}`);
  }
  const onModalClose = () => {
    setIsModalOpen(false);
    setTags('aboutUsVideoExits')
  }
  const onModalOpen = () => {
    setIsModalOpen(true);
    setTags('aboutUsVideoEnters')
  }

  if (isLarge)
    return <ContentRow>
      <VideoModal GTMName={'aboutUsVideoPlays'} youtubeVideoId={youtubeVideoId} open={isModalOpen} closeModal={onModalClose} header={aboutUsFeatureHeader.toString()} />
      <Col md={6}>
        <Title>{aboutUsFeatureHeader}</Title>
        <Description>{aboutUsFeatureDescription}</Description>
        <LocationBox>
          <LocationDecoration decorationColor={decorationColor} />
          <div>{aboutUsFeatureLocation}</div>
        </LocationBox>
      </Col>
      <Col col={6} >
        <LazyloadBackgroundImage image={photo} width='100%' height='20.063rem'>
          <Video backgroundImage={photo} decorationColor={decorationColor} isLarge={isLarge} onMouseUp={onModalOpen}>
            <WatchItBox>
              <PlayButton src={playButton} />
              <ActionText><MultilingualText tKey="featureVideoButtonText" area="aboutUs" /></ActionText>
            </WatchItBox>
          </Video>
        </LazyloadBackgroundImage>

      </Col>
    </ContentRow >
  return <ContentRow>
    <VideoModal GTMName={'aboutUsVideoPlays'} youtubeVideoId={youtubeVideoId} open={isModalOpen} closeModal={onModalClose} header={aboutUsFeatureHeader.toString()} />
    <Col col={12}>
      <Title>{aboutUsFeatureHeader}</Title>
      <LazyloadBackgroundImage image={photo} width='100%' height='11.063rem'>
        <Video backgroundImage={photo} decorationColor={decorationColor} onMouseUp={onModalOpen}>
          <WatchItBox>
            <PlayButton src={playButton} />
          </WatchItBox>
        </Video>
      </LazyloadBackgroundImage>
      <Description>{aboutUsFeatureDescription}</Description>
      <LocationBox>
        <LocationDecoration decorationColor={decorationColor} />
        <div>{aboutUsFeatureLocation}</div>
      </LocationBox>
    </Col>

  </ContentRow>;

};
