import React, { FunctionComponent, useState } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid'
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { useBreakpoints, useTopImage } from 'features/hooks'
import playButton from 'assets/images/about-us/play-button.png';
import aboutUsBanner from 'assets/images/about-us/about-us-banner.jpg';
import aboutUsBannerMobile from 'assets/images/about-us/about-us-banner-mobile.jpg';

import VideoModal from '../VideoModal';

import {
  TopBanner,
  PageTitle,
  VideoButton,
  ButtonIcon,
  ButtonText,
} from './styles';


export const TopBannerComponent: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLarge } = useBreakpoints();
  const imageMobile = useTopImage(aboutUsBannerMobile);
  const imageDesktop = useTopImage(aboutUsBanner);
  const topBannerContainerSize = isLarge ? 'large' : 'default';
  const youtubeVideoId = "RTZJ__YLFco";

  const resolveColCustomStyle = () => !isLarge ? { paddingRight: 0 } : { padding: 0 };
  const { t } = useTranslation("aboutUs");
  const getMultilingualItem = (key) => t(key);
  const videoButtonText = getMultilingualItem("videoButtonText");

  const setTags = (id) => {
    GTM(id, `${videoButtonText} (top banner)`, `https://www.youtube.com/watch?v=${youtubeVideoId}`);
  }
  const onModalClose = () => {
    setIsModalOpen(false);
    setTags('aboutUsVideoExits')
  }
  const onModalOpen = () => {
    setIsModalOpen(true);
    setTags('aboutUsVideoEnters')
  }

  return <div>
    <VideoModal GTMName={'aboutUsVideoPlays'}  youtubeVideoId={youtubeVideoId} open={isModalOpen} closeModal={onModalClose} header="Banner video" />
    <TopBanner imageDesktop={imageDesktop} imageMobile={imageMobile}>
      <Container type={topBannerContainerSize} right={true}>
        <Row alignItems="end">
          <Col sm={12} lg={5} md={12} style={resolveColCustomStyle()}>
            <PageTitle>
              {getMultilingualItem('title')}
            </PageTitle>
          </Col>
          <Col sm={12} lg={7} md={12} style={resolveColCustomStyle()}>
            <VideoButton onMouseUp={onModalOpen}>
              <ButtonIcon src={playButton} />
              <ButtonText>
                {videoButtonText}
              </ButtonText>
            </VideoButton>
          </Col>
        </Row>
      </Container>
    </TopBanner>
  </div>;

};
