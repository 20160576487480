import styled from 'styled-components';

import { StyledFooter } from 'view/components/structure/footer/styles';
import { StyledMain } from 'view/components/structure/main/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  ${StyledMain}{
    flex: 1 0 auto;
  }
  
  ${StyledFooter}{
    flex-shrink: 0;
  }
`;
