import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import PositionPin from 'assets/images/icons/pin.svg';
import { Main, TextBox, BottomGroup, City, PinIcon, DetailsButton, NewOfferRibbon, StyledLink, Team, Title } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

enum Badges {
    NEW = 'new',
}
export const JobOfferElement = ({ className = '', style = {}, details }) => {
    const { lang } = useSelector(languageSelect);
    const link = `${lang === 'en' ? '/en' : ''}/job-offers/${details.slug}`;
    const { t } = useTranslation("jobOffers");
    const getMultilingualItem = (key) => t(key);

    return (<Main className={className} style={style}>
        {details.badge === Badges.NEW && <NewOfferRibbon><MultilingualText tKey="newOffer" area="jobOffers" /></NewOfferRibbon>}
        <TextBox>
            <Team>{details.team}</Team>
            <Title>{details.title}</Title>
        </TextBox>
        <BottomGroup>
            <City>
                <PinIcon src={PositionPin} />
                {details.city}</City>
            <StyledLink to={link} onMouseUp={() => GTM('jobOffersButtonClick', `${getMultilingualItem('textDetails')} [${details.title} | ${details.team} | ${details.city}]`, link)}>
                <DetailsButton>
                    <MultilingualText tKey="textDetails" area="jobOffers" />
                </DetailsButton>
            </StyledLink>
        </BottomGroup>
    </Main>);
};
