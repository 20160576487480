import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import {
  DetailsLinkEmployeePrograms, IconEmployeePrograms,
  ItemContentBaner
} from 'view/pages/home/components/EmployeePrograms/styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import EqualSalaryIcon from 'assets/images/employee-programs/equal-salary-icon.png';
import SuksesPisanyImg from 'assets/images/employee-programs/sukses-pisany-logo.png';
import KartaRoznorodnosciImg from 'assets/images/employee-programs/karta-roznorodnosci-logo.png';
import { Col } from 'styled-bootstrap-grid';
import { RowBannersHappyParent, WrraperBunnerEqualSalary, BannerImportantInitiatives, LogoInsideBanner } from './styles';
import { useSelector } from 'react-redux';
import { languageSelect } from '../../../../features/language';
import HappyParentsIcon from "../../../../assets/images/employee-programs/happy-parents-icon.png";
import GTM from "features/services";



export const BannersSmartWorkBottom: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);

  return <>
    <Container>
      <RowBannersHappyParent>
        <Col lg={ 12 } md={ 12 } sm={ 12 } noGutter={ true }>
          <h3><MultilingualText
              tKey="check" area="happy_parents" /></h3>
          <WrraperBunnerEqualSalary>
            <ItemContentBaner color={ '#eb6653' }>
              <p className="title"><MultilingualText
                tKey="equalSalary" area="happy_parents" /></p>
              <p className="description"><MultilingualText
                tKey="equalSalaryDesc" area="happy_parents" /></p>
              <DetailsLinkEmployeePrograms onMouseUp={() => GTM('smartWorkLinkTo', `${<MultilingualText
                  tKey="detailsButton" area="home" />}`, `${lang === 'en' ? '/en' : ''}/how-we-work/equal-salary`)} to={`${lang === 'en' ? '/en' : ''}/how-we-work/equal-salary`}>
                <MultilingualText tKey="detailsButton" area="home" />
              </DetailsLinkEmployeePrograms>

              <IconEmployeePrograms iconImg={ EqualSalaryIcon } />
            </ItemContentBaner>
            <ItemContentBaner color={ '#149fd1' }>
              <p className="title"><MultilingualText
                  tKey="happyParents" area="home" /></p>
              <p className="description"><MultilingualText
                  tKey="happyParentsDesc" area="home" /></p>
              <DetailsLinkEmployeePrograms
                  onMouseUp={() => GTM('smartWorkLinkTo', `${<MultilingualText
                      tKey="detailsButton" area="home" />}`, `${lang === 'en' ? '/en' : ''}/how-we-work/happy-parents`)}
                  to={`${lang === 'en' ? '/en' : ''}/how-we-work/happy-parents`}><MultilingualText
                  tKey="knowProgram" area="home" /></DetailsLinkEmployeePrograms>
              <IconEmployeePrograms iconImg={ HappyParentsIcon } />
            </ItemContentBaner>
          </WrraperBunnerEqualSalary>
        </Col>
        <Col lg={ 5.7 } md={ 6 } sm={ 12 } noGutter={ true }>

          <h3><MultilingualText
            tKey="other-important" area="happy_parents" /></h3>
          <BannerImportantInitiatives>
            <LogoInsideBanner src={ SuksesPisanyImg } />
            <LogoInsideBanner src={ KartaRoznorodnosciImg } />
          </BannerImportantInitiatives>
        </Col>

      </RowBannersHappyParent>

    </Container>
  </>;
};
