import styled from 'styled-components';
import { media } from 'core/theme/media';

export const Main = styled.div`
    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    overflow-x: hidden;
  `,
  Header = styled.h3`
    padding: 9.375rem 0;
    font-weight: normal;
    white-space: break-spaces;
    ${media.down('md')} {
      padding: 3.75rem 0;
      line-height: normal;
    }
  `,
  Image = styled.div<{ photo: string; photoMobile: string }>`
    width: 100%;
    height: 37.5rem;
    background-image: url(${(props) => props.photo});
    background-size: cover;
    background-position: center;

    ${media.down('md')} {
      background-image: url(${(props) => props.photoMobile});
      height: 13.125rem;
      background-size: cover;
    }
  `,
  SliderHeader = styled.h2`
    font-weight: 400;
    padding-top: 9.438rem;
    padding-bottom: 4rem;

    ${media.down('md')} {
      padding-top: 1.875rem;
      padding-bottom: 1.5rem;
    }
  `,
  Title = styled.h3`
    font-weight: 400;
    padding-top: 1.25rem;
    padding-bottom: 3.125rem;

    ${media.down('md')} {
      padding-bottom: 1.875rem;
      padding-top: 0.625rem;
      line-height: normal;
    }
  `,
  BannerBox = styled.div`
    margin-top: 9.375rem;
    margin-bottom: 12.5rem;
    ${media.down('md')} {
      margin-top: 2.5rem;
      margin-bottom: 0;
    }
  `;
