import React, {FunctionComponent} from "react";
import Container from 'view/components/content/container';
import {Col, Row} from 'styled-bootstrap-grid';
import {DivDescription} from "../../locationPages/components/MainDescription/styles";
import descImg from 'assets/images/how-we-work/desc-img.jpg'
import MultilingualText from "../../../components/content/MultilingualText/MultilingualText";
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import { SentenceHowWeWork, DescImgHowWeWorkMobile, DescImgHowWeWork } from "./styles";

export const HowWeWorkDescription: FunctionComponent = () => {
    return <>
        <Container>
            <Row>
                <Col lg={6} md={12} sm={12}>
                    <SentenceHowWeWork> <MultilingualText tKey="employeeSupport" area="howWeWork" /> </SentenceHowWeWork>
                    <LazyloadBackgroundImage image={descImg}>
                    <DescImgHowWeWorkMobile src={descImg}/>
                    </LazyloadBackgroundImage>
                    <p> <MultilingualText tKey="employeeSupportFitsDesc" area="howWeWork" /> </p>
                    <p> <MultilingualText tKey="employeeSupportSecondDesc" area="howWeWork" />  </p>
                    <DivDescription>
                        <ul>
                            <li><MultilingualText tKey="employeeSupportList1" area="howWeWork"/> </li>
                            <li><MultilingualText tKey="employeeSupportList2" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList3" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList4" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList5" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList6" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList7" area="howWeWork"/></li>
                            <li><MultilingualText tKey="employeeSupportList8" area="howWeWork"/></li>
                        </ul>
                    </DivDescription>
                    <p><MultilingualText tKey="employeeSupportLastDesc" area="howWeWork" /></p>

                </Col>
                <Col lg={6} md={12} sm={12}>
                    <LazyloadBackgroundImage image={descImg}>
                    <DescImgHowWeWork src={descImg}/>
                    </LazyloadBackgroundImage>
                </Col>
            </Row>
        </Container>
    </>
}