import React, { FunctionComponent } from 'react';
import Container from '../../../components/content/container';
import { Col } from 'styled-bootstrap-grid';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';

import { RowWhyImplementHappy } from './styles';

export const WhyImplementHappy: FunctionComponent = () => {
  return <>
    <Container>
      <RowWhyImplementHappy>
        <Col lg={ 8 } md={ 10 } sm={ 12 } noGutter={ true }>
          <h2><MultilingualText tKey="why-implement-title" area="happy_parents" /></h2>
          <p><MultilingualText tKey="why-implement-description" area="happy_parents" /></p>
        </Col>
      </RowWhyImplementHappy>
    </Container>
  </>;
};
