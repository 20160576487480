import styled from "styled-components";
import {media} from "../../../../../core/theme";
import {ButtonClosedesktop} from "../../../contact/styles";
import ArrowRightBlack from "../../../../../assets/images/whereWorking/arrow-right-black.svg";
import ArrowRightBlue from "../../../../../assets/images/whereWorking/arrow-right-blue-hover.svg";

export const ContainerPopUPSmartWork = styled.div`

  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: 400;
  }

  ${media.up('md')} {
    max-width: 85%;
    flex-direction: row;
  }
`
export const TitleModalSmartWork = styled.h2`
  max-width: 400px;
`
export const WrapperItemListInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  border-bottom: 1px solid #C9D2E3;

  ${media.up('md')} {
    flex-direction: row;
    align-items: center;
    gap: 16px;

  }

`
export const NumberListInfo = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.3px;
  color: #0074C2;

  ${media.down('md')} {
    margin: 0;
  }
`
export const ButtonClosedSmartInfo = styled(ButtonClosedesktop)`
  
  ${media.down('md')} {
    width: 55px;
    height: 55px;
    background-size: 55px;
    margin-top: 0;
    z-index: 11;
  }

  ${media.down('sm')} {
    width: 32px;
    height: 32px;
    background-size: 32px;

  }
`
export const SeeMorePopup = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  
  &:after {
    content: "";
    background: url("${ArrowRightBlack}") no-repeat;
    margin-left: 0.547rem;
    background-size: 0.9375rem;
    width: 1rem;
    height: 1rem;
  }

  &:active, &:visited, &:link {
    color: ${props => props.theme.colors.darkText};
  }

  &:hover {
    &:after {
      background: url("${ArrowRightBlue}") no-repeat
    }
  }
`