import styled from "styled-components";
import {media} from "../../../../core/theme";

export const SentenceHowWeWork = styled.h2`
  width: 99%;
  max-width: 29rem;
  font-weight: 300;
  margin-top: 0;
  ${media.down('md')} {
    padding-bottom: 16px;
    margin-bottom: 0;

  }
  
`
export const DescImgHowWeWorkMobile = styled.img`
  width: 100%;
  ${media.up('sm')} {
    display: none;
  }
`
export const DescImgHowWeWork = styled.img`

  ${media.down('sm')} {
    display: none;
  }
`