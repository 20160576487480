import styled from 'styled-components';
import {media} from 'core/theme/media';


export const Header = styled.h3`
  padding: 96px 0 128px 0;
  font-weight: normal;
  white-space: break-spaces;
  margin: 0;

  ${media.down('md')} {
    padding: 40px 0;
    line-height: normal;
  }
`
export const WrapperBenefits = styled.div`
  h2 {
    display: none;
  }

  p {
    margin: 0;
  }


`
export const WrapperTextBenefit = styled.div`
  ${media.up('lg')} {
    margin-bottom: -54px;
  }
`