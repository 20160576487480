import styled from 'styled-components';
import { media } from 'core/theme';

export  const FlipBoxInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  &:hover {
    transform: rotateY(180deg);
  }	@media (min-width: 900px) and (max-width: 1280px) { 
    width: 100%;

  }
`
export const FlipBox = styled.div`
display: flex;
justify-content: start;
  background-color: transparent;
  width: 23.125rem;
  height: 28.125rem;

  perspective: 1000px;

  &:hover {
    ${FlipBoxInner} {
      transform: rotateY(180deg);
    }
  }

  @media (min-width: 900px) and (max-width: 1280px) {
    width: 100%;
     height: 23.438rem;
  }
`

export const FlipBoxBack = styled.div`
  flex-direction: column;
  cursor: pointer;
  background-size: cover;
  padding-left: 1rem;
  padding-right: 1rem;

  background: rgb(25, 25, 25);
  color: white;
  transform: rotateY(180deg);
  position: absolute;

  width: 23.125rem;
  height: 28.125rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;

  h3 {
    margin-top: 0;
  }

  @media (min-width: 900px) and (max-width: 1280px) {
    width: 100%;
height: 23.438rem;
  }
`
export  const FlipBoxFront = styled.div<IDescriptionCulture>`

  width: 23.125rem;
  height: 28.125rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-size: cover;
  justify-content: flex-end;
  text-align: left;
  padding-bottom: 2.5rem;

  ${media.up('sm')} {
    ${(props) => (props.image !== undefined) && `background-image: url('${props.image}');`};
    ${(props) => (props.image === undefined) && `border: solid 1px ${props.theme.colors.lines};`};
  }

  @media (min-width: 900px) and (max-width: 1280px) {
    width: 100%;
height: 23.438rem;
  }
  color: black;
  position: absolute;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`