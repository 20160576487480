import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid';

import bannerImage from 'assets/images/job-offers/background.jpg';
import {
  TopBanner,
  Team,
  PageTitle,
} from './styles';

export const TopBannerComponent: FunctionComponent<{ title?: string, team?: string }> = ({ title, team }) => {

  return <TopBanner backgroundImage={bannerImage}>
    <Container>
      <Row alignItems="end">
        <Col lg={8} md={12}>
          <Team>{team}</Team>
          <PageTitle>{title}</PageTitle>
        </Col>
      </Row>
    </Container>
  </TopBanner>;

};
