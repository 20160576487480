import styled, { css } from 'styled-components';
import { media } from 'core/theme/media';
import { Col } from 'styled-bootstrap-grid';

export const CollageContainer = styled.div`
    ${media.up('lg')} {
      padding-top: 10rem;
    }
  `,
  TextCol = styled(Col)`
    ${media.down('md')} {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
    }
  `,
  Text = styled.div`
    margin: 1.875rem 0;
  `,
  Hashtag = styled.h3`
    color: ${(props) => props.theme.colors.blueButtonBackground};
    font-weight: 400;
  `,
  PhotoCollage = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;

    ${media.down('md')} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  `,
  PhotoCol = styled(Col)`
    font-size: 0;
  `,
  Photo1 = css`
    height: auto;
    width: calc(100% + calc(100vw - 1170px - calc(50vw - 585px)));
    ${media.only('lg')} {
      padding-left: 0.5rem;
    }
  `,
  Photo1Collage = css`
    height: auto;
    width: 100%;
  `,
  Photo2 = css`
    width: 100%;
    height: auto;
    grid-row-start: 1;
  `,
  Photo3 = css`
    width: 100%;
    height: auto;
  `,
  Photo4 = css`
    width: 100%;
    height: auto;
    grid-column-start: 3;
    grid-row: 1 / span 2;

    ${media.down('md')} {
      grid-column-start: 2;
      grid-row: 2 / span 2;
      width: 100%;
      height: auto;
    }
  `,
  Photo5 = styled.div<IPhoto>`
    width: 100%;
    height: auto;
    grid-column: 1 / span 2;
    grid-row-start: 2;
    background-image: url(${(props) => props.backImage});
    background-repeat: no-repeat;
    background-size: 100% auto;

    ${media.down('md')} {
      height: 100%;
      grid-column: 1 / span 1;
      grid-row-start: 2;
      background-size: auto 100%;
    }
  `;
