import React,{ useState } from 'react';
import {CloseIcon, WrapperPhrase} from "./styles";
import Container from "view/components/content/container";
import {Row} from "styled-bootstrap-grid";
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";
import {useHistory} from "react-router";


const defaultFilters = {
  cities: [],
  teams: [],
  contractTypes: [],
};

export const PhraseSearch = ({ defaultOptions = defaultFilters, onChange, onClear, phrase }) => {
  const { lang } = useSelector(languageSelect);
  const history = useHistory();
  const [currentPhrase, setCurrentPhrase] = useState(phrase);

  const defaultFilter = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10`;

  const clearingFiltere = () => {
    history.push(defaultFilter);
    onChange(defaultFilters);
    setCurrentPhrase('');
    onClear();
  };
  return (
    <Container>
      <Row>
        {currentPhrase ? (
          <WrapperPhrase>
            <h1>{currentPhrase}</h1>
            <CloseIcon onMouseUp={clearingFiltere} />
          </WrapperPhrase>
        ) : null}
      </Row>
    </Container>
  );
};
