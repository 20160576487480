import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #CAD2E2;
  }
  100% {
    background-color: #fff;
  }
`;

export const Placeholder = styled.div<{ height: string; width: string }>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  animation: ${loadingAnimation} 1s infinite;
`;
