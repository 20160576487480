import styled, { css } from 'styled-components';
import { media } from 'core/theme';
import { IStyledSubMenuProps } from './interfaces';

export const StyledMenu = styled.div`
    column-count: 1;

    ${media.up('sm')} {
      column-count: 2;
    }
  `,
  StyledMenuItem = styled.div`
    cursor: pointer;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: -0.2px;
    margin-bottom: 0;
    line-height: 1.88;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    &:first-child {
      ${media.up('sm')} {
        line-height: normal;
        margin-bottom: 1.25rem;
      }
    }

    &:last-child {
      margin-top: 1.25rem;
    }

    svg {
      vertical-align: middle;
      margin-right: 0.625rem;
    }
  `,
  SubMenuTitle = styled.div`
    display: block;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.625rem;
      transition: transform 0.3s;

      ${media.up('sm')} {
        display: none;
      }
    }
  `,
  SubmenuWrapper = styled.div`
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    display: none;

    ${media.up('sm')} {
      display: block;
    }

    ${StyledMenuItem} {
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 2.08;
      letter-spacing: -0.15px;
      margin-bottom: 0;
      margin-top: 0;

      ${media.up('sm')} {
        opacity: 0.6;
      }
    }
  `,
  StyledSubMenu = styled.div<IStyledSubMenuProps>`
    ${(props) =>
      props.open &&
      css`
        ${SubmenuWrapper} {
          display: block;
        }

        ${SubMenuTitle} svg {
          transform: rotate(180deg);
        }
      `}
  `,
  StyledMenuItemLink = styled.a`
    display: block;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: -0.2px;
    margin-bottom: 0;
    line-height: 1.88;
    text-decoration: none;
    color: inherit;
    svg {
      vertical-align: middle;
      margin-right: 0.625rem;
    }
    margin-top: 1.25rem;
  `;
