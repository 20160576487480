import * as React from 'react';

import { useMultilingualText } from 'features/hooks';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';

import {
  OfficeContainer,
  Gradient,
  Rectangle,
  Title,
  MoreContainer,
  More,
  Arrow,
  BackgroundHover
} from './styles';

export const Office = (props) => {
  const officeTextTitle = useMultilingualText(`${props.tKey}.title`, 'home');

  const [isShown, setIsShown] = React.useState(false);
  return <LazyloadBackgroundImage image={props.photo}>
    <OfficeContainer to={props.to} onMouseUp={props.onMouseUp} onMouseEnter={() => { setIsShown(true) }}
      onMouseLeave={() => { setIsShown(false) }} photo={props.photo}>
      <Gradient gradient={props.gradient} />
      <BackgroundHover rectangle={props.rectangle} isShown={isShown}></BackgroundHover>
      <Rectangle rectangle={props.rectangle} />
      <Title>{officeTextTitle}</Title>
      <MoreContainer>
        <More>{props.title2}</More>
        <Arrow />
      </MoreContainer>
    </OfficeContainer>
  </LazyloadBackgroundImage>
};