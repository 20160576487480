import React, { FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid'

import { useMultilingualText } from 'features/hooks';
import { IRecruitmentStageComponentProps } from 'view/pages/recruitment/interfaces';
import {RStageContainer, ImageCol, Image, StageNumber, TitleCol, Title, DescCol, Description, SubTitle} from './styles';
import {SmartWorkPopUpInfo} from "../SmartWorkPopUpInfo/SmartWorkPopUpInfo";

export const SmartWorkStageComponent: FunctionComponent<IRecruitmentStageComponentProps> = ({ stage, isLast, id,infoModal }) => {
  const { image } = stage;
  const smartWorkName = useMultilingualText(`smartWorkProcess.${id}.name`, 'smartWork');
  const smartWorkSubName = useMultilingualText(`smartWorkProcess.${id}.sub-name`, 'smartWork');
  const smartWorkDescription = useMultilingualText(`smartWorkProcess.${id}.description`, 'smartWork');


  return <RStageContainer isLast={isLast}>

    <Row>
      <ImageCol lg={1} md={2} sm={12}>
        <Image src={image} />
      </ImageCol>
      <TitleCol lg={5} md={10} sm={12}>
        <Title>{smartWorkName}</Title>
        <SubTitle>{smartWorkSubName}</SubTitle>
      </TitleCol>
      <DescCol lg={5} md={12} sm={12}>
        <Description>{smartWorkDescription}</Description>
        {infoModal  &&  <SmartWorkPopUpInfo />  }
      </DescCol>
    </Row>
  </RStageContainer>;
};