import { createGlobalStyle } from 'styled-components';

import { media } from 'core/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NameOfYourFont from 'assets/fonts/HeyAliceMono-Regular.woff';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'HeyAliceMono';
        src: url(${NameOfYourFont}) format('woff');
    }
    body {
        font-family: ${(props) => props.theme.font};
        font-size: ${(props) => props.theme.fontSize};
        margin: 0;
        color: ${(props) => props.theme.colors.darkText};
        -webkit-font-smoothing: antialiased;
         overflow-x: hidden;
    }
    h1 {
        font-size: ${(props) => props.theme.desktop.h1.fontSize};
        letter-spacing: ${(props) => props.theme.desktop.h1.letterSpacing};
        font-weight: ${(props) => props.theme.desktop.h1.fontWeight};
        line-height: ${(props) => props.theme.desktop.h1.lineHeight};

        ${media.down('sm')}{
        font-size: ${(props) => props.theme.mobile.h1.fontSize};
        letter-spacing: ${(props) => props.theme.mobile.h1.letterSpacing};
        font-weight: ${(props) => props.theme.mobile.h1.fontWeight};
        line-height: ${(props) => props.theme.mobile.h1.lineHeight};
        }
    }

    h2 {
        font-size: ${(props) => props.theme.desktop.h2.fontSize};
        letter-spacing: ${(props) => props.theme.desktop.h2.letterSpacing};
        font-weight: ${(props) => props.theme.desktop.h2.fontWeight};
        line-height: ${(props) => props.theme.desktop.h2.lineHeight};

        ${media.down('sm')}{
        font-size: ${(props) => props.theme.mobile.h2.fontSize};
        letter-spacing: ${(props) => props.theme.mobile.h2.letterSpacing};
        font-weight: ${(props) => props.theme.mobile.h2.fontWeight};
        line-height: ${(props) => props.theme.mobile.h2.lineHeight};
        }
    }

    h3 {
        font-size: ${(props) => props.theme.desktop.h3.fontSize};
        letter-spacing: ${(props) => props.theme.desktop.h3.letterSpacing};
        font-weight: ${(props) => props.theme.desktop.h3.fontWeight};
        line-height: ${(props) => props.theme.desktop.h3.lineHeight};

        ${media.down('sm')}{
        font-size: ${(props) => props.theme.mobile.h3.fontSize};
        letter-spacing: ${(props) => props.theme.mobile.h3.letterSpacing};
        font-weight: ${(props) => props.theme.mobile.h3.fontWeight};
        line-height: ${(props) => props.theme.mobile.h3.lineHeight};
        }
    }
    
    
    /**
      normalize
     */
    *, ::after, ::before {
      box-sizing: border-box;
    }
`;

export default GlobalStyle;
