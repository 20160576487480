import { useState, useEffect } from 'react';
import { GridTheme } from 'core/theme';

export const useBreakpoints = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const { breakpoints } = GridTheme;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const size = width < breakpoints.md ? 'sm' : width < breakpoints.lg ? 'md' : 'lg';
  return {
    size: size,
    isLarge: size === 'lg',
    isMedium: size === 'md',
    isSmall: size === 'sm',
    width,
  };
};
