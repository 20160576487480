import { bootstrapSelect } from 'features/bootstrap';
import { useBreakpoints, useMultilingualText } from 'features/hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import Dropdown from 'view/components/content/dropdown';
import { languageSelect } from 'features/language/'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import GTM from 'features/services';

import {
  ButtonSave,
  Checkmark,
  Circle,
  ColInput,
  CursorPointerSpan,
  Input,
  InputContainer,
  InputContainer2,
  InputContainer3,
  InputLabel,
  InputText,
  InputTrigger,
  MobileInputTitle,
  MobileInputTitleClose,
  MobileInputTitleText,
  Search,
  SearchIconShow,
  SearchText,
  SpecContainer,
  SpecContainer2,
  StyledDropdownIcon,
  StyledLocIcon,
  StyledSpecIcon
} from './styles';

const customStyles = {
  content: {
    height: '100%',
    width: '100vw',
    left: 0,
    top: 0,
    borderRadius: 0,
  },
  overlay: { zIndex: 1000 }
};
function truncate(str, n) {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}
export const InputsHome: FunctionComponent = () => {
  const { isSmall } = useBreakpoints();
  const [specActive, setSpecActive] = useState(false);
  const [locActive, setLocActive] = useState(false);
  const [cities, setCities] = useState([] as any);
  const [teams, setTeams] = useState([] as any);
  const [specValue, setSpecValue] = useState([] as any);
  const [locValue, setLocValue] = useState([] as any);
  const [specValueBefore, setSpecValueBefore] = useState([] as any);
  const [locValueBefore, setLocValueBefore] = useState([] as any);
  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);

  const warsaw = useMultilingualText(`warsaw`, `home`);
  const krakow = useMultilingualText(`krakow`, `home`);
  const sales = useMultilingualText(`sales`, `home`);

  useEffect(() => {
    if (bootstrap.data.cities) {
      const dataCities = [{ id: 2, name: `${krakow}` }, { id: 4, name: `${warsaw}` }, { id: -1, name: `${sales}` }]
      setCities(dataCities);
    }
    if (bootstrap.data.teams) {
      const dataSpec = bootstrap.data.teams;
      setTeams(dataSpec);
    }
  }, [bootstrap.data.cities, bootstrap.data.teams]);

  const specText = () => {
    const isChecked = specValue.filter(value => value);
    if (isChecked.length === 0) return <MultilingualText tKey="inputs.specialization" area="home" />;
    let spec = teams.filter((value, index) => specValue[index] === true)
    spec = spec.map(value => value['name']);
    if (isChecked.length === teams.length) return <MultilingualText tKey="inputs.allspecializations" area="home" />;
    let moreThan3 = (spec.length > 3) ? <Circle>+{isSmall ? spec.length - 2 : spec.length - 3}</Circle> : '';
    return <React.Fragment>{truncate(spec.slice(0, isSmall ? 2 : 3).join(", "), 45)}{moreThan3}</React.Fragment>;

  }
  const locText = () => {
    const isChecked = locValue.filter(value => value);
    if (isChecked.length === 0) return <MultilingualText tKey="inputs.location" area="home" />;
    let loc = cities.filter((value, index) => locValue[index] === true)
    loc = loc.map(value => value['name']);
    if (isChecked.length === cities.length) return <MultilingualText tKey="inputs.alllocations" area="home" />;
    let moreThan3 = (loc.length > 3) ? <Circle>+{isSmall ? loc.length - 2 : loc.length - 3}</Circle> : '';
    return <React.Fragment>{truncate(loc.slice(0, isSmall ? 2 : 3).join(", "), 45)}{moreThan3}</React.Fragment>;
  }

  const paramsUrlCities = () => {
    let path = cities.filter((value, index) => locValue[index] === true).length !== 0 ? `cities=${cities.filter((value, index) => locValue[index] === true).map(value => value['id']).join(',').replace(/\s/g, '')}` : '';
    const citiesData = path.search('-1') !== -1 ? path.replace('-1', bootstrap.data.cities.filter(value => value['name'] === 'Other' ? true : null).map(value => value['id'])) : path;
    return citiesData
  }

  const paramsUrlTeams = () => {
    const teamsData = teams.filter((value, index) => specValue[index] === true).length !== 0 ? `teams=${teams.filter((value, index) => specValue[index] === true).map(value => value['id']).join(',').replace(/\s/g, '')}` : '';
    return teamsData;
  }

  const getGTMCitiesData = () => {
    const loc = cities.filter((_, index) => locValue[index] === true)
    if (loc.length === 0) return '(not defined)'
    return (loc.map(value => value['name']));
  };
  const getGTMTeamsData = () => {
    const teamsData = teams.filter((_, index) => specValue[index] === true).map(value => value.name);
    if (teamsData.length === 0) return '(not defined)'
    return teamsData;
  };

  const onSearchClick = () => {
    const citiesData = getGTMCitiesData().toString();
    const teamsData = getGTMTeamsData().toString();
    GTM('homeSearchBox', teamsData, citiesData)
  }
  return <React.Fragment>
    <InputContainer sm={12} lg={5.5} md={5.5}>
      <Dropdown.Wrapper closeModal={specActive} onToggle={(open: boolean) => {
        setSpecActive(open);
      }}>
        <Dropdown.Trigger>
          <InputTrigger open={specActive}><StyledSpecIcon /> <InputText>{specText()}</InputText><StyledDropdownIcon /></InputTrigger>
        </Dropdown.Trigger>
        <Dropdown.Popup hasPadding={!isSmall}>
          {isSmall ?
            <Modal
              isOpen={specActive}
              onAfterOpen={() => {
                setSpecValueBefore(specValue);
              }}
              onRequestClose={() => { }}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <Container fluid style={{ height: '95vh' }}>
                <Col xs={12} noGutter style={{ height: '94vh' }}>
                  <MobileInputTitle>
                    <MobileInputTitleText><MultilingualText tKey="inputs.specializationshort" area="home" /></MobileInputTitleText>
                    <MobileInputTitleClose onMouseUp={() => {
                      setSpecActive(false);
                      setSpecValue(specValueBefore);
                    }}><MultilingualText tKey="inputs.closebutton" area="home" /></MobileInputTitleClose>
                  </MobileInputTitle>
                  {teams.map((value, index) =>
                    <ColInput noGutter key={index} lg={6} xs={12}>
                      <InputLabel>
                        <Input
                          checked={specValue[index]}
                          value={value['id']}
                          onChange={
                            (event) => {
                              let data = specValue.map(value => value);
                              data[index] = event.target.checked;
                              setSpecValue(data);
                            }
                          }
                          type="checkbox">
                        </Input>
                        <Checkmark />
                        <CursorPointerSpan onMouseUp={
                          () => {
                            // let checked = !specValue[index];
                            // let data = specValue.map(value => value);
                            // data[index] = checked;
                            // setSpecValue(data)
                          }
                        }>{value['name']}</CursorPointerSpan>
                      </InputLabel>

                    </ColInput>)}
                  <ButtonSave onMouseDown={() => setSpecActive(false)}><MultilingualText tKey="inputs.savebutton" area="home" /></ButtonSave>
                </Col>
              </Container>


            </Modal> :
            <SpecContainer>
              <Row>
                {teams.map((value, index) =>
                  <ColInput key={index} lg={6} xs={12}>
                    <InputLabel>

                      <Input
                        checked={specValue[index]}
                        value={value['id']}
                        onChange={
                          (event) => {
                            let data = specValue.map(value => value);
                            data[index] = event.target.checked;
                            setSpecValue(data);
                          }
                        }
                        type="checkbox">
                      </Input>
                      <Checkmark />
                      <CursorPointerSpan onMouseUp={
                        () => {
                          // let checked = !specValue[index];
                          // let data = specValue.map(value => value);
                          // data[index] = checked;
                          // setSpecValue(data)
                        }
                      }>{value['name']}</CursorPointerSpan>
                    </InputLabel>
                  </ColInput>)}
              </Row>
            </SpecContainer>}
        </Dropdown.Popup>
      </Dropdown.Wrapper>
    </InputContainer>
    <InputContainer2 sm={12} lg={5.5} md={5.5}>
      <Dropdown.Wrapper closeModal={locActive} onToggle={(open: boolean) => {
        setLocActive(open);
      }}>
        <Dropdown.Trigger>
          <InputTrigger open={locActive}><StyledLocIcon /> <InputText>{locText()}</InputText><StyledDropdownIcon /></InputTrigger>

        </Dropdown.Trigger>
        <Dropdown.Popup hasPadding={!isSmall}>
          {isSmall ?
            <Modal
              isOpen={locActive}
              onAfterOpen={() => { setLocValueBefore(locValue) }}
              onRequestClose={() => { }}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <Container fluid style={{ height: '95vh' }}>
                <Col xs={12} noGutter style={{ height: '94vh' }}>
                  <MobileInputTitle>
                    <MobileInputTitleText><MultilingualText tKey="inputs.specializationshort" area="home" /></MobileInputTitleText>
                    <MobileInputTitleClose onMouseUp={() => {
                      setLocValue(locValueBefore);
                      setLocActive(false);
                    }}><MultilingualText tKey="inputs.closebutton" area="home" /></MobileInputTitleClose>
                  </MobileInputTitle>
                  {cities.map((value, index) =>
                    <ColInput noGutter key={index} lg={6} xs={12}>
                      <InputLabel>
                        <Input
                          checked={locValue[index]}
                          value={value['id']}
                          onChange={
                            (event) => {
                              let data = locValue.map(value => value);
                              data[index] = event.target.checked;
                              setLocValue(data);
                            }
                          }
                          type="checkbox">
                        </Input>
                        <Checkmark />
                        <CursorPointerSpan onMouseUp={
                          () => {
                            // let checked = !locValue[index];
                            // let data = locValue.map(value => value);
                            // data[index] = checked;
                            // setLocValue(data)
                          }
                        }>{value['name']}</CursorPointerSpan>
                      </InputLabel>
                    </ColInput>)}
                  <ButtonSave cities={true} onMouseDown={() => setLocActive(false)}><MultilingualText tKey="inputs.savebutton" area="home" /></ButtonSave>
                </Col>
              </Container>
            </Modal> :
            <SpecContainer2>
              <Row>
                {cities.map((value, index) => <ColInput key={index} lg={6} xs={12}>
                  <InputLabel>
                    <Input
                      checked={locValue[index]}
                      value={locValue[index]}
                      onChange={
                        (event) => {
                          let data = locValue.map(value => value);
                          data[index] = event.target.checked;
                          setLocValue(data);
                        }
                      }
                      type="checkbox">
                    </Input>
                    <Checkmark />
                    <CursorPointerSpan onMouseUp={
                      () => {
                        // let checked = !locValue[index];
                        // let data = locValue.map(value => value);
                        // data[index] = checked;
                        // setLocValue(data)
                      }
                    }>{value['name']}</CursorPointerSpan>
                  </InputLabel>
                </ColInput>)}
              </Row>
            </SpecContainer2>}
        </Dropdown.Popup>
      </Dropdown.Wrapper>
    </InputContainer2>
    <InputContainer3 xs={12} lg={1} md={1}>
      <Search to={() => `${lang === 'en' ? '/en' : ''}/job-offers?${paramsUrlTeams()}&${paramsUrlCities()}`}
        onMouseUp={onSearchClick}>
        <SearchIconShow />{isSmall && <SearchText><MultilingualText tKey="inputs.searchbutton" area="home" /></SearchText>}</Search>
    </InputContainer3>

  </React.Fragment>

};