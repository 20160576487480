import React, { FunctionComponent } from 'react';
import { JobOfferElement } from '../JobOfferElement/JobOfferElement';
import { MainRow, MainCol, NoDataBox, Text } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

export const JobOfferList: FunctionComponent<{ threeInRow?: boolean, data: any }> = ({ data: offers, threeInRow }) => {
  return (
    <MainRow offerPage={!threeInRow}>
      {offers.length === 0 ?
        <MainCol col={12} isWide={threeInRow}>
          <NoDataBox>
            <Text><MultilingualText tKey="noPosition" area="jobOffers" /></Text>
          </NoDataBox>
        </MainCol> :
        offers.map(offer =>
          <MainCol key={Math.random()} lg={threeInRow ? 4 : 6} md={12} sm={12} isWide={threeInRow}>
            <JobOfferElement details={offer} />
          </MainCol>)}
    </MainRow>
  )
};
