import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { languageSelect } from 'features/language/';
import Language from './language';
import Menu from './menu';
import ButtonsBox from './buttons';
import {
  Header as StyledHeader,
  Navbar,
  Collapse,
  Logo as LogoWrapper,
  MobileWrapper,
  StyledButton
} from './styles';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ITogglerProps } from './interfaces';

const Toggler: FunctionComponent<ITogglerProps> = ({ onClick }) => {
  return <StyledButton type="button" onClick={onClick}>
    <span></span>
  </StyledButton>;
};


export const Header: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const homePageLink = lang === 'en' ? '/en' : '';
  const location = useLocation();
  const [prevLoc, setPrevLoc] = useState(location.pathname)
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (prevLoc !== location.pathname) setOpen(false);
    setPrevLoc(location.pathname)
  }, [prevLoc, location.pathname]);


  return <StyledHeader open={open}>
    <Container as={Navbar} fluid>
      <LogoWrapper>
        <Link to={homePageLink} onMouseUp={() => GTM('headerLogo', "Logo", '/')}>
          <Logo />
        </Link>
      </LogoWrapper>
      <Toggler onClick={() => {
        setOpen(!open);
      }} />
      <Collapse>
        <ButtonsBox />

          <Menu />

        <MobileWrapper>
          <Language />
        </MobileWrapper>
      </Collapse>
    </Container>
  </StyledHeader >;
};
