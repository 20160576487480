import React, { FunctionComponent } from 'react';
import BannerHomeMysliIMG from 'assets/images/employee-programs/baner-home-mysli.jpg';
import BannerHomePoznajIMG from 'assets/images/employee-programs/poznaj-program.jpg';
import EqualSalaryIcon from 'assets/images/employee-programs/equal-salary-icon.png';
import HappyParentsIcon from 'assets/images/employee-programs/happy-parents-icon.png';
import MultilingualText from '../../../../components/content/MultilingualText/MultilingualText';
import { WrapperItemBaner,ContainerBannerHome,ItemContentBaner, DetailsLinkEmployeePrograms, IconEmployeePrograms } from './styles';
import { useSelector } from 'react-redux';
import { languageSelect } from '../../../../../features/language';

export const EmployeePrograms: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  return <>
    <ContainerBannerHome>
      <WrapperItemBaner backgroudImg={ BannerHomeMysliIMG }>
        <ItemContentBaner color={ '#149fd1' }>
          <p className="title"><MultilingualText
            tKey="happyParents" area="home" /></p>
          <p className="description"><MultilingualText
            tKey="happyParentsDesc" area="home" /></p>
          <DetailsLinkEmployeePrograms to={`${lang === 'en' ? '/en' : ''}/how-we-work/happy-parents`}><MultilingualText
            tKey="knowProgram" area="home" /></DetailsLinkEmployeePrograms>
          <IconEmployeePrograms iconImg={ HappyParentsIcon } />
        </ItemContentBaner>
      </WrapperItemBaner>

      <WrapperItemBaner backgroudImg={ BannerHomePoznajIMG }>
        <ItemContentBaner color={ '#eb6653' }>
          <p className="title"><MultilingualText
            tKey="equalSalary" area="home" /></p>
          <p className="description"><MultilingualText
            tKey="equalSalaryDesc" area="home" /></p>
          <DetailsLinkEmployeePrograms  to={`${lang === 'en' ? '/en' : ''}/how-we-work/equal-salary`}> <MultilingualText
            tKey="detailsButton" area="home" /></DetailsLinkEmployeePrograms>
          <IconEmployeePrograms iconImg={ EqualSalaryIcon } />
        </ItemContentBaner>
      </WrapperItemBaner>
    </ContainerBannerHome>
  </>;
};

