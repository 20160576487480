import styled from 'styled-components';
import { media } from 'core/theme';
import { CustomPagingComponent, Paging } from 'view/components/content/Slider/SlideComponent/styles';

export const Text = styled.div`
    text-align: center;
  white-space: break-spaces;
    ${media.up('lg')} {
      font-size: 1.5rem;
      color: #191919;
      height: 8.5rem;
      line-height: 1.46;
      letter-spacing: -0.3px;
      font-weight: normal;
      padding-right: 4.75rem;
      padding-left: 4.75rem;

      &:focus {
        outline: 0;
      }
    }

    p {
      margin: 0;
    }
  `,
  Slide = styled.div<ISlideOpacity>`
  display: flex;
  flex-direction: column;

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.slideOpacity &&
    `
      opacity: 0.0; 
    `}
}

${media.up('lg')} {
  width: 42rem;
  height: 6.563rem;
  ${(props) => props.slideOpacity && `opacity: 0.3;`}
}
`,
  Main = styled.div`
    h1 {
      text-align: center;
      line-height: normal;
      letter-spacing: -0.3px;
      margin-bottom: 1.5rem;
    }

    ${media.up('lg')} {
      h1 {
        line-height: 1.44;
        letter-spacing: -0.49px;
        margin-bottom: 3.375rem;
      }
    }
  `;
export const PagingQuote = styled(Paging)`
  margin-top: 1.5rem;
  justify-content: center;

  ${media.up('lg')} {
    margin-top: 5.688rem;
  }
`;
export const LocationCustomPagingComponent = styled(CustomPagingComponent)`
  margin-right: 0.3125rem;
  margin-left: 0.3125rem;
  cursor: pointer;
`;
