import React, { FunctionComponent } from 'react';
import { Col } from 'styled-bootstrap-grid';
import Modal from 'react-modal';

import { useMultilingualText, useBreakpoints } from 'features/hooks';
import { useLocationData } from 'features/hooks/useLocationData';
import GTM from 'features/services';
import Container from 'view/components/content/container';
import { ModalButton } from 'view/pages/about-us/components/VideoModal/styles';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';

import playButton from 'assets/images/about-us/play-button.png';
import ModalClose from 'assets/images/about-us/modal-close.png';
import { Placeholder, Play, RowContentWorks } from './styles';


const customStyles = {
  content: {
    padding: 0,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'hidden',
    width: '100vw',
    height: '45vw',
    maxHeight: '56.25rem',
    maxWidth: '100rem',
    border: 0,
  },
  overlay: {
    backgroundColor: 'rgba(25,25,25,0.89)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 999,
  },
};

export const WorkingLoks: FunctionComponent = () => {
  const isLarge = useBreakpoints();
  const { key, workLooks } = useLocationData();
  const workLooksTitle = useMultilingualText(`workLooks.title`, `locations_${key}`);
  const workLooksDescription = useMultilingualText(`workLooks.description`, `locations_${key}`);
  const workLooksLinkToVideo = useMultilingualText(`workLooks.linkToVideo`, `locations_${key}`);
  const header = useMultilingualText(`workLooks.header`, `locations_${key}`);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  let player;

  const onPlayerStateChange = () => {
    const state = player.getPlayerState();
    if (state === 1) {
      GTM(`${key}ClickMoviePlay`, `${header}`, `https://www.youtube.com/embed/${workLooksLinkToVideo}`)
    }
  }

  const onYouTubeIframeAPIReady = () => {
    player = new YT.Player('player', {
      events: {
        'onStateChange': onPlayerStateChange
      }
    })
  };

  function openModal() {
    setIsOpen(true);
    GTM(`${key}ClickMovieOpen`, `${header}`, `https://www.youtube.com/embed/${workLooksLinkToVideo}`);
  }

  function closeModal() {
    setIsOpen(false);
    GTM(`${key}ClickMovieExits`, `${header}`, `https://www.youtube.com/embed/${workLooksLinkToVideo}`);
  }

  return (<>
    <Container>
      <RowContentWorks>
        <Col lg={4}>
          <h2>{workLooksTitle}</h2>
          <p>{workLooksDescription}</p>
        </Col>
        <Col lgOffset={1} lg={7}>
          <LazyloadBackgroundImage image={workLooks.image} width="100%" height='12.5rem'>
            <Placeholder BackgroundPhone={workLooks.image} BackgroundDesktop={workLooks.image} onMouseUp={openModal}>
              <Play src={playButton} />
            </Placeholder>
          </LazyloadBackgroundImage>
          <ModalButton notDisplayed={!modalIsOpen} onMouseUp={() => closeModal()} src={ModalClose} />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}>
            <iframe
              id="player"
              data-type="text/html"
              title={'youtube'}
              data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture enablejsapi"
              src={`https://www.youtube.com/embed/${workLooksLinkToVideo}?enablejsapi=1`}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              width="100%"
              height="100%"
              onLoad={onYouTubeIframeAPIReady}
            />
          </Modal>
        </Col>
      </RowContentWorks>
    </Container>
  </>);

};
