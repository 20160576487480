import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

import { languageSelect } from 'features/language';
import GTM from 'features/services';
import { useMultilingualText } from 'features/hooks';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import Container from 'view/components/content/container';

import { BannerDepartment } from './components/baner-department';
import { BannerOffice } from './components/baner-office';
import ImgKracowIT from 'assets/images/whereWorking/banner-it-krakow.jpg';
import ImgKracowUslugi from 'assets/images/whereWorking/banner-uslugi-krakow.jpg';
import ImgWarszawa from 'assets/images/whereWorking/banner-warszawa.jpg';
import ImgInPoland from 'assets/images/whereWorking/banner-in-poland.jpg';
import ImgKracowFabryka from 'assets/images/whereWorking/banner-fabryka-krakow.jpg';
import { Line } from './components/line';
import { ScrollUpButton } from './components/ScrollUpButton/scroll-up';
import { ArrowDown, ContainerButtonScroll, HeaderContainerTitle, WrapperHeadWhereWorking } from './styles';

export const WhereWorkMain: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const Krakow = useMultilingualText(`locations.krakow.name`, 'whereWork');
  const Warszawa = useMultilingualText(`locations.warszawa.name`, 'whereWork');
  const acrossPoland = useMultilingualText(`locations.cala-polska.name`, 'whereWork');
  const location = useLocation();

  useEffect(() => {
    GTM('whereWorkingLoaded', undefined, undefined, location.pathname);
  }, [lang]);

  return <>
    <WrapperHeadWhereWorking>
      <Container>
        <Row>
          <Col lg={6}>
            <HeaderContainerTitle>
              <h1><MultilingualText tKey="title" area="whereWork" /></h1>
            </HeaderContainerTitle>
          </Col>
          <Col lg={6}>
            <ContainerButtonScroll>
              <ul>
                <li><Link to="#krakow" onMouseUp={() => GTM('whereWorkingNavigation', `${Krakow}`, '(not defined)')} smooth>{Krakow}<ArrowDown /></Link></li>
                <li><Link to="#warszawa" onMouseUp={() => GTM('whereWorkingNavigation', `${Warszawa}`, '(not defined)')} smooth>{Warszawa}<ArrowDown /></Link></li>
                <li><Link to="#cala-polska" onMouseUp={() => GTM('whereWorkingNavigation', `${acrossPoland}`, '(not defined)')} smooth>{acrossPoland}<ArrowDown /></Link></li>
              </ul>
            </ContainerButtonScroll>


          </Col>
        </Row>
      </Container>
    </WrapperHeadWhereWorking>
    <ScrollUpButton />
    <BannerDepartment id="krakow" />
    <BannerOffice tKey="krakowIt" linkTo={`${lang === 'en' ? '/en/working-places/' : ''}it-center-krakow`} imgOfOffice={ImgKracowIT} />
    <BannerOffice tKey="krakowCUW" linkTo={`${lang === 'en' ? '/en/working-places/' : ''}shared-services-center-krakow`} imgOfOffice={ImgKracowUslugi} />
    <BannerOffice tKey="krakowFactory" linkTo={`${lang === 'en' ? '/en/working-places/' : ''}krakow-factory`} imgOfOffice={ImgKracowFabryka} />
    <Line />
    <BannerDepartment id="warszawa" />
    <BannerOffice tKey={'warsaw'} linkTo={`${lang === 'en' ? '/en/working-places/' : ''}warsaw-office`} imgOfOffice={ImgWarszawa} />
    <Line />
    <BannerDepartment id="cala-polska" />
    <BannerOffice tKey={'other'} linkTo={`${lang === 'en' ? '/en/working-places/' : ''}sales-poland`} imgOfOffice={ImgInPoland} />
  </>;
};