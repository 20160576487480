import * as React from 'react';
import { Col } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import { useBreakpoints } from 'features/hooks'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import {
  OfferContainer,
  Title,
  Location,
  Pin,
  LocationText,
  Button,
  ButtonText,
  OfferBoxMobile,
  ArrowMobile,
  ButtonIcon,
  ButtonCounter,
  Name,
  OfferMobile
} from './styles'
import arrowMobile from 'assets/images/home/path-copy.svg';

export const Offer = ({ name, location, counter, last, id }) => {
  const { lang } = useSelector(languageSelect);
  const { isSmall } = useBreakpoints();
  const [isAnswerVisible, setIsAnswerVisible] = React.useState(false);
  const link = `${lang === 'en' ? '/en' : ''}/job-offers?teams=${id}`;
  const { t } = useTranslation("home");
  const getMultilingualItem = (key) => t(key);

  return <Col xs={12} md={6} lg={6}>
    {!isSmall ?
      <OfferContainer>
        <Title>{name}</Title>
        <Location>
          <Pin />
          <LocationText>{location}</LocationText>
        </Location>
        <Button to={link}
          onMouseUp={() => GTM('homeOffersBlock', `${getMultilingualItem('offersButton')} (${counter} | ${name} | ${location})`, link)}>
          <ButtonText><MultilingualText tKey="offersButton" area="home" /></ButtonText>
          <ButtonCounter>{counter}</ButtonCounter>
          <ButtonIcon />
        </Button>
      </OfferContainer> : <React.Fragment>
        <OfferBoxMobile last={last} isAnswerVisible={isAnswerVisible} onMouseUp={() => setIsAnswerVisible(!isAnswerVisible)}>
          <Name>{name}</Name>
          <ArrowMobile src={arrowMobile} up={isAnswerVisible} />
        </OfferBoxMobile>
        {isAnswerVisible &&
          <OfferMobile last={last} isAnswerVisible={isAnswerVisible}>
            <div style={{ display: 'flex' }}><Pin /><LocationText>{location}</LocationText></div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button to={link} onMouseUp={() => GTM('homeOffersBlock', `${getMultilingualItem('offersButton')} (${counter} | ${name} | ${location})`, link)}>
                <ButtonText><MultilingualText tKey="offersButton" area="home" /></ButtonText>
                <ButtonCounter>{counter}</ButtonCounter>
                <ButtonIcon />
              </Button>
            </div>

          </OfferMobile>}
      </React.Fragment>}

  </Col>

};