import homePL from 'translations/pl/home.json';
import aboutUsPL from 'translations/pl/about-us.json';
import recruitmentPL from 'translations/pl/recruitment.json';
import whereWorkPL from 'translations/pl/where-work.json';
import privacyPL from 'translations/pl/privacy.json';
import teamsPL from 'translations/pl/teams.json';
import jobOffersPL from 'translations/pl/job-offers.json';
import krakowIt from 'translations/pl/locations/krakowIt.json';
import krakowFactory from 'translations/pl/locations/krakowFactory.json';
import krakowCUW from 'translations/pl/locations/krakowCUW.json';
import warsaw from 'translations/pl/locations/warsaw.json';
import other from 'translations/pl/locations/other.json';
import contactPL from 'translations/pl/contact.json';
import common from 'translations/pl/common.json';
import happyParentsPl from 'translations/pl/happy-parents.json';
import equalSalaryPL from  'translations/pl/equal-salary.json';
import missionIsPossiblePL from  'translations/pl/mission-is-possible.json';
import howWeWorkPL from  'translations/pl/how-we-work.json';
import smartWorkPL from 'translations/pl/smart-work.json';
import privacyMailPL from 'translations/pl/privacyMail.json';

export default {
  home: homePL,
  privacy: privacyPL,
  aboutUs: aboutUsPL,
  recruitment: recruitmentPL,
  whereWork: whereWorkPL,
  teams: teamsPL,
  jobOffers: jobOffersPL,
  locations_krakowIt: krakowIt,
  locations_krakowFactory: krakowFactory,
  locations_krakowCUW: krakowCUW,
  locations_warsaw: warsaw,
  locations_other: other,
  contact:contactPL,
  happy_parents:happyParentsPl,
  equal_salary:equalSalaryPL,
  mission_is_possible:missionIsPossiblePL,
  howWeWork:howWeWorkPL,
  smartWork:smartWorkPL,
  privacyMail:privacyMailPL,
  common,
};
