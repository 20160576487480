import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useMultilingualText } from 'features/hooks';
import { ColButtonLocation, CountInButtonLocation, TextInButtonLocation, WrapperTextInButton, ButtonCareerLocation } from './styles';
import { useLocationData } from 'features/hooks/useLocationData';
import { bootstrapSelect } from 'features/bootstrap';
import { languageSelect } from 'features/language/'
import GTM from 'features/services';

export const ButtonCareerLocationComponent: FunctionComponent<{ locationFilter: string }> = ({ locationFilter }) => {
  const offersButtonText = useMultilingualText(`offersButtonText`, 'whereWork');

  const { key, slug } = useLocationData();
  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);
  const [counter, setCounter] = React.useState(0);
  let history = useHistory();
  React.useEffect(() => {
    if (bootstrap.data.locations) {
      let tmp = bootstrap.data.locations.filter(value => value.key === slug);
      setCounter(tmp[0]['offersNumber']);
    }
  }, [bootstrap.data.locations, slug])

  const calcOfferWord = (number: number) => (number === 1 ? ' OFERTA' : number % 10 > 1 && number % 10 < 5 ? 'OFERTY' : 'OFERT');

  const onOffersClick = () => {
    const link = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10&page=1&${locationFilter}`;
    const offersText = lang === "en" ? `JOB OFFERS` : `${calcOfferWord(+counter)} PRACY`;
    history.push(link);
    GTM(`${key}ClickOffer`, `${counter} ${offersText}`, link)
  }

  return <>
    <ColButtonLocation lg={6.953} md={12} xsOrder={4} >
      <ButtonCareerLocation onMouseUp={() => {
        onOffersClick();
      }}>
        <WrapperTextInButton>
          <CountInButtonLocation>{counter}</CountInButtonLocation>
          <TextInButtonLocation>{lang === "en" ? offersButtonText : `${calcOfferWord(+counter)}\nPRACY`}</TextInButtonLocation>
        </WrapperTextInButton>
      </ButtonCareerLocation>
    </ColButtonLocation>
  </>
}