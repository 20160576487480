import { media } from 'core/theme/media';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import pin from 'assets/images/home/pin.svg';
import arrowButton from 'assets/images/home/arrow.svg';
import arrowWhite from 'assets/images/home/frame-c-arrow-right-white.svg';
import { IArrow } from 'view/pages/recruitment/interfaces';

export const OfferContainer = styled.div`
  border: solid 1px ${(props) => props.theme.colors.lines};
  width: 35.625rem;
  height: 11.25rem;
  margin-bottom: 1.875rem;
  ${media.down('md')} {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1400px) {
    width: 100%;
  }
`;
export const Title = styled.div`
  min-width: 9.813rem;
  height: 1.875rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #202430;
  margin-left: 2.5rem;
  margin-top: 1.875rem;

  ${media.up('md')} {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 1.875rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  ${media.between('sm', 'md')} {
    overflow: hidden;
  }
`;
export const Pin = styled.div`
  width: 0.75rem;
  height: 1rem;
  background-image: url(${pin});
  background-position: center center;
  background-size: 0.75rem 1rem;
`;
export const Location = styled.div`
  display: flex;
  margin-top: 1.1rem;
  margin-left: 2.5rem;
`;
export const LocationText = styled.div`
  margin-left: 0.4rem;
  margin-top: -0.25rem;
`;

export const Button = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 5rem;
  right: 2.5rem;
  margin-right: 15px;
  width: 10.313rem;
  height: 3.75rem;
  transition: 0.1s;
  background-color: ${(props) => props.theme.colors.blueButtonBackground};
  ${media.down('sm')} {
    position: sticky;
    margin-top: 1.25rem;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
  }
`;
export const ButtonText = styled.div`
  text-transform: uppercase;
  margin-top: 1.08rem;
  margin-left: 1rem;
  width: 3.063rem;
  height: 1.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.lightText};
`;
export const ButtonCounter = styled.div`
  margin-left: 1.5rem;
  margin-top: 0.7rem;
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.colors.lightText};
  ${media.down('sm')} {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;
export const ButtonIcon = styled.div`
  margin-left: 0.5rem;
  margin-top: 1.05rem;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(${arrowButton});
  background-position: center center;
  background-size: 1.5rem 1.5rem;
  transition: 0.1s;
  ${Button}:hover & {
    background-image: url(${arrowWhite});
  }
  ${media.down('sm')} {
    margin-left: 0;
    margin-right: 1.25rem;
  }
`;
export const OfferBoxMobile = styled.div<IOfferBoxMobile>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 0.063rem solid ${(props) => props.theme.colors.lines};
  cursor: pointer;
  ${(props) => props.last && !props.isAnswerVisible && `border-bottom: 0.063rem solid ${props.theme.colors.lines};`};
`;
export const Name = styled.h3`
  margin: 0;
  padding: 1rem 0;
  width: 89%;
  font-weight: normal;
  padding: 1.563rem 0;
`;
export const ArrowMobile = styled.img<IArrow>`
  transition: transform 0.3s;
  ${(props) =>
    props.up &&
    `
    transform: rotate(180deg);
    `}
`;
export const OfferMobile = styled.div<IOfferBoxMobile>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 61.25rem;
  padding-bottom: 1.25rem;
  ${(props) => props.last && props.isAnswerVisible && `border-bottom: 0.063rem solid ${props.theme.colors.lines};`};
`;
