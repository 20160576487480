import React, { FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';

import { useMultilingualText } from 'features/hooks';
import Container from 'view/components/content/container';
import { ContentOfDepartment } from './interfaces';
import { DepartmentDescription, DepartmentTitle } from './styles';


export const BannerDepartment: FunctionComponent<ContentOfDepartment> = ({ id }) => {
  const bannerDepartmentName = useMultilingualText(`locations.${id}.name`, 'whereWork');
  const bannerDepartmenDescription = useMultilingualText(`locations.${id}.description`, 'whereWork');
  return <Container>
    <Row>
      <Col id={id}>
        <DepartmentTitle>{bannerDepartmentName}</DepartmentTitle>
      </Col>
      <Col lg={9}>
        <DepartmentDescription>{bannerDepartmenDescription} </DepartmentDescription>
      </Col>
    </Row>
  </Container>;
};