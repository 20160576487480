import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';
import { media } from 'core/theme';
import CameraGallery from 'assets/images/locations/camera-gallery.svg';

export const ColGallery = styled(Col)`
  cursor: pointer;
  display: flex;
  width: unset;
  justify-content: flex-end;
  align-items: flex-end;
  img {
    width: 100%;
    min-width: 7.813rem;
    height: auto;
    
    ${media.up('lg')} {
      width: 100%;
      height: auto;
    }
  }
`
export const BlackRectangle = styled.div`
  background-color: ${ props => props.theme.colors.darkText};
  position: absolute;
  width: 7.063rem;
  height: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;



  p {
    font-weight: 300;
    line-height: normal;
    color: ${ props => props.theme.colors.lightText};
    font-size: 1.5rem;
  }

  &:after {
    display: inline-block;
    content: " ";
    background: url("${ CameraGallery }") no-repeat;
    background-size: 1.375rem;
    width: 1.375rem;
    height: 1.188rem;
  
  }


  ${media.up('md')} {
    width: 14.063rem;
    height: 6.25rem;
    p {
      font-size: 2.25rem;
    }

    &:after {
      background-size: 1.875rem;
      width: 1.875rem;
      height: 1.688rem;
    }
  }
`