import styled from 'styled-components';
import { media } from 'core/theme/media';
import plus from 'assets/images/home/plus.svg';
import plus2 from 'assets/images/home/plus2.svg';
import plusBlue from 'assets/images/home/plus-blue.svg';
export const CultureContainer = styled.div<ICultureContainer>`
	width: 23.125rem;
	height: 28.125rem;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	background-size: cover;

	${media.down('sm')} {
		height: 12.5rem;
		width: 100%;
		${(props) => (props.isRight) && `width: calc(100% + 15px);`}
		${(props) => (props.imageMobile !== undefined) && `background-size: cover; background-position-x: 0;`}
		${(props) => (props.imageMobile !== undefined) && `background-image: url('${props.imageMobile}');`};
		${(props) => (props.imageMobile === undefined) && `border: solid 1px ${props.theme.colors.lines};`};
	}
	${media.up('sm')} {
		${(props) => (props.image !== undefined) && `background-image: url('${props.image}');`};
		${(props) => (props.image === undefined) && `border: solid 1px ${props.theme.colors.lines};`};
	}
	@media (min-width: 900px) and (max-width: 1280px) { 
    width: 100%;
		height: 100%;
  }
	
`;
export const Title = styled.div<ITitleCulture>`
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: -0.28px;
	
	margin-left: 2.5rem;
	margin-bottom: 0.938rem;
	width: 15.625rem;
	white-space: pre-wrap;
	${(props) => props.image === undefined && `color: ${props.theme.colors.darkText};`};
	${(props) => props.image !== undefined && `color: ${props.theme.colors.lightText};`};
	${media.down('sm')} {
		width: 6.938rem;
		font-size: 0.875rem;
		margin-left: 1.188rem;
		margin-top: 8.75rem;
		letter-spacing: -0.16px;
		height: 2.5rem;
		${(props) => props.imageMobile === undefined && `color: ${props.theme.colors.darkText};`};
		${(props) => props.imageMobile !== undefined && `color: ${props.theme.colors.lightText};`};
	}
	@media (min-width: 900px) and (max-width: 1280px) { 
		margin-left: 1.5rem;
	}
`;
export const Description = styled.div<IDescriptionCulture>`
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: ${ props => props.theme.colors.lightText };
	margin-left: 2.5rem;
	width: 18.938rem;
	${(props) => props.image === undefined && `color: ${props.theme.colors.darkText};`};
	${(props) => props.image !== undefined && `color: ${props.theme.colors.lightText};`};
	${media.down('sm')} {
		display: none;
	}
	@media (min-width: 900px) and (max-width: 1280px) { 
		width: 86%;
    margin-bottom: 1rem;
		margin-left: 1.5rem;
	}
`
export const Plus = styled.div<IPlusCulture>`
	height: 2.125rem;
	width: 2.125rem;
	margin-left: 18.5rem;
	margin-top: 2.5rem;
	margin-bottom: 11.25rem;
	background-repeat: no-repeat;
	background-position: center center;
	${CultureContainer}:hover & {
		background-image: url(${plusBlue});
	}
	transition: background-image 0.3s;
	${media.down('sm')} {
		position: absolute;
		${(props) => (props.imageMobile === undefined) && `background-image: url("${plus2}");`};
		${(props) => (props.imageMobile !== undefined) && `background-image: url("${plus}");`};
		width: 1.063rem;
		height: 1.063rem;
		margin-left: 8.238rem;
		margin-top: 1.0rem;
		background-size: 1.063rem;
	}
	${media.up('sm')} {
		${(props) => (props.image === undefined) && `background-image: url("${plus2}");`};
		${(props) => (props.image !== undefined) && `background-image: url("${plus}");`};
	}
	@media (min-width: 900px) and (max-width: 1280px) { 
		position: relative;
		margin-bottom: 9.25rem;
		width: 2.125rem;
    height: 2.125rem;
		right: 2.5rem;
  }
`;