export const happyParents = {
    happyParentsLoaded :{
        event: 'virtualPageView',
        virtualPageView: '/how-we-work/happy-parents',
    },
    happyParentsNavigation: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    happyParentsLinkTo: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    happyParentsVideoEnters: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie enters',
    },
    happyParentsVideoPlays: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie plays',
    },
    happyParentsVideoExits: {
        event: 'backendMicroconversion',
        category: 'Clicks - movie exits',
    },
}