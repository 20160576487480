import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import SmartworkWhiteFrom from 'assets/images/smart-work/smartwork-white-logo.png';
import { BanerTop, WrapperHeadBannerDesc, IconEmployeeProgramsPage } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

export const TopBannerSmartWork: FunctionComponent = () => {
  return <>
    <BanerTop>
      <Container type="large">

          <WrapperHeadBannerDesc>
            <IconEmployeeProgramsPage iconImg={ SmartworkWhiteFrom } />
            <h2><MultilingualText tKey="salary-title" area="smartWork" /></h2>

          </WrapperHeadBannerDesc>


      </Container>
    </BanerTop>
</>;
};
