import React, {FunctionComponent} from "react";
import Container from "../../../components/content/container";

import {EmployeeProgram} from "../EmployeeProgram/EmployeeProgram";
import {EmployeeProgramsList} from "../EmployeePrograms/EmployeeProgramsList";

import MultilingualText from "../../../components/content/MultilingualText/MultilingualText";

import { EmployeeProgramTitle } from "./styles";
import { WrapperEmployeePrograms } from "./styles";

export const EmployeePrograms: FunctionComponent = () => {

    return (
        <Container>
            <WrapperEmployeePrograms>
                <EmployeeProgramTitle><MultilingualText tKey="employeeProgram.title"
                                                        area="howWeWork"/></EmployeeProgramTitle>
                {EmployeeProgramsList.map((benefit) =>
                    <EmployeeProgram key={benefit.id} image={benefit.image} id={benefit.id}/>
                )}
            </WrapperEmployeePrograms>

        </Container>
    )
}