import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'styled-bootstrap-grid';

import { useMultilingualText, useBreakpoints } from 'features/hooks';
import { bootstrapSelect } from 'features/bootstrap';
import { languageSelect } from 'features/language/';
import LazyImage from 'view/components/content/LazyImage';

import { ContentOfOffice } from './interfaces';
import {
  ButtonCareer,
  CareerActions,
  ColOfficeDescription,
  ContainerOffiec,
  CountInButton,
  DetailsLink,
  ImgOffiec,
  OfficeDescription,
  OfficeTitle,
  TextInButton,
} from './styles';
import GTM from 'features/services';


export const BannerOffice: FunctionComponent<ContentOfOffice> = ({ tKey, linkTo, imgOfOffice }) => {
  const bannerOfficeSlug = useMultilingualText(`offices.${tKey}.slug`, 'whereWork');
  const bannerOfficeName = useMultilingualText(`offices.${tKey}.name`, 'whereWork');
  const bannerOfficeDescription = useMultilingualText(`offices.${tKey}.description`, 'whereWork');
  const detailsButton = useMultilingualText(`detailsButtonText`, 'whereWork');
  const offersButtonText = useMultilingualText('offersButtonText', 'whereWork');

  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);
  const [counter, setCounter] = React.useState(0);
  const [id, setId] = React.useState(0);
  let history = useHistory();
  const { isSmall } = useBreakpoints();
  React.useEffect(() => {
    if (bootstrap.data.locations) {
      let tmp = bootstrap.data.locations.filter(value => value.key === bannerOfficeSlug);
      setCounter(tmp[0]['offersNumber']);
      setId(tmp[0]['params']['cities']);
    }
  })

  const offersLimit = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10&page=1&cities=${id}`

  const jobOffers = () => {
    history.push(offersLimit);
    GTM('whereWorkingOffers', `${offersButtonText} - ${counter} (${bannerOfficeName})`, `${offersLimit}`)
  }

  return <ContainerOffiec type={'big'} right>
    <Row>
      <ColOfficeDescription lg={4.5} md={4.5} xsOrder={2}>
        <OfficeTitle>{bannerOfficeName}</OfficeTitle>
        <OfficeDescription>{bannerOfficeDescription}</OfficeDescription>
        <CareerActions>
          <ButtonCareer onMouseUp={() => { jobOffers() }}>
            <TextInButton>{offersButtonText}</TextInButton>
            <CountInButton>{counter}</CountInButton>
          </ButtonCareer>
          <DetailsLink onMouseUp={() => GTM('whereWorkingLinkTo', `${detailsButton} (${bannerOfficeName})`, `${window.location.host}/working-places/${linkTo}`)} to={linkTo}>{detailsButton}</DetailsLink>
        </CareerActions>

      </ColOfficeDescription>
      <Col lg={7.5} md={7.5} xsOrder={1}>
        <div>
          <LazyImage src={imgOfOffice} sccss={ImgOffiec} alt="" height={isSmall ? '307px' : '450px'} />
        </div>
      </Col>
    </Row>
  </ContainerOffiec>;
};