import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import bootstrapReducer from 'features/bootstrap';
import languageReducer from 'features/language';
import pageReducer from 'features/page';

export const store = configureStore({
  reducer: {
    bootstrap: bootstrapReducer,
    page: pageReducer,
    language: languageReducer,
  },
  middleware: [thunk],
  enhancers: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
