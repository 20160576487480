import styled from 'styled-components';
import { media } from 'core/theme/media';
import icon from 'assets/images/icons/arrow-right-white.svg';
import iconHover from 'assets/images/icons/arrow-right-white-hover.svg';

const arrowIcon = styled.div`
  width: 3rem;
  height: 3rem;
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.1s;

  ${media.down('sm')} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
export const TopBanner = styled.div<{ backgroundImage: string }>`
    height: 43.75rem;
    width: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    ${media.down('sm')} {
      height: 27.5rem;
    }
  `,
  PageTitle = styled.h1`
    color: ${(props) => props.theme.colors.lightText};
    margin: 0;
    padding-bottom: 7.5rem;
    width: 27rem;

    ${media.down('md')} {
      padding-bottom: 2.6rem;
      width: 19rem;
    }
    ${media.down('sm')} {
      background-position: 63%;
    }
  `,
  Button = styled.div`
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    cursor: pointer;
    height: 10rem;
    padding-left: 3.813rem;
    padding-right: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;
    margin-left: 3.2rem;
    width: 92%;

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
    :hover ${arrowIcon} {
      background-image: url(${iconHover});
    }

    ${media.down('md')} {
      padding-left: 2.188rem;
      padding-right: 1.875rem;
      margin-left: 0;
      width: 100vw;
    }
    ${media.down('sm')} {
      height: 5rem;
      padding-right: 3.75rem;
    }
  `,
  Text = styled.div`
    display: flex;
    align-items: center;
  `,
  OffersNumber = styled.h1`
    color: ${(props) => props.theme.colors.lightText};
    margin-right: 1.25rem;

    ${media.down('md')} {
      margin-right: 0.938rem;
    }
  `,
  ArrowIcon = arrowIcon,
  ButtonText = styled.div`
    text-transform: uppercase;
    white-space: break-spaces;
    font-weight: 600;
    line-height: 1.25;
    color: ${(props) => props.theme.colors.lightText};

    ${media.down('sm')} {
      font-size: 0.875rem;
      line-height: 1.07;
    }
  `;
