import AboutUs from 'view/pages/about-us';
import ContactPage from 'view/pages/contact';

import HomePage from 'view/pages/home';
import { LocationComponent } from 'view/pages/locationPages/LocationComponent';
import { NewsPage } from 'view/pages/news/news';
import { PrivacyPage } from 'view/pages/privacy/privacy';
import Recruitment from 'view/pages/recruitment';

import Teams from 'view/pages/teams';
import { WhereWorkMain } from 'view/pages/whereWorking/whereWorkMain';
import { JobOffersDetailsPage } from '../../view/pages/job-offers/details/JobOffersDetailsPage/JobOfferDetailsPage';
import { JobOffersListPage } from '../../view/pages/job-offers/list/JobOffersListPage/JobOffersListPage';
import LocationData from './location-data';
import { IRoute } from './router';
import { teamsData } from './teams-data'
import HappyParentPage from '../../view/pages/happy-parents';
import { EqualSalaryPage } from '../../view/pages/equal-salary/EqualSalary';
import HistoryPmiPage from "../../view/pages/history-pmi-page";
import {MissionIsPossible} from "../../view/pages/mission-is-possible/MissionIsPossible";
import {HowWeWorkPage} from "../../view/pages/how-we-work/HowWeWork";
import {SmartWork} from "../../view/pages/smart-work/smart-work";
import {PrivacyMailPage} from "view/pages/privacy/privacy-mail";

export const teamsPath = ['/teams', '/en/teams'];
export const Routes = [
  {
    path: ['/', '/en/'],
    exact: true,
    component: HomePage,
    menu: {
      visible: false,
      title: 'homePage',
    },

  },
  {
    path: ['/contact', '/en/contact'],
    exact: true,
    component: ContactPage,
    menu: {
      visible: false,
      title: 'contact',
    },
  },

  {
    path: ['/job-offers', '/en/job-offers'],
    exact: true,
    component: JobOffersListPage,
    menu: {
      visible: false,
      title: 'jobOffers',
    },
  },
  {
    path: ['/job-offers/:slug', '/en/job-offers/:slug'],
    exact: true,
    component: JobOffersDetailsPage,
    menu: {
      visible: false,
      title: 'jobOffers',
    },
  },
  {
    path: ['/about-us', '/en/about-us'],
    menu: {
      visible: true,
      title: 'whoWeAre',
    },
    children: [
      {
        path: ['/'],
        exact: true,
        component: AboutUs,
        menu: {
          visible: true,
          title: 'whoWeAre',
        },
      },
      {

        path: ['/history-pmi'],
        exact: true,
        component: HistoryPmiPage,
        menu: {
          visible: true,
          title: 'History_Pmi',
        },
      },

    ]
  },

  {
    path: [ '/en/working-places', '/working-places' ],
    menu: {
      visible: true,
      title: 'whereWeWork'
    },
    children: [
      {
        path: ['/general-information'],
        exact: true,
        component: WhereWorkMain,
        menu: {
          visible: true,
          title: 'whereWeWork_mainInfo',
        },
      },
      'divider',

    ].concat(LocationData.map((locationRoute) => ({
      path: [`/${locationRoute.name}`],
      component: LocationComponent,
      exact: true,
      menu: {
        visible: true,
        title: `location_${locationRoute.key}`,
      },
    })))
  },
  {
    path: ['/how-we-work', '/en/how-we-work'],
    exact: true,

    menu: {
      visible: true,
      title: 'HowWeWork',
    },
    children: [
      {
        path: ['/general-information'],
        exact: true,
        component: HowWeWorkPage,
        menu: {
          visible: true,
          title: 'whereWeWork_mainInfo',
        },
      },
      'divider',
      {
        path: ['/smart-work'],
        exact: true,
        component: SmartWork,
        menu: {
          visible: true,
          title: 'Smart Work',
        },
      },
      {
        path: ['/happy-parents'],
        exact: true,
        component: HappyParentPage,
        menu: {
          visible: true,
          title: 'Happy Parents',
        },
      },
      {
        path: ['/equal-salary'],
        exact: true,
        component: EqualSalaryPage,
        menu: {
          visible: true,
          title: 'Equal Salary',
        },
      },


    ]
  },
  {
    path: teamsPath,
    menu: {
      visible: true,
      title: 'teams',
    },
    children: teamsData.map((teamRoute) => ({
      path: [`/${teamRoute.path}`],
      component: Teams,
      exact: true,
      menu: {
        visible: true,
        title: teamRoute.tKey,
      },
    }))
  },
  {
    path: ['/news', '/en/news'],
    exact: true,
    component: NewsPage,
    menu: {
      visible: false,
      title: 'News'
    },
  },
  {
    path: ['/recruitment', '/en/recruitment'],
    component: Recruitment,
    exact: true,
    menu: {
      visible: true,
      title: 'recruitmentProcess',
    },
  },
  {
    path: ['/privacy-policy', '/en/privacy-policy'],
    exact: true,
    component: PrivacyPage,
    menu: {
      visible: false,
      title: 'Privacy Policy',
    },
  },
  {
    path: ['/privacy-policy-mail', '/en/privacy-policy-mail'],
    exact: true,
    component: PrivacyMailPage,
    menu: {
      visible: false,
      title: 'Privacy Policy',
    },
  },
  //  Not display at the moment page mission-is-possible
  // {
  //   path: ['/mission-is-possible', '/en/mission-is-possible'],
  //   exact: true,
  //   component: MissionIsPossible,
  //   menu: {
  //     visible: false,
  //     title: 'Mission Is Possible',
  //   },
  // },
];

export const getOnlyMenuRoutes = () => Routes.filter((item: IRoute) => item.menu.visible);
export const getRoutersToRender = (list: (IRoute | 'divider')[] = Routes) => {
  let flattenList: IRoute[] = [];
  list.forEach((item: IRoute | 'divider') => {
    if (item === 'divider') {
      return;
    }

    if (item.component !== undefined) {
      flattenList.push(item);
    }

    if (item.children !== undefined && item.children.length > 0) {
      const subRoutes = item.children.map((children: IRoute | 'divider') => {
        let newPath;
        if (children !== 'divider') {

          newPath = item.path.map(x => (`${x}${children.path[0]}`))
        }
        return Object.assign({}, children, { path: newPath });
      });
      flattenList = flattenList.concat(...getRoutersToRender(subRoutes));
    }
  });

  return flattenList;
};
