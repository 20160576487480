import React, {FunctionComponent, useEffect} from 'react';
import { Main } from '../about-us/styles';
import TopBannerHappyParent from './TopBannerHappyParent';
import { MainTextHappyParets } from './MainTextHappyParets/MainTextHappyParets';
import SumItUpFromHappy from './SumItUpFromHappy';
import { WhyImplementHappy } from './WhyImplementHappy/WhyImplementHappy';
import { VideoHappyParents } from './VideoHappyParents/VideoHappyParents';
import { ReviewsWorkers } from './ReviewsWorkers/ReviewsWorkers';
import { InclusionHappyParents } from './InclusionHappyParents/InclusionHappyParents';
import BannersHappyParentBottom from './BannersHappyParentBottom';
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";
import {useLocation} from "react-router-dom";
import GTM from "features/services";

export const HappyParentPage : FunctionComponent= () => {
  const {lang} = useSelector(languageSelect);
  const location = useLocation();
  useEffect(() => {
    GTM('happyParentsLoaded', undefined, undefined, location.pathname);
  }, [lang]);

  return <Main>
    <TopBannerHappyParent />
    <MainTextHappyParets />
    <SumItUpFromHappy />
    <WhyImplementHappy />
    <VideoHappyParents />
    <ReviewsWorkers />
    <InclusionHappyParents />
    <BannersHappyParentBottom />
  </Main>
}
