import styled from 'styled-components';
import { media } from 'core/theme';
import { Video } from '../../about-us/components/Feature/styles';
import { Row } from 'styled-bootstrap-grid';

export const VideoHappy = styled.div<{ backgroundImage: string }>`
          width: 100%;
          height: 203px;
          background-size: cover;
          background-image: url('${ (props) => props.backgroundImage }');
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.875rem;

          ${ media.up('md') } {
            height: 800px;
          }

          ${ media.down('md') } {
            margin-left: -15px;
            width: 100vw;
          }
  `,
  PlayButtonHappy = styled.img`
    transition: 0.3s;
    height: 170px;
    width: auto;

    ${ media.down('md') } {
      height: 40px;
    }

    ${ Video }:hover & {
      opacity: 0.3;
    }
  `;

export const RowTitleVideoHappyParents = styled(Row)`
  margin-top: 60px;
  margin-bottom: 20px;

  ${ media.up('md') } {
    margin-top: 150px;
    margin-bottom: 100px;
  }
`;

export const TitleVideoHappyParents = styled.h2`
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  max-width: 315px;

  ${ media.up('md') } {
    font-weight: 300;
    letter-spacing: -0.46px;
    max-width: 100%;
  }
`;
