import styled from 'styled-components';
import closeIcon from 'assets/images/job-offers/close-icon.svg';
import { media } from 'core/theme';

export const CloseIcon = styled.div`
  width: 32px;
  height: 32px;
  background-size: 100% 100%;
  background-image: url(${closeIcon});
  cursor: pointer;
`;

export const WrapperPhrase = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 12px;

  ${media.up('sm')} {
    gap: 16px;
    margin-top: 32px;
  }
`;
