import React, { FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';

import Container from 'view/components/content/container';
import { useBreakpoints } from 'features/hooks';
import LazyImage from 'view/components/content/LazyImage';

import AboutUsDescription from 'assets/images/about-us/about-us-description.jpg';
import { DescriptionImage, Explanation, Header, LeftHeader, Paragraph, SmallHeader, TextBox, ImageBox } from './styles';

import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';


export const AboutUsTextSection: FunctionComponent = () => {
  const { isLarge, isSmall } = useBreakpoints();

  const resolveImageColStyles = () => isLarge ? {} : { padding: 0 };

  return <div>
    <Container>
      <TextBox>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <LeftHeader>
              <MultilingualText tKey="leftHeader" area="aboutUs" />
            </LeftHeader>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Header><MultilingualText tKey="header1" area="aboutUs" /></Header></Col>
        </Row>
        <Row>
          <Col smOffset={0} sm={12} mdOffset={0} md={12} lgOffset={6} lg={6}>
            <Paragraph>
              <MultilingualText tKey={isSmall ? `paragraph1Mobile` : `paragraph1`} area="aboutUs" />
            </Paragraph>
            {isSmall &&
              <Explanation>
                <SmallHeader><MultilingualText tKey="header2" area="aboutUs" /></SmallHeader>
                <Paragraph>
                  <MultilingualText tKey="paragraph2" area="aboutUs" />
                </Paragraph>
              </Explanation>}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={5} style={resolveImageColStyles()}>
            <ImageBox>
              <LazyImage src={AboutUsDescription} alt="" sccss={DescriptionImage} width="61rem" height={isSmall ? "273px" : "709px"} />
            </ImageBox>
          </Col>
          <Col sm={12} md={12} lg={6} lgOffset={1}>
            {!isSmall &&
              <Explanation>
                <SmallHeader><MultilingualText tKey="header2" area="aboutUs" /></SmallHeader>
                <Paragraph>
                  <MultilingualText tKey="collageText" area="aboutUs" />
                </Paragraph>
              </Explanation>}
          </Col>
        </Row>
      </TextBox>
    </Container>
  </div>;

};
