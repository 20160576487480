import styled from 'styled-components';
import { media } from 'core/theme/media';
import checkbox from 'assets/images/icons/checkbox.svg';

export const Main = styled.div`
    margin-top: 0.9375rem;
    ${media.down('md')} {
      margin-top: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 1.25rem 0 1.75rem 0;
    }
  `,
  CheckboxLabel = styled.label`
    cursor: pointer;
    display: flex;
    font-size: 0.938rem;
    padding: 0.219rem 0;

    ${media.down('md')} {
      padding: 0.625rem 0;
      align-items: center;
    }

    span {
      width: 16rem;
      ${media.down('md')} {
        width: 100%;
      }
    }
    input {
      margin: 0 0.563rem 0 0;
      width: 1.125rem;
      min-width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      border: 1px solid #7991c0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer;

      ${media.down('md')} {
        width: 1.563rem;
        min-width: 1.563rem;
        height: 1.563rem;
      }
    }
    input:checked {
      width: 1.125rem;
      height: 1.125rem;
      background-image: url(${checkbox});
      background-size: contain;
      background-position: center;
      border: none;

      ${media.down('md')} {
        width: 1.563rem;
        height: 1.563rem;
      }
    }
    input:checked + span::before {
      width: 1.125rem;
      height: 1.125rem;
      background-image: url(${checkbox});
      background-size: contain;
      background-position: center;
      border: none;

      ${media.down('md')} {
        width: 1.563rem;
        height: 1.563rem;
      }
    }
    input:active {
      border: 2px solid ${(props) => props.theme.colors.blueButtonBackground};
    }
  `;
