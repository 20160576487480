import styled from 'styled-components';
import { media } from 'core/theme/media';
import { Row, Col } from 'styled-bootstrap-grid';
import Container from 'view/components/content/container';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search-icon.svg';
import closeCircleIcon from 'assets/images/icons/close-circle-icon.svg';

export const Main = styled.div`
    background-color: ${(props) => props.theme.colors.darkText};
    padding: 1.875rem 0;
    box-sizing: content-box;
    font-family: ${(props) => props.theme.font};
    font-weight: 300;
    height: 12.5rem;
    overflow-x: hidden;
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    ${media.down('md')} {
      height: 100%;
      padding: 1.875rem 0;
    }
  `,
  MainContainer = styled(Container)`
    height: 100%;
    ${media.down('md')} {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  `,
  MainRow = styled(Row)`
    height: 100%;
  `,
  SearchTitle = styled.h1`
    color: ${(props) => props.theme.colors.lightText};
    letter-spacing: -0.75px;
    margin: 0;
    ${media.down('md')} {
      font-size: 1.875rem;
      padding-bottom: 2rem;
      padding-left: 0.9375rem;
    }
  `,
  SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
  `,
  SearchInput = styled.input`
    font-family: 'Poppins', serif;
    width: 100%;
    text-indent: 1.25rem;
    height: 3.75rem;
    font-size: 1rem;
    line-height: 1.2rem;

    &:focus {
      outline: none;
    }
  `,
  SearchIconBox = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    height: 3.75rem;
    width: 3.75rem;
    transition: all 0.3s;
    cursor: pointer;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
  `,
  SVGIcon = styled(SearchIcon)`
    width: 1rem;
  `,
  SearchBoxCol = styled(Col)`
    ${media.up('md')} {
      padding-right: 0;
    }
  `,
    CloseIcon = styled.div`
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      background-image: url(${closeCircleIcon});
      cursor: pointer;
      position: absolute;
      right: 84px;
      top: 50%;
      transform: translateY(-50%);

      ${media.up('md')} {
        right: 70px;
      }
    `;
