import styled from "styled-components";
import {media} from "../../../../core/theme";

export const WrapperEmployeePrograms = styled.div`
  margin-top: 136px;

  ${media.down('sm')} {
    margin-top: 40px;
  }
`
export const EmployeeProgramTitle = styled.h2`
  margin-bottom: 96px;

  ${media.down('sm')} {
    margin-bottom: 16px;
  }
`