import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';

import {

  ConteinerDescription,
  Title,
  WrapperHead,
} from './styles';
import { Theme } from 'core/theme';
import { BannerLocation } from './components/baner-location';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid';
import { BannerBox } from '../recruitment/styles';
import JoinUsBanner from '../recruitment/components/JoinUsBanner';
import { useSelector } from 'react-redux';
import { languageSelect } from 'features/language';
import MultilingualText from '../../components/content/MultilingualText/MultilingualText';
import {useLocation} from "react-router-dom";
import GTM from "features/services";


export const ContactPage: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const IframeCracow = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.8152816656207!2d20.018746315885824!3d50.07102092251223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471645224d194871%3A0x85569064e45f65ed!2sPhilip%20Morris%20Polska%20S.A.!5e0!3m2!1spl!2spl!4v1600948285720!5m2!1spl!2spl';
  const IframeWarsaw = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9769.20187031592!2d20.9947984!3d52.2560886!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1d7d29c6c026c0b8!2sPhilip%20Morris%20Polska%20Distribution%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1601235827977!5m2!1spl!2spl';
  const location = useLocation();

  useEffect(() => {
    GTM('contactPageLoaded', undefined, undefined, location.pathname);
  }, [lang]);

  return <>
    <WrapperHead>
      <Container type={'big'} right={true}>
        <Row>
          <Col lg={5} lgAlignSelf="center">
            <ConteinerDescription>
              <Title><MultilingualText tKey="contact" area="contact" /></Title>
            </ConteinerDescription>
          </Col>
        </Row>
      </Container>
    </WrapperHead>
    <Container>
      <BannerLocation department={'PMI SCE'}
        businessName={lang === 'en' ? 'Integrated Business Services\nPMI Service Center Europe\u00A0Sp.\u00A0z\u00A0o.o.' : 'Zintegrowane Usługi Biznesowe w\u00A0Krakowie\nPMI Service Center Europe\u00A0Sp.\u00A0z\u00A0o.o.'}
        addres={'al. Jana Pawła II 196'}
        postcode={lang === 'en' ? '31-982 Krakow' : '31-982 Kraków'}
        phoneNamber={'+48 12 646 33 27'}
        iframeMap={IframeCracow}
        color={Theme.colors.location.blue}
      />

      <BannerLocation department={lang === 'en' ? 'Factory' : 'Fabryka'}
        businessName={'Philip Morris Polska\u00A0S.A.'}
        addres={'al. Jana Pawła II 196'}
        postcode={lang === 'en' ? '31-982 Krakow' : '31-982 Kraków'}
        phoneNamber={'+48 12 646 4646'}
        iframeMap={IframeCracow}
        color={Theme.colors.location.yellow}
      />

      <BannerLocation department={lang === 'en' ? 'Warsaw' : 'Warszawa'}
        businessName={'Philip Morris Polska Distribution\u00A0Sp.\u00A0z\u00A0o.o.'}
        addres={'ul. Inflancka 4C'}
        secondAdress={'Gdański Business Center\u00A0D'}
        postcode={lang === 'en' ? '00-189 Warsaw' : '00-189 Warszawa'}
        phoneNamber={'+48 22 606 22 22'}
        iframeMap={IframeWarsaw}
        color={Theme.colors.location.turquoise}
      />
    </Container>
    <BannerBox>
      <JoinUsBanner tKey="banner" area="contact" link={`${lang === 'en' ? '/en' : ''}/recruitment`} />
    </BannerBox>
  </>;
};
