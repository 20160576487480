export const smartWork = {
  smartWorkLoaded :{
    event: 'virtualPageView',
    virtualPageView: '/how-we-work/smart-work',
  },
  smartWorkNavigation: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  smartWorkLinkTo: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  smartWorkVideoEnters: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  smartWorkVideoPlays: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  smartWorkVideoExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
}