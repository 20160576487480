import { FunctionComponent, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { languageSelect, changeLanguage } from 'features/language/'

export const LanguageHighOrderComponent: FunctionComponent<{ renderComponent: (lang: string, onLanguageChange: Function) => ReactElement }> =
  ({ renderComponent }) => {
    const { i18n } = useTranslation()
    const { lang } = useSelector(languageSelect);
    const { pathname } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const changeUrl = () => {
      const langToSet: 'pl' | 'en' = lang === 'pl' ? 'en' : 'pl';
      const action = changeLanguage(langToSet);
      dispatch(action);
      i18n.changeLanguage(langToSet)

      const pathToSet = pathname.startsWith('/en') ? pathname.replace('/en', '') : `/en${pathname}`;
      history.push(pathToSet)
    }

    return renderComponent(lang, changeUrl);
  };