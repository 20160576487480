import React, { FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import Container from 'view/components/content/container';
import { useBreakpoints } from 'features/hooks';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import LazyBackgroundImage from 'view/components/content/LazyBackgroundImage';

import imageMobile from 'assets/images/job-offers/banner-mobile.jpg';
import Image from 'assets/images/job-offers/banner.jpg';
import {
    LargeContainer, Main, Content, Header, Paragraph, ButtonBox, Button, ArrowIcon, ImageCol, ImageComp, StyledLink
} from './styles';

export const Banner: FunctionComponent<{ tKey: string, area: string }> = ({ tKey, area }) => {
    const { isLarge } = useBreakpoints();
    const { t } = useTranslation("jobOffers");
    const getMultilingualCVItem = (key) => t(key);

    const CVlink = "https://pmi.avature.net/en_US/talentCommunity";
    const linkedinLink = "https://www.linkedin.com/company/insidepmi/jobs/";

    return <LargeContainer type="large">
        <LazyBackgroundImage image={imageMobile}>
            <Main backgroundImage={imageMobile}>
                <Container>
                    <Row alignItems="center">
                        <Col lg={6} md={12}>
                            <Content>
                                <Header><MultilingualText tKey="findAnyTitle1" area="jobOffers" /> {!isLarge && <br />}<MultilingualText tKey="findAnyTitle2" area="jobOffers" />{!isLarge && <br />} <MultilingualText tKey="findAnyTitle3" area="jobOffers" /></Header>
                                <Paragraph><MultilingualText tKey={tKey} area={area} /></Paragraph>
                                <StyledLink href={linkedinLink} target="_blank">
                                </StyledLink>
                                <StyledLink href={CVlink} target="_blank" onMouseUp={() => GTM('jobOffersButtonClick', `${getMultilingualCVItem("sendCv")} (${getMultilingualCVItem('findAnyTitle1')} ${getMultilingualCVItem('findAnyTitle2')}${getMultilingualCVItem('findAnyTitle3')})`, CVlink)}>
                                    <ButtonBox>
                                        <Button><MultilingualText tKey="sendCv" area="jobOffers" />
                                            <ArrowIcon />
                                        </Button>
                                    </ButtonBox>
                                </StyledLink>
                            </Content>
                        </Col>
                        {isLarge &&
                            <ImageCol lg={6} sm={12}>
                                <LazyBackgroundImage image={Image}>
                                    <ImageComp src={Image} />
                                </LazyBackgroundImage>
                            </ImageCol>
                        }
                    </Row>
                </Container>
            </Main>
        </LazyBackgroundImage>
    </LargeContainer>
};
