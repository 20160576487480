import stage1 from 'assets/images/recruitment/file-text.svg';
import stage2 from 'assets/images/recruitment/send-to-phone.svg';
import stage3 from 'assets/images/recruitment/b-meeting.svg';
import stage4 from 'assets/images/recruitment/questionnaire.svg';

import { IRecruitmentStage } from 'view/pages/recruitment/interfaces';
export const RecruitmentStages: IRecruitmentStage[] = [
  {
    key: 'stage1',
    image: stage1,
  },
  {
    key: 'stage2',
    image: stage2,
  },
  {
    key: 'stage3',
    image: stage3,
  },
  {
    key: 'stage4',
    image: stage4,
  },
];
