import { useLocationData } from 'features/hooks/useLocationData';
import React, { FunctionComponent } from 'react';

import { useMultilingualText, useMultilingualArray } from 'features/hooks';
import { Col } from 'styled-bootstrap-grid';
import Container from 'view/components/content/container';
import { DivDescription, RowManiDescription, SubTitle } from './styles';

export const MainDescriptioDepartment: FunctionComponent = () => {
  const { key } = useLocationData();
  const descriptioDepartmentAmenitiesContent = useMultilingualArray(`descriptioDepartment.amenities.content`, `locations_${key}`);
  const descriptioDepartmentAmenitiesTitle = useMultilingualText(`descriptioDepartment.amenities.title`, `locations_${key}`);

  const descriptioDepartmentLocationContent = useMultilingualArray(`descriptioDepartment.location.content`, `locations_${key}`);
  const descriptioDepartmentLocationTitle = useMultilingualText(`descriptioDepartment.location.title`, `locations_${key}`);

  const descriptioDepartmentAreaTitle = useMultilingualText(`descriptioDepartment.area.title`, `locations_${key}`);
  const descriptioDepartmentAreaContent = useMultilingualArray(`descriptioDepartment.area.content`, `locations_${key}`);
  const descriptioDepartmentTitle = useMultilingualText(`descriptioDepartment.title`, `locations_${key}`);
  return <>
    <Container>
      <RowManiDescription>
        <Col lg={6}>
          <SubTitle>{descriptioDepartmentTitle}</SubTitle>
        </Col>
        <Col lg={6}>
          <DivDescription>
            <span>{descriptioDepartmentLocationTitle}</span>
            {key !== "other" && <div><ul>
              {
                descriptioDepartmentLocationContent.map((x, index) =>
                  <li key={index}>{x}</li>,
                )
              }
            </ul>
              <span>{descriptioDepartmentAmenitiesTitle}</span>
              <ul>
                {
                  descriptioDepartmentAmenitiesContent.map((x, index) =>
                    <li key={index}>{x}</li>,
                  )
                }
              </ul>
              <span> {descriptioDepartmentAreaTitle}</span>
              <ul>
                {
                  descriptioDepartmentAreaContent.map((y, index) =>
                    y.length > 0 ? <li key={index}>{y}</li> : false,
                  )
                }
              </ul>
            </div>}
          </DivDescription>
        </Col>
      </RowManiDescription>
    </Container>
  </>;
};
