import styled from 'styled-components';
import { media } from 'core/theme';
import LinkedinLogo from 'assets/images/news/logo-linkedin.svg';
import FacebookLogo from 'assets/images/news/logo-fb-simple.svg';
import TwitterLogo from 'assets/images/news/logo-twitter.svg';

export const HeaderNews = styled.h2`
  font-weight: normal;

  ${ media.up('lg') } {
    margin-top: 0;
  }
`;
export const DataNews = styled.p`
  ${ media.up('lg') } {
    margin-bottom: 0.625rem;
  }
`;
export const BrowLine = styled.hr`
  border: 0.063rem solid ${ props => props.theme.colors.lines };
  margin-top: 6.25rem;
  margin-bottom: 0;

  ${ media.down('md') } {
    display: none;
  }
`;
export const ImgNews = styled.img`
  width: 100%;
`;
export const SecondImgSmale = styled(ImgNews)`
  max-width: 25rem;
`;

export const BlockContent = styled.div`
  margin-top: 1.875rem;

  p {
    margin-top: 1.875rem;
    line-height: normal;
    margin-bottom: 0;
  }

  ${ media.up('lg') } {
    margin-top: 6.563rem;

    p {
      line-height: 1.75;
    }
  }
`;
export  const BlockContentLastElement=styled(BlockContent)`
  margin-bottom: 13rem;

  ${ media.down('md') } {
    margin-bottom: 0;
  }
`
export const BlockSocialMedia = styled(BlockContent)`
  width: 12.5rem;
  height: 11.688rem;
  border: 0.0625rem solid ${ props => props.theme.colors.lines };

  ${ media.down('md') } {
    display: none;
  }
`;
export const BlockSocialMediaBottom = styled.div`
  margin-top: 2rem;
  margin-bottom: 3.75rem;
  max-width: 19.688rem;
  width: 100%;
  height: 3.75rem;
  border: 0.0625rem solid ${ props => props.theme.colors.lines };

  ${ media.up('lg') } {
    display: none;
  }
`;
export const UlSocialMedia = styled.ul`
  list-style-type: none;
  padding-left: 1.875rem;
  margin-top: 1.875rem;

  li {
    margin-bottom: 0.625rem;
  }

  ${ media.down('md') } {
    display: flex;
    flex-direction: row;
    align-items: end;

    padding-left: 0;
    margin: 1.25rem 0 0 1.25rem;
  }
`;
export const LiSocialMedia = styled.li`
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  a {
      font-size: 0;
    line-height: 0;
  }
  span {
    font-size: 1rem;
   line-height: normal;
  }
`;
export const TitleSocialMedia = styled.li`
  margin-bottom: 0.938rem;

  ${ media.down('md') } {
    font-size: 0.875rem;
 
    margin-right: 5rem;
  }
`;

export const LinkSocialMedia = styled.a`
  color: ${ props => props.theme.colors.blueButtonBackground };
  letter-spacing: -0.2px;
  text-decoration: none;

`;
export const LinkedinLogoIcon = styled.svg`
  background-image: url(${ LinkedinLogo });
  background-repeat: no-repeat;
  margin-right: 0.625rem;
  background-size: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  object-fit: contain;

  ${ media.down('md') } {
    margin-right: 1.875rem;
    background-size: 1.313rem;
    width: 1.313rem;
    height: 1.313rem;
  }
`;
export const FacebookLogoIcon = styled(LinkedinLogoIcon)`
  background-image: url(${ FacebookLogo });
  background-size: 0.563rem;
    margin-left: 0.188rem;
    width: 0.687rem;
  ${ media.down('md') } {
    background-size: 0.813rem;
    width: 0.813rem;
    height: 1.25rem;
  }
`;
export const TwitterLogoIcon = styled(LinkedinLogoIcon)`
  background-image: url(${ TwitterLogo });

  height: 0.75rem;

  ${ media.down('md') } {
    background-size: 1.313rem;
    width: 1.313rem;
    height: 1.125rem;
  }
`;
export const ShortContent = styled.p`
  margin-bottom: 1.875rem;
`;