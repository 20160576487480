
export const slides = [
  {
    id: 1,

    title: 'Rewolucyjne zmiany',
    text:
      'Philip Morris International tworzy nową, rewolucyjną wizję świata - świata wolnego od dymu tytoniowego. Dzięki nauce, nowym technologiom, a także dzięki Twoim pomysłom i doświadczeniu wizja ta staje się nam coraz bliższa. Nadszedł czas na nowe, lepsze zmiany!',
  },
  {
    id: 2,

    title: 'Rozwój bez ograniczeń',
    text:
      'Codziennie podejmujemy nowe wyzwania i sprawdzamy się w różnych zadaniach.\nSzukając swoich najmocniejszych stron, mamy odwagę, by tworzyć rozwiązania przyszłości.',
  },
  {
    id: 3,

    title: 'Tworzymy przyszłość',
    text:
      'Tworzymy miejsce pracy, które wspiera Twoją kreatywność i ciekawość. \n' +
      'Co my z tego mamy?\n' +
      'Twój wkład w kształtowanie naszej wizji przyszłości.\n',
  },
  {
    id: 4,

    title: 'W zespole siła',
    text:
      'Praca zespołowa to podstawa. Bycie częścią zespołu kształtuje nas samych. W zespole odnajdujemy inspirację, z grupy czerpiemy siłę. Towarzystwo współpracowników motywuje do działania, często daje znaczące i korzystne rezultaty. W grupie siła!',
  },
  {
    id: 5,

    title: 'Solidne fundamenty',
    text:
      'Fundament to podstawa! Philip Morris International to firma o globalnych standardach. Codziennie stajemy przed ogromem wyzwań i podejmujemy mnóstwo decyzji. Mamy już jednak zbudowane silne podstawy, na których Ty również możesz polegać – i tworzyć z nami nowe, lepsze jutro.',
  },
  {
    id: 6,

    title: 'Siła różnorodności',
    text:
      'DOWÓD?\nPhilip Morris International w Polsce został uhonorowany Certyfikatem Równych Płac, potwierdzającym, że firma wynagradza kobiety i mężczyzn jednakowo, kierując się doświadczeniem i kompetencjami.\n',
  },
];
