import styled from 'styled-components';
import { media } from 'core/theme/media';

export const Header = styled.h2`
  padding-top: 9.563rem;
  padding-bottom: 3.687rem;
  letter-spacing: -0.46px;

  ${media.down('md')} {
    padding-top: 3.75rem;
    padding-bottom: 0;
  }
`;
