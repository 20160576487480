import smartWorkBanner from "../../../../assets/images/how-we-work/smart-work-banner.jpg";
import HappyParentBanner from "../../../../assets/images/how-we-work/happy-parent-banner.jpg";
import equalSalaryBanner from "../../../../assets/images/how-we-work/equal-salary-banner.jpg";
import {IEmploteeProgram} from "../interfaces";


export const EmployeeProgramsList: IEmploteeProgram [] = [
    {
        id: 'smartWork',
        image: smartWorkBanner,
    },
    {
        id:'happyParents',
        image:HappyParentBanner,
    },
    {
        id:'equalSalary',
        image:equalSalaryBanner
    }
]