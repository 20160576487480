import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { languageSelect } from 'features/language/';
import Container from 'view/components/content/container';
import logo from 'assets/images/404/logo.svg'
import image from 'assets/images/404/photo.jpg';
import imageMobile from 'assets/images/404/photoMobile.jpg';
import { Row, Col } from 'styled-bootstrap-grid';
import {
  Main,
  Logo,
  Title,
  Description,
  Button,
  Icon,
  Image,
  ImageMobile,
} from './styles';

export const NotFound: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);

  return <Main>
    <Container>
      <Row>
        <Col lg={6} xs={12}><Logo src={logo} />
          <Title>Błąd 404</Title>
          <Description>
            Tu niczego nie ma. Null. Klops. Zero. Nada.
          </Description>
          <Button to={`${lang === 'en' ? '/en' : ''}/`}><span>STRONA GŁÓWNA</span><Icon /></Button>
        </Col>
        <Col lg={6} hiddenMdDown>
          <Image src={image} />
        </Col>
      </Row>
    </Container>
    <ImageMobile src={imageMobile} />
  </Main>;

};