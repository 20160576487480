import stage1 from 'assets/images/smart-work/two-arrows.svg';
import stage2 from 'assets/images/smart-work/stay-home.svg';
import stage3 from 'assets/images/smart-work/time-clock.svg';
import stage4 from 'assets/images/smart-work/spa-rocks.svg';

import { IRecruitmentStage } from 'view/pages/recruitment/interfaces';
export const SmartWorkStage: IRecruitmentStage[] = [
  {
    key: 'stage1',
    image: stage1,
  },
  {
    key: 'stage2',
    image: stage2,
  },
  {
    key: 'stage3',
    image: stage3,
    infoModal:true,
  },
  {
    key: 'stage4',
    image: stage4,
  },
];
