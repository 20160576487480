import React, {FunctionComponent} from "react";
import {Col} from "styled-bootstrap-grid";
import {useMultilingualText} from "../../../../features/hooks";
import {IEmploteeProgramProps} from "../interfaces";
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import { RowWorkProgram, WrapperWorkProgram, WorkProgramImgMobile, DetailsLinkWorkProgram, WorkProgramImg } from "./styles";
import { SentenceHowWeWork } from "../HowWeWorkDescription/styles";
import GTM from 'features/services';
import {ArrowDown} from "../../whereWorking/styles";
import {DetailsLink} from "../../whereWorking/components/styles";

export const EmployeeProgram: FunctionComponent<IEmploteeProgramProps> = ({id, image}) => {
    const EmployeeProgramTitle = useMultilingualText(`employeeProgram.${id}_title`, 'howWeWork');
    const EmployeeProgramDescriptionFirst = useMultilingualText(`employeeProgram.${id}_descriptionFirst`, 'howWeWork');
    const EmployeeProgramSecondTitle = useMultilingualText(`employeeProgram.${id}_secondTitle`, 'howWeWork');
    const EmployeeProgramDescriptionSecond = useMultilingualText(`employeeProgram.${id}_descriptionSecond`, 'howWeWork');
    const EmployeeProgramLink = useMultilingualText(`employeeProgram.${id}_link`, 'howWeWork');

    return <>

        <RowWorkProgram>
            <Col lg={6} md={12} sm={12}>
                <WrapperWorkProgram>
                    <SentenceHowWeWork>{EmployeeProgramTitle}</SentenceHowWeWork>
                    <LazyloadBackgroundImage image={image}>
                    <WorkProgramImgMobile src={image}/>
                    </LazyloadBackgroundImage>
                    <p>{EmployeeProgramDescriptionFirst}</p>
                    <strong>{EmployeeProgramSecondTitle}</strong>
                    <p>{EmployeeProgramDescriptionSecond}</p>
                    <DetailsLinkWorkProgram  onMouseUp={() => GTM('howWeWorkLinkTo', `Sprawdź`, `${window.location.host}/working-places/${EmployeeProgramLink}`)}  to={`${EmployeeProgramLink}`}> Sprawdź</DetailsLinkWorkProgram>
                </WrapperWorkProgram>

            </Col>
            <Col lg={6} md={12} sm={12}>
                <LazyloadBackgroundImage image={image}>
                <WorkProgramImg src={image}/>
                </LazyloadBackgroundImage>
            </Col>
        </RowWorkProgram>


    </>
}