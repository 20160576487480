import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { media } from '../../../../core/theme';

export const RowInclusionHappy = styled(Row)`
  margin-top: 60px;
  white-space: pre-wrap;
  h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    max-width: 315px;
  }

  ${ media.up('md') } {
    margin-top: 150px;

    h2 {
      max-width: 100%;
    }
  }
`
