import React, {  FunctionComponent } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { Helmet } from 'react-helmet';

import Container from 'view/components/content/container';
import {ContentPrivacy, CustomTable, HeaderPrivacy} from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import {useSelector} from "react-redux";
import { languageSelect } from 'features/language/';

export const PrivacyMailPage: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);

  return <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Container>
      <Row>
        <Col lg={8} lgOffset={2}>
          <HeaderPrivacy>
            <MultilingualText tKey="headerPrivacy" area="privacyMail" />
          </HeaderPrivacy>
          <ContentPrivacy>
            <p>
              <MultilingualText tKey="subHeader" area="privacyMail" />
            </p>
            <ul>
              <li>
                <MultilingualText tKey="ul1li1" area="privacyMail" />
                <br />
              </li>
              <li>
                <MultilingualText tKey="ul1li2header" area="privacyMail" />
                <ol>
                  <li>
                    <MultilingualText tKey="olli1" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli2" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli3" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli4" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli5" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli6" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="olli7" area="privacyMail" />
                  </li>
                </ol>
              </li>
              <li>
                <MultilingualText tKey="ul1li4" area="privacyMail" />
              </li>
            </ul>
            <Row>
              <Col>
                <CustomTable>
                  <tbody>
                  <tr>
                    <td>
                      &nbsp;
                      <strong>
                        <strong>
                          <MultilingualText tKey="t1td1" area="privacyMail" />
                        </strong>
                      </strong>
                    </td>
                    <td>
                      &nbsp;
                      <strong>
                        <strong>
                          <MultilingualText tKey="t1td2" area="privacyMail" />
                        </strong>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <em></em>
                      </strong>
                      <p>
                        <strong>
                          <em>
                            <MultilingualText tKey="t1p7" area="privacyMail" />
                          </em>
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <MultilingualText tKey="t1li5" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li6" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li7" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li8" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li9" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li10" area="privacyMail" />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <strong>
                            <em> </em>
                          </strong>
                          <em>
                            <MultilingualText tKey="t1li11" area="privacyMail" />
                          </em>
                        </li>
                        <li>
                          <MultilingualText tKey="t1li12" area="privacyMail" />
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p>
                        <MultilingualText tKey="t1p8" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t1p9" area="privacyMail" />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>
                          <em></em>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          <em>
                            <MultilingualText tKey="t1p10" area="privacyMail" />
                          </em>
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <MultilingualText tKey="t1li13" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li14" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li15" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li16" area="privacyMail" />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <strong>
                            <em> </em>
                          </strong>
                          <em>
                            <MultilingualText tKey="t1li17" area="privacyMail" />
                          </em>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p>
                        <MultilingualText tKey="t1p11" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t1p12" area="privacyMail" />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <em></em>
                      </strong>
                      <p>
                        <strong>
                          <em>
                            <MultilingualText tKey="t1p13" area="privacyMail" />
                          </em>
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <strong>
                            <em> </em>
                          </strong>
                          <em>
                            <MultilingualText tKey="t1li18" area="privacyMail" />
                          </em>
                        </li>
                      </ul>
                      <p></p>
                    </td>
                    <td>
                      <p>
                        <MultilingualText tKey="t1p14" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t1p15" area="privacyMail" />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <em></em>
                      </strong>
                      <p>
                        <strong>
                          <em>
                            <MultilingualText tKey="t1p16" area="privacyMail" />
                          </em>
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <MultilingualText tKey="t1li19" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li20" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li21" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li22" area="privacyMail" />
                        </li>
                        <li>
                          <MultilingualText tKey="t1li23" area="privacyMail" />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <strong>
                            <em> </em>
                          </strong>
                          <em>
                            <MultilingualText tKey="t1li24" area="privacyMail" />
                          </em>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p>
                        <MultilingualText tKey="t1p17" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t1p18" area="privacyMail" />
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </CustomTable>
              </Col>
            </Row>
            <br />
            <ul>
              <li>
                <MultilingualText tKey="ul2li1" area="privacyMail" />
                <ol>
                  <li>
                    <MultilingualText tKey="ol2li1" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li2" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li3" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li4" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li5" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li6" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li7" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li8" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li9" area="privacyMail" />
                  </li>
                </ol>
              </li>
              <li>
                <MultilingualText tKey="ul2li2" area="privacyMail" />
              </li>
              <li>
                <MultilingualText tKey="ul2li3" area="privacyMail" />
                <ol>
                  <li>
                    <MultilingualText tKey="ol2li10" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li11" area="privacyMail" />
                  </li>
                  <li>
                    <MultilingualText tKey="ol2li12" area="privacyMail" />
                  </li>
                </ol>
              </li>
              <li>
                <MultilingualText tKey="ul2li4" area="privacyMail" />
              </li>
            </ul>
            <Row>
              <Col>
                <CustomTable>
                  <tbody>
                  <tr>
                    <td>
                      &nbsp;
                      <strong>
                        <strong>
                          <MultilingualText tKey="t2td1" area="privacyMail" />
                        </strong>
                      </strong>
                    </td>
                    <td>
                      &nbsp;
                      <strong>
                        <strong>
                          <MultilingualText tKey="t2td2" area="privacyMail" />
                        </strong>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <MultilingualText tKey="t2p1" area="privacyMail" />
                      </p>
                    </td>
                    <td>
                      <p>
                        <MultilingualText tKey="t2p2" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t2p3" area="privacyMail" />
                      </p>
                      <p>
                        <MultilingualText tKey="t2p4" area="privacyMail" />
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </CustomTable>
              </Col>
            </Row>
            <br />
            <ul>
              <li>
                <MultilingualText tKey="ul1li3" area="privacyMail" /> <MultilingualText tKey="text1_1" area="privacyMail" />{' '}
                <a href="tel:+48223433865">+48223433865.</a>{' '}
              </li>
              <li>
                <MultilingualText tKey="ul2li5" area="privacyMail" />
              </li>
              <li>
                <MultilingualText tKey="ul2li6" area="privacyMail" />
              </li>
            </ul>
            <p>
              <MultilingualText tKey="text3" area="privacyMail" />
              {lang === 'en' ?
                  <a href="https://www.pmiprivacy.com/poland/en/candidate/">https://www.pmiprivacy.com/poland/en/candidate/</a>
                  :
                  <a href="https://www.pmiprivacy.com/poland/pl/candidate/">https://www.pmiprivacy.com/poland/pl/candidate/</a>}.
            </p>
          </ContentPrivacy>
        </Col>
      </Row>
    </Container>
  </>;

};