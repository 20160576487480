import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';
import Container from 'view/components/content/container';
import { ContainerVariants } from 'core/theme';
import iconHover from 'assets/images/icons/arrow-right-white-hover.svg';
import icon from 'assets/images/icons/arrow-right-white.svg';

const arrowIcon = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background-size: 100% 100%;
  background-image: url(${icon});
`;

export const LargeContainer = styled(Container)`
    ${media.down('md')} {
      padding: 0;
      margin: 0;
    }
  `,
  Main = styled.div<{ backgroundImage: string }>`
    overflow-x: hidden;
    border: 1px solid ${(props) => props.theme.colors.lines};
    width: 100%;
    height: auto;
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    ${media.down('md')} {
      background-image: url(${(props) => props.backgroundImage});
      background-position: right;
      background-size: cover;
      padding: 3.75rem 0;
      width: 100vw;
    }
  `,
  Content = styled.div``,
  Header = styled.h2`
    line-height: normal;
    letter-spacing: -0.46px;
    padding-bottom: 1.875rem;
    ${media.down('md')} {
      padding-bottom: 0.625rem;
    }
  `,
  Paragraph = styled.p`
    line-height: 1.56;
    width: 28rem;
    padding-bottom: 3.281rem;

    ${media.down('md')} {
      width: 53%;
      font-size: 0.85rem;
      padding-bottom: 1.5625rem;
    }
  `,
  ButtonBox = styled.div`
    padding: 0.469rem 0;
    ${media.down('md')} {
      padding: 0.3125rem 0;
    }
  `,
  Button = styled.button`
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    color: ${(props) => props.theme.colors.lightText};
    transition: all 0.3s;
    cursor: pointer;
    outline: none;
    border: none;
    width: 20.313rem;
    height: 3.75rem;
    padding: 0 1.563rem 0 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-transform: uppercase;

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
    :hover ${arrowIcon} {
      background-image: url(${iconHover});
    }

    ${media.down('md')} {
      padding: 0 1.375rem;
      width: 15.188rem;
      height: 3.125rem;
      font-size: 1.188rem;
      font-weight: 600;
      letter-spacing: -0.24px;
    }
  `,
  ArrowIcon = arrowIcon,
  ImageCol = styled(Col)`
    padding: 0;
    font-size: 0;
  `,
  ImageComp = styled.img`
    width: calc(
      100% +
        calc(calc(100vw - ${ContainerVariants.default.maxWidth.xl}px - calc(100vw - ${ContainerVariants.large.maxWidth.xl}px)) * 0.5 - 15px)
    );
    height: auto;
  `,
  StyledLink = styled.a`
    color: #0074c2;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      border: 0;
    }
  `;
