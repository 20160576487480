import React, {FunctionComponent} from 'react';
import Container from 'view/components/content/container';
import { Row, Col } from 'styled-bootstrap-grid';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

import { RowInclusionHappy } from './styles';



export const InclusionHappyParents:FunctionComponent = () => {

  return<>
    <Container>
      <RowInclusionHappy>
        <Col lg={ 7 } md={ 10 } sm={ 12 } noGutter={ true }>
          <h2><MultilingualText tKey="inclusion-happy-title" area="happy_parents" /></h2>
          <p><MultilingualText tKey="inclusion-happy-description" area="happy_parents" /></p>
        </Col>
      </RowInclusionHappy>
    </Container>
  </>
}
