import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';
import arrowRight from 'assets/images/icons/arrow-right-black.svg';
import arrowRightHover from 'assets/images/icons/arrow-right-blue-hover.svg';

export const MainRow = styled(Row)`
    ${media.down('md')} {
      margin-left: 0;
      width: 100%;
    }
  `,
  Main = styled.div<{ line?: boolean; down?: boolean }>`
    width: 100%;
    padding-block: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.down('md')} {
      height: 3.75rem;
      padding-block: unset;
    }

    ${(props) =>
      props.line &&
      `
  border-bottom: 1px solid ${props.theme.colors.lines};
`}
    ${(props) =>
      props.down &&
      `
  ${media.down('md')}{
    height: 6.25rem;
  }
`}
  `,
  OffersNumberBox = styled.div<{ displayProp?: boolean }>`
    width: 12rem;
    ${media.down('md')} {
      width: 100%;
    }
    ${(props) =>
      props.displayProp &&
      `
  ${media.down('md')}{
    display: none;
    width: 0;
  }
  `}
  `,
  OffersNumber = styled.div<{ children; displayProp?: boolean }>`
    color: ${(props) => props.theme.colors.lightBlue};
    display: none;

    ${(props) =>
      props.displayProp &&
      ` 
  display: block;
`}
  `,
  Pages = styled.div`
    display: flex;
    align-items: center;

    ${media.down('md')} {
      width: 100%;
      justify-content: space-between;
    }
  `,
  ArrowIcon = styled.div<{ left?: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    background-image: url(${arrowRight});
    transition-duration: 0.1s;
    background-size: cover;
    object-fit: contain;

    ${(props) =>
      props.left &&
      `
    transform: rotate(180deg);
  `}

    &:hover {
      background-image: url(${arrowRightHover});
    }
  `,
  Numbers = styled.div`
    display: flex;
    margin: 0 0.625rem;

    ${media.down('sm')} {
      margin: 0 0.3rem;
    }
  `,
  Number = styled.a<{ active?: boolean }>`
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
      background-color: #eaeef4;
    }
    ${(props) =>
      props.active &&
      `
  background-color: #eaeef4;
  color: #8f9197;
`}
  `,
  DropdownBox = styled.div`
    display: flex;
    align-items: center;
  `,
  SelectBox = styled.div`
    margin-left: 1.25rem;
  `;
