import * as React from 'react';
import { FunctionComponent } from 'react';

import { StyledMain } from './styles';

export const Main: FunctionComponent = ({ children }) => {
  return <StyledMain>
    {children}
  </StyledMain>;
};
