import { media } from 'core/theme';
import * as React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { BannerWrapper, TitleDepartment } from '../styles';
import { ContactInfo } from './contact-info';
import { PopUp } from './pop-up';

export const BannerLocation = (props) => {

  const ColorLine = styled.hr`
  border: none;
  background: ${props.color};
  width: 0.313rem;
  height: 5.625rem;
  margin: 0;

  ${media.down('md')} {
    margin-right: 0;
    height: 3.75rem;
  }
`;

  return <>
    <Row>
      <Col>
        <BannerWrapper>
          <TitleDepartment>{props.department}</TitleDepartment>
          <Row>
            <Col lg={.5} md={.5} xs={1}>
              <ColorLine />
            </Col>
            <Col lg={1.1} xs={2} md={1} xsOrder={2}>
              <PopUp {...props} />
            </Col>
            <Col lg={10.4} md={10.5} xs={8} xsOrder={1}>
              <ContactInfo {...props} />
            </Col>
          </Row>
        </BannerWrapper>
      </Col>
    </Row>
  </>;
};
