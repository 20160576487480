import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import GTM from 'features/services';
import { IMenuItemProps } from './interfaces';
import { StyledMenuItem } from './styles';

export const MenuItem: FunctionComponent<IMenuItemProps> = ({ path, children, itemText, onClickFunc }) => {
  const history = useHistory();
  const onLinkClick = path ? () => history.push(path) : onClickFunc;
  return <StyledMenuItem onClick={onLinkClick} onMouseUp={() => GTM('menuItemFooter', itemText, `${window.location.host}${path}`)}>
    {children}
  </StyledMenuItem>;
};