import React, { FunctionComponent } from 'react';
import { Row } from 'styled-bootstrap-grid'

import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

import { Benefit } from '../Benefit/Benefit';
import { BenefitsList } from './benefitsList';
import { Main, Header } from './styles';

export const Benefits: FunctionComponent = () => {
  return <Main>
    <Container>
      <Header><MultilingualText tKey="benefits.header" area="teams" /></Header>
      <Row>
        {BenefitsList.map((benefit) =>
          <Benefit key={benefit.id} image={benefit.image} id={benefit.id} />
        )}
      </Row>
    </Container>
  </Main>;
};
