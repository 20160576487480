import React, { FunctionComponent } from 'react';
import { JobDescriptionParagraph, Header } from './styles';

interface JobOfferDetailsPageParagraphProps {
    text: string,
    lineNo: number,
}

const headers = [
    "Make history with us!", "Twórz z nami historię!",
    "Who are we looking for?", "Kogo szukamy?",
    "What we offer you?", "Co Ci oferujemy?",
    "How can you make history with us?", "Czym będziesz się zajmować?",
    "Please note that only on-line applications will be taken into consideration.", "Należy pamiętać, że brane będą pod uwagę wyłącznie zgłoszenia on-line."
];

const verify = (subString, text) => (text.search(subString) !== -1);

export const JobOfferDetailsPageParagraph: FunctionComponent<JobOfferDetailsPageParagraphProps> = ({ lineNo, text }) => {
    const shouldBeAHeader = headers.some(x => verify(x.replace(/\s+/g, " ").toLowerCase(), text.replace(/\s+/g, " ").toLowerCase()));
    const textToDisplay = text.split('\t').map(element => ` ${element}`);
    return shouldBeAHeader ?
        <Header isNotFirst={lineNo !== 0}> {textToDisplay}</Header > :
        <JobDescriptionParagraph>{textToDisplay}</JobDescriptionParagraph>
}
