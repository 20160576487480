import styled from 'styled-components';
import { media } from 'core/theme/media';

import { IContentContainer } from 'view/pages/recruitment/interfaces';
export const ContentContainer = styled.div<IContentContainer>`
    border: 1px solid ${(props) => props.theme.colors.lines};
    width: 29.375rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.875rem 2.5rem 2.563rem 2.5rem;
    margin-left: 0.9375rem;
    margin-right: 0rem;

    ${(props) =>
      props.isLastVisible &&
      `
      opacity: 0.3;
    `}

    ${media.down('md')} {
      width: 15.375rem;
      height: 26rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
    ${media.down('sm')} {
      margin: 0 0rem 0 2rem;
    }
  `,
  SmallHeader = styled.div`
    ${media.down('md')} {
      font-size: 0.75rem;
      padding-bottom: 0.313rem;
    }
  `,
  Header = styled.h3`
    font-weight: 400;
    padding: 0.625rem 0;
    width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    ${media.up('md')} {
      height: 4.8rem;
      max-height: 4.8rem;
      line-height: 2.2rem;
    }
    ${media.down('md')} {
      font-size: 0.875rem;
      padding: 0;
      height: 3.4rem;
      max-height: 3.4rem;
      line-height: 1.4rem;
      margin-block: 0px;
    }
  `,
  HeaderName = styled.h3`
    font-weight: 400;
    padding: 0.625rem 0;
    width: 100%;

    ${media.down('md')} {
      font-size: 0.875rem;
      padding: 0;
      max-height: 4.6rem;
    }
  `,
  Position = styled.div`
    height: 3rem;
    ${media.down('md')} {
      width: 10.563rem;
      font-size: 0.75rem;
      padding-top: 0.5rem;
    }
  `,
  ImageBox = styled.div`
    width: 100%;
    height: 24.375rem;
    margin: 1.375rem 0 1.25rem 0;

    ${media.down('md')} {
      width: 100%;
      height: 11.875rem;
      margin: 0.563rem 0 0.938rem 0;
    }
  `,
  MainImage = styled.div<{ backgroundImage: string }>`
    background-image: url('${(props) => props.backgroundImage}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
  `,
  Linkedin = styled.img`
    width: 6.938rem;
    height: auto;
    cursor: pointer;
    margin-top: 1.25rem;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }

    ${media.down('md')} {
      width: 3.5rem;
    }
  `;
