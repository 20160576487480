import homeEN from 'translations/en/home.json';
import aboutUsEN from 'translations/en/about-us.json';
import recruitmentEN from 'translations/en/recruitment.json';
import whereWorkEN from 'translations/en/where-work.json';
import privacyEN from 'translations/en/privacy.json';
import teamsEN from 'translations/en/teams.json';
import jobOffersEN from 'translations/en/job-offers.json';
import krakowIt from 'translations/en/locations/krakowIt.json';
import krakowFactory from 'translations/en/locations/krakowFactory.json';
import krakowCUW from 'translations/en/locations/krakowCUW.json';
import warsaw from 'translations/en/locations/warsaw.json';
import other from 'translations/en/locations/other.json';
import common from 'translations/en/common.json';
import contactEN from 'translations/en/contact.json';
import happyParentsEN from 'translations/en/happy-parents.json';
import equalSalaryEN from  'translations/en/equal-salary.json';
import missionIsPossibleEN from  'translations/en/mission-is-possible.json';
import howWeWorkEN from  'translations/en/how-we-work.json';
import smartWorkEN from 'translations/en/smart-work.json';
import privacyMailEN from 'translations/en/privacyMail.json';

export default {
  home: homeEN,
  privacy: privacyEN,
  aboutUs: aboutUsEN,
  recruitment: recruitmentEN,
  whereWork: whereWorkEN,
  teams: teamsEN,
  jobOffers: jobOffersEN,
  locations_krakowIt: krakowIt,
  locations_krakowFactory: krakowFactory,
  locations_krakowCUW: krakowCUW,
  locations_warsaw: warsaw,
  locations_other: other,
  contact:contactEN,
  happy_parents:happyParentsEN,
  equal_salary:equalSalaryEN,
  mission_is_possible:missionIsPossibleEN,
  howWeWork:howWeWorkEN,
  smartWork:smartWorkEN,
  privacyMail:privacyMailEN,
  common,
};
