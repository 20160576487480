import { ILocation } from './interface';
import communityBanner from 'assets/images/locations/community-service-banner.jpg';
import communityBannerPhone from 'assets/images/locations/banner-phone-CommunityServiceKracow.jpg';
import placeholderCommunity from 'assets/images/locations/placeholderCommunityKracow.jpg';
import img1 from 'assets/images/locations/gallery/community-service-krakow/img1.jpg';
import img2 from 'assets/images/locations/gallery/community-service-krakow/img2.jpg';
import img3 from 'assets/images/locations/gallery/community-service-krakow/img3.jpg';
import img4 from 'assets/images/locations/gallery/community-service-krakow/img4.jpg';
import img5 from 'assets/images/locations/gallery/community-service-krakow/img5.jpg';
import img6 from 'assets/images/locations/gallery/community-service-krakow/img6.jpg';
import img7 from 'assets/images/locations/gallery/community-service-krakow/img7.jpg';
import img8 from 'assets/images/locations/gallery/community-service-krakow/img8.jpg';
import { Theme } from 'core/theme';

export const CommunityServiceKracow: ILocation = {
  key: 'krakowCUW',
  addressLocation: {
    color: `${Theme.colors.location.blue}`,
    iframe:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.8152816656207!2d20.018746315885824!3d50.07102092251223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471645224d194871%3A0x85569064e45f65ed!2sPhilip%20Morris%20Polska%20S.A.!5e0!3m2!1spl!2spl!4v1600948285720!5m2!1spl!2spl',
    phoneNumber: '123 456 789',
  },
  bannerImageDesktop: communityBanner,
  bannerImagePhone: communityBannerPhone,
  gallery: {
    firstImg: img1,
    secondImg: img2,
    allImgGallery: [img1, img2, img4, img3, img5, img6, img7, img8],
  },
  name: 'shared-services-center-krakow',
  slug: 'krakowCUW',
  teamsWorking: ['procurement', 'finances', 'people-culture', 'legal-ethics-compliance', 'administration', 'production'],
  workLooks: {
    image: placeholderCommunity,
  },
};
