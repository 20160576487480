import { IFeature } from './interfaces';
import aboutUsFeature1 from 'assets/images/about-us/about-us-feature-1.jpg';
import aboutUsFeature2 from 'assets/images/about-us/about-us-feature-2.jpg';
import aboutUsFeature3 from 'assets/images/about-us/about-us-feature-3.jpg';
import aboutUsFeature4 from 'assets/images/about-us/about-us-feature-4.jpg';
import aboutUsFeature5 from 'assets/images/about-us/about-us-feature-5.jpg';

export const FeaturesList: IFeature[] = [
  {
    key: 'krakow-factory-1',
    tKey: 'factoryKrakow1',
    decorationColor: 'yellow',
    photo: aboutUsFeature1,
    youtubeVideoId: '3kbcctw0K-4',
  },
  {
    key: 'warsaw-distribution',
    tKey: 'officeWarsaw',
    decorationColor: 'turquoise',
    photo: aboutUsFeature2,
    youtubeVideoId: 'k2wVuujdqZs',
  },
  {
    key: 'general-distribution',
    tKey: 'salesPoland',
    decorationColor: 'turquoise',
    photo: aboutUsFeature3,
    youtubeVideoId: 'T5yfhL3KINk',
  },
  {
    key: 'krakow-factory-2',
    tKey: 'factoryKrakow2',
    decorationColor: 'blue',
    photo: aboutUsFeature4,
    youtubeVideoId: 'lqvvjq07lxc',
  },
  {
    key: 'krakow-it',
    tKey: 'ITKrakow',
    decorationColor: 'blue',
    photo: aboutUsFeature5,
    youtubeVideoId: 'SjkssmAeBTk',
  },
];
