import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { languageSelect } from 'features/language/'

export const useOffers = (queryParams) => {

    const { lang } = useSelector(languageSelect);
    const [offers, setOffers] = useState<any>([]);
    const [pageStats, setPageStats] = useState<any>([]);

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH } = process.env;
        const source = axios.CancelToken.source();
        axios.get(`${REACT_APP_API_FULL_PATH}/job-offers?${queryParams}`, { cancelToken: source.token }).then(response => {
            setOffers(response.data.data);
            setPageStats({
                from: response.data.from,
                to: response.data.to,
                total: response.data.total
            });
        });
        return () => source.cancel('Operation cancelled');
    }, [queryParams, lang]);

    return { offers, pageStats };
};
