export const contactPageData = {
    contactPageLoaded :{
        event: 'virtualPageView',
        virtualPageView: '/contact',
    },
    contactPageLinkTo: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    contactPageCall: {
        event: 'backendMicroconversion',
        category: 'Clicks - call',
    },
}