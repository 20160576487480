import React from 'react';
import { Row } from 'styled-bootstrap-grid';
import Modal from 'react-modal';

import Container from 'view/components/content/container';
import { useBreakpoints } from 'features/hooks'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { slides as data } from './data';
import {
  Close,
  Title,
  Text,
  Buttons,
  ArrowNext,
  ArrowPrev,
  Counter,
  WrapperModalText,

} from './styles';

const customStyles = {
  content: {
    height: '100%',
    width: '100vw',
    left: 0,
    top: 0,
    borderRadius: 0,
    backgroundColor: '#191919',
    border: 0,
    padding: 0,
  },
  overlay: { zIndex: 1000 }
};

export const ModalFull = (props) => {
  const { isSmall } = useBreakpoints();
  return <React.Fragment>
    {isSmall && <Modal
      isOpen={props.activeModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={() => props.setActiveModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {isSmall &&
        <React.Fragment>
          <Container>
            <Row>
              <WrapperModalText xs={12}>
                <Title><MultilingualText tKey={`cultureTitle${props.activeSlide}`} area='home' /></Title>
                <Text><MultilingualText tKey={`cultureContent${props.activeSlide}Show`} area='home' /></Text>

              </WrapperModalText>
            </Row>
          </Container>
          <Buttons>
            <ArrowPrev onClick={() => {
              if (props.activeSlide > 1) props.setActiveSlide(props.activeSlide - 1);
            }} />
            <Counter>{props.activeSlide}/{data.length}</Counter>
            <ArrowNext onClick={() => {
              if (props.activeSlide < data.length) props.setActiveSlide(props.activeSlide + 1);
            }} />
          </Buttons>
          <Close onClick={() => props.setActiveModal(false)}></Close>
        </React.Fragment>
      }
    </Modal>}
  </React.Fragment>
};