import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import Container from 'view/components/content/container';
import { media } from 'core/theme/media';
import arrowRight from 'assets/images/icons/arrow-right-black.svg';
import arrowRightHover from 'assets/images/icons/arrow-right-blue-hover.svg';

export const Icon = styled.div`
  background-image: url(${arrowRight});
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100% 100%;
  transition-duration: 0.3s;

  ${media.down('md')} {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const StyledContainer = styled(Container)`
    padding-top: 9.375rem;
    padding-bottom: 9.25rem;

    ${media.down('md')} {
      padding-top: 3.688rem;
      padding-bottom: 3.688rem;
    }
  `,
  Header = styled.h2`
    line-height: normal;
    letter-spacing: -0.46px;
    padding-bottom: 3.188rem;

    ${media.down('md')} {
      padding-bottom: 2.375rem;
      width: 14rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  `,
  OffersCol = styled(Col)`
    padding: 0;
  `,
  Empty = styled.div`
    background-color: red; // ${(props) => props.theme.colors.lightBlue};
    height: 30.625rem;

    ${media.down('md')} {
      height: 46.875rem;
    }
  `,
  SeeMore = styled(Link)`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-top: 3rem;
    margin-left: 3.8rem;
    color: ${(props) => props.theme.colors.darkText};
    text-decoration: none;
    &:hover {
      ${Icon} {
        background-image: url(${arrowRightHover});
      }
    }
    ${media.down('md')} {
      padding-top: 1.813rem;
      margin-left: 0;
    }
  `,
  ActionText = styled.h3`
    font-weight: normal;
    line-height: normal;
    padding-right: 0.625rem;
  `;
