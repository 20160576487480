import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col } from 'styled-bootstrap-grid';

import { media } from 'core/theme';

export const StyledFooter = styled.footer`
    background: #000;
    color: #fff;
    overflow: hidden;
  `,
  FooterTop = styled.div`
    padding: 1.875rem 0;

    ${media.up('sm')} {
      padding: 6.375rem 0 4.938rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    ${Col}.top-col {
      &:last-child {
        padding-bottom: 1.875rem;
        margin-bottom: 1.813rem;
        position: relative;
        &:after {
          position: absolute;
          left: -15px;
          right: -15px;
          bottom: 0;
          content: '';
          display: block;
          background: rgba(255, 255, 255, 0.2);
          height: 1px;
        }

        ${media.up('sm')} {
          padding-bottom: 0;
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  `,
  StyledLogo = styled.div`
    line-height: 0;
    max-width: 7.875rem;

    svg {
      max-width: 100%;
      height: auto;
    }

    ${media.up('sm')} {
      max-width: none;
    }
  `,
  FooterBottomLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    margin-right: 1.25rem;
    &:last-child {
      margin-right: 0;
    }
  `,
    FooterBottomButton = styled.button`
    color: inherit;
    text-decoration: none;
    margin-right: 1.25rem;
    background: transparent;
    opacity: 0.5;
    font-size: 0.75rem;
    -webkit-letter-spacing: -0.15px;
    -moz-letter-spacing: -0.15px;
    -ms-letter-spacing: -0.15px;
    letter-spacing: -0.15px;
    border: none;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  `,
  OfferButtonLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    display: block;
  `,
  OffersButton = styled.button`
    height: 4.375rem;
    background: ${(props) => props.theme.colors.blueButtonBackground};
    border: 0;
    color: #fff;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    min-width: 100vw;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    padding: 0 1.875rem;

    ${media.up('sm')} {
      height: 6.875rem;
      font-size: 0.938rem;
      letter-spacing: -0.19px;
      font-weight: 600;
      padding: 0 3.125rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }

    svg {
      margin-right: 1.25rem;
      height: 1.313rem;
      width: auto;

      ${media.up('sm')} {
        height: auto;
      }
    }

    span,
    svg {
      vertical-align: middle;
    }
  `,
  FooterBottom = styled.div`
    overflow: hidden;
    padding-bottom: 2.313rem;

    ${media.up('sm')} {
      padding-bottom: 0;
    }

    ${FooterBottomLink} > span, ${Col} > span {
      opacity: 0.5;
      font-size: 0.75rem;
      letter-spacing: -0.15px;
    }

    ${Col} {
      margin-bottom: 1.875rem;
      &:first-child {
        margin-bottom: 0;
      }
      ${media.up('sm')} {
        margin-bottom: 0;
      }
    }
  `,
  AllOffers = styled.span`
    font-family: Poppins;
  `;
