import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { useMultilingualText } from 'features/hooks';

import arrowDown from 'assets/images/recruitment/question-arrow.svg';
import { IFAQComponentProps } from 'view/pages/recruitment/interfaces';

import {
  QuestionBox,
  Question,
  Arrow,
  Answer
} from './styles';

export const FAQComponent: FunctionComponent<IFAQComponentProps> = ({ id }) => {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const recruitmentQuestion = useMultilingualText(`faq.faqItems.${id}.question`, 'recruitment');
  const recruitmentAnswer = useMultilingualText(`faq.faqItems.${id}.answer`, 'recruitment');

  const onQuestionClick = () => {
    setIsAnswerVisible(!isAnswerVisible);
    GTM('recruitmentFAQ', `FAQ question: ${recruitmentQuestion}`, isAnswerVisible ? 'collapse' : 'expand');
  }

  return <Row>
    <Col>
      <QuestionBox onMouseUp={onQuestionClick}>
        <Question>{recruitmentQuestion}</Question>
        <Arrow src={arrowDown} up={isAnswerVisible} />
      </QuestionBox>
      {isAnswerVisible &&
        <Answer>{recruitmentAnswer}</Answer>}
    </Col>
  </Row>;
};