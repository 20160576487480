import React, { FunctionComponent, useState, useRef, MutableRefObject } from "react";
import Slider from "react-slick";
import { Col, Row } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { useBreakpoints } from 'features/hooks';
import SlideComponent from './SlideComponent';
import { Main } from './styles';

export const SliderComponent: FunctionComponent<ISliderComponentProps> = ({ slides, borderTop, borderBottom, isQuoteIconVisible }) => {
  const slider: MutableRefObject<any> = useRef<Slider>(null);
  const slidesCount = slides.length;
  const [activeSlide, setActiveSlide] = useState(0);
  const { isSmall } = useBreakpoints();

  const settings = {
    dots: true,
    activeSlide: activeSlide,
    infinite: true,
    speed: 800,
    swipe: isSmall,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "dots",
    customPaging: () => <span />
  };


  const onSwipeHandler = (direction) => {
    if (direction === "left" && activeSlide < slidesCount)
      setActiveSlide(prevState => ++prevState)
    if (direction === "left" && activeSlide + 1 >= slidesCount)
      setActiveSlide(0)
    if (direction === "right" && activeSlide === 0)
      setActiveSlide(slidesCount - 1)
    if (direction === "right" && activeSlide > 0)
      setActiveSlide(prevState => --prevState)

  }

  const onSlideChangeGTM = (activeSlide, previousSlide) => {
    const activeSlideValues = slides[activeSlide - 1];
    GTM('aboutUsSlider', `${activeSlideValues.header}: ${activeSlideValues.quote}`, `${previousSlide} to ${activeSlide}`);
  };
  return (
    <Main borderTop={borderTop} borderBottom={borderBottom}>
      <Row>
        <Col col={12} >
          <Slider {...settings} ref={slider} onSwipe={onSwipeHandler}>
            {slides.map((x, idx) =>
              <div key={x.key}>
                <SlideComponent slide={x} sliderRef={slider} slidesCount={slidesCount} onSlideChangeGTM={onSlideChangeGTM}
                  setActiveSlide={setActiveSlide} activeSlide={activeSlide} isQuoteIconVisible={isQuoteIconVisible} />
              </div>
            )}
          </Slider>
        </Col>
      </Row>
    </Main >
  );
};