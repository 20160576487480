import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import { Col, Row } from 'styled-bootstrap-grid';
import LazyImage from 'view/components/content/LazyImage';
import EqualSalaryBannerSecond from 'assets/images/employee-programs/equal-salary-second-baner.png';
import { DescriptionImage } from 'view/pages/about-us/components/AboutUsTextSection/styles';
import { WrapperDescriptionSalary, ImageBox, ColorLine, WrapperColorLineText } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';


export const MainTextEqualSalary: FunctionComponent = () => {
  return <>
    <Container>
      <Row><Col sm={ 12 } md={ 12 } lg={ 6 }>
        <WrapperDescriptionSalary>
          <h2><MultilingualText tKey="does-mean" area="equal_salary" /></h2>
          <p><MultilingualText tKey="certificate-awarded" area="equal_salary" /></p>
          <WrapperColorLineText>
            <ColorLine />
            <div>
              <h3><MultilingualText tKey="color-line-text" area="equal_salary" /></h3>
            </div>
          </WrapperColorLineText>
          <p><MultilingualText tKey="process-takes" area="equal_salary" /></p>
            <h2><MultilingualText tKey="why-equal" area="equal_salary" /></h2>
          <p><MultilingualText tKey="want-be-fait" area="equal_salary" /></p>
        </WrapperDescriptionSalary>
      </Col>

      </Row>
      <Row>
        <Col sm={ 12 } md={ 12 } lg={ 6 }>
          <ImageBox>
            <LazyImage src={ EqualSalaryBannerSecond } alt="" sccss={ DescriptionImage } width="61rem" />
          </ImageBox>
        </Col>

      </Row>
    </Container>
  </>;
};
