import React, { FunctionComponent, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Container from '../container';
import { languageSelect } from 'features/language/'
import GTM from 'features/services';
import LazyImage from 'view/components/content/LazyImage';

import image from 'assets/images/shared/competence-test-banner.png';
import {
  Banner,
  Header,
  Text,
  ImageBox,
  Image,
  PMIButton,
  ArrowIcon,
  ContnetRow,
  TextCol,
  ImageCol,
} from './styles';
export const CompetencesTestBannerComponent: FunctionComponent<ICompetencesTestBannerComponentProps> = (props) => {
  const { lang } = useSelector(languageSelect);
  const testLink = `${lang === 'en' ? '/en/' : '/'}test`;
  const onButtonClick = () => {
    window.location.href = testLink;
    GTM('homeOffersBlock', getMultilingualItem('testBannerHeader.button'), testLink);
  };

  const { t } = useTranslation("home");
  const getMultilingualItem = (key) => t(key);

  return <Banner borderTop={props.borderTop} borderBottom={props.borderBottom}>
    <Container>
      <ContnetRow alignItems="center" >
        <TextCol lg={6} md={12} sm={12} >
          <Header>{getMultilingualItem('testBannerHeader.header')}</Header>
          <Text>{getMultilingualItem('testBannerHeader.text')}</Text>
          <PMIButton onClick={onButtonClick}>
            {getMultilingualItem('testBannerHeader.button')}
            <ArrowIcon />
          </PMIButton>
        </TextCol>
        <ImageCol lg={6}>
          <ImageBox>
            <LazyImage src={image} sccss={Image} alt="" height="650px" />
          </ImageBox>
        </ImageCol>
      </ContnetRow>
    </Container>
  </Banner>;

};