import React, { FunctionComponent, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { ISubmMenuProps } from './interfaces';
import { SubMenuItem, StyledDropdownIcon } from './styles';
import Dropdown from 'view/components/content/dropdown';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'

export const SubMenu: FunctionComponent<ISubmMenuProps> = ({ children, title, link }) => {

  const [subMenuActive, setSubMenuActive] = useState(false);

  const match = useRouteMatch(link);

  const active = match !== null;

  return <SubMenuItem active={subMenuActive || active}>
    <Dropdown.Wrapper onToggle={(open: boolean) => {
      setSubMenuActive(open);
    }}>
      <Dropdown.Trigger>
        <MultilingualText tKey={title} area="common" />
        <StyledDropdownIcon />
      </Dropdown.Trigger>
      <Dropdown.Popup>
        {children}
      </Dropdown.Popup>
    </Dropdown.Wrapper>
  </SubMenuItem>;
};