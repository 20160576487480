import React, { FunctionComponent, useState } from 'react';

import { ScrollButtonIcon } from './styles';

export const ScrollUpButton: FunctionComponent = () => {

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };


  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop);


  return (
    <ScrollButtonIcon onClick={ scrollTop } show={ showScroll }></ScrollButtonIcon>
  );
};