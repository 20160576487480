import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { languageSelect } from 'features/language';
import GTM from 'features/services';
import { bootstrapSelect } from 'features/bootstrap';
import { useBreakpoints, useTopImage } from 'features/hooks';

import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import Slider from 'view/components/content/Slider';
import { Banner } from 'view/pages/job-offers/list/Banner/Banner';
import { Benefits } from './components/Benefits/Benefits';
import MoreDivisions from './components/MoreDivisions';
import Offers from './components/Offers';
import TeamDescription from './components/TeamDescription';
import TopBanner from './components/TopBanner';

import { Image, ImageContainer, Line, Main, SliderContainer, BannerBox } from './styles';
import { ITeamContent } from './interface'

const initialState: ITeamContent = {
  name: '',
  title: '',
  offersNumber: 0,
  sentence: '',
  paragraph: '',
  bannerImage: '',
  bannerImageMobile: '',
  locations: '',
  decorativeImage: '',
  decorativeImageMobile: '',
  divisions: [],
  testimonials: [],
}

export const TeamComponent: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const location = useLocation();
  const { isSmall } = useBreakpoints();
  const [team, setTeam] = useState<ITeamContent>(initialState)
  const [filtersId, setFiltersId] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const backgroundMobile = useTopImage(team.bannerImageMobile);
  const backgroundDesktop = useTopImage(team.bannerImage);
  const parts = location.pathname.split('/');
  const name = parts[parts.length - 1];
  const bootstrap = useSelector(bootstrapSelect);

  useEffect(() => {
    GTM('teamLoaded', undefined, undefined, location.pathname)
  }, [lang])

  useEffect(() => {
    if (!bootstrap.data.cms || !bootstrap.data.cms.teams) return;
    const actualTeam = bootstrap.data.cms.teams.find(x => x && x.name === name);
    setTeam(actualTeam);
    setFiltersId(actualTeam.team_ids);
  }, [bootstrap.data, name])

  useEffect(() => {
    setFilter(`&teams=${filtersId}`);
  }, [filtersId])

  if (!team)
    return <Main></Main>

  return <Main>
    <TopBanner teamsFilter={filter} numberOfOffers={team.offersNumber} title={team.title} bannerImage={team.bannerImageMobile && isSmall ? backgroundMobile : backgroundDesktop} />
    <TeamDescription sentence={team.sentence} locations={team.locations}
      paragraph={team.paragraph} divisions={team.divisions} />
    <ImageContainer type="large">
      <LazyloadBackgroundImage height="100%" image={team.decorativeImageMobile && isSmall ? team.decorativeImageMobile : team.decorativeImage}>
        <Image backgroundImage={team.decorativeImageMobile && isSmall ? team.decorativeImageMobile : team.decorativeImage} />
      </LazyloadBackgroundImage>
    </ImageContainer>
    {team.testimonials.length > 0 && <SliderContainer type="large">
      <LazyloadBackgroundImage image={team.testimonials[0].image}>
        <Slider slides={team.testimonials} isQuoteIconVisible={false} />
      </LazyloadBackgroundImage>
    </SliderContainer>}
    {team.offersNumber === 0 ?
      <BannerBox>
        <Banner tKey="noOffersBannerText" area="teams" />
      </BannerBox> :
      <Offers query={`teams=${filtersId}&limit=6`} teamsFilter={filter} />
    }
    <Line />
    <Benefits />
    <Line />
    <MoreDivisions name={team.name} />
  </Main>;

};
