import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { languageSelect } from 'features/language/'
import { bootstrapSelect } from 'features/bootstrap';
import Container from 'view/components/content/container';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';

import TopBanner from './components/TopBanner';
import RecruitmentProcess from './components/RecruitmentProcess';
import Slider from './components/Slider';
import FAQ from './components/FAQ';
import JoinUsBanner from './components/JoinUsBanner';
import image from 'assets/images/recruitment/recruitment-image.jpg';
import imageMobile from 'assets/images/recruitment/recruitment-image-mobile.jpg';

import { IRecruitment } from './interfaces';
import { Main, Header, Image, SliderHeader, Title, BannerBox } from './styles';

export const Recruitment: FunctionComponent = () => {
  const location = useLocation();
  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);
  const [recruitment, setRecruitment] = useState<IRecruitment[]>([]);

  useEffect(() => {
    GTM('recruitmentLoaded', undefined, undefined, location.pathname);
  }, [lang])
  useEffect(() => {
    if (!bootstrap.data.cms)
      return;
    setRecruitment(bootstrap.data.cms.recruitment);
  }, [bootstrap.data.cms]);

  return <Main>
    <TopBanner />
    <Container>
      <Row>
        <Col col={12}>
          <Header><MultilingualText tKey="description" area="recruitment" /></Header>
        </Col>
      </Row>
    </Container>

    <Container type="large">
      <Row>
        <Col col={12}>
          <LazyloadBackgroundImage image={image}>
            <Image photo={image} photoMobile={imageMobile} />
          </LazyloadBackgroundImage>
        </Col>
      </Row>
    </Container>
    <RecruitmentProcess />
    <Container>
      <SliderHeader><MultilingualText tKey="sliderHeader" area="recruitment" /></SliderHeader>
    </Container>
    {recruitment.map((item) =>
      <div key={item.key}>
        <Container>
          <Title>{item.title}</Title>
        </Container>
        <Slider recruiters={item.recruiters} title={item.title} />
      </div>
    )}

    <FAQ />
    <BannerBox>
      <JoinUsBanner tKey="banner" area="recruitment" link={`${lang === 'en' ? '/en' : ''}/job-offers`} />
    </BannerBox>
  </Main >

};
