export const howWeWorkPage = {
    howWeWorkLoaded :{
        event: 'virtualPageView',
        virtualPageView: '/how-we-work',
    },
    howWeWorkNavigation: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    howWeWorkLinkTo: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    },
    howWeWorkOffers: {
        event: 'backendMicroconversion',
        category: 'Clicks - button',
    }
}