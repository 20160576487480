import styled, { keyframes, css } from 'styled-components';
import Container from 'view/components/content/container';
import { Col } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';

interface ICustomPagingComponent {
  active?: boolean;
}

export const ContentContainer = styled(Container)`
    white-space: break-spaces;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;

    ${media.up('lg')} {
      padding-top: 8.125rem;
      padding-bottom: 8.125rem;
    }

    a {
      color: ${(props) => props.theme.colors.links};
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.563rem;
      letter-spacing: -0.2px;

      ${media.down('md')} {
        width: 100%;
      }
      ${media.down('sm')} {
        text-decoration: none;
        margin-left: -0.938rem;
      }
    }
  `,
  ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  MainImageCol = styled(Col)`
    padding-right: 1.25rem;
  `,
  ImageCol = styled(Col)`
    margin-left: 0;

    ${media.down('sm')} {
      padding-left: 0;
    }
  `,
  Header = styled.h2`
    margin-bottom: 3.125rem !important;

    ${media.down('md')} {
      margin-bottom: 1.875rem !important;
    }
  `,
  MainImage = styled.div<{ backgroundImage: string }>`
    width: 100%;
    max-width: 23.125rem;
    padding-top: 100%;
    background-image: url('${(props) => props.backgroundImage}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
  QuoteImg = styled.img`
    width: 3.75rem;
    height: auto;
    margin-bottom: 3.125rem;

    ${media.down('md')} {
      margin-bottom: 0;
      margin-left: 0.625rem;
    }
    ${media.down('sm')} {
      width: 1.875rem;
    }
  `,
  QuoteText = styled.h3`
    width: 38rem;
    font-weight: normal;
    letter-spacing: normal;

    ${media.down('md')} {
      padding-top: 1.875rem;
      width: 100%;
    }
    ${media.down('sm')} {
      line-height: normal;
    }
  `,
  SmallText = styled.p`
    width: 94%;
    max-width: 31.25rem;
    margin-top: 1.875rem !important;

    ${media.down('md')} {
      margin-top: 1.875rem !important;
    }
  `,
  Paging = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: -0.5rem;
  `,
  PagingComponentBox = styled.div`
    padding: 0.5rem 0.3125rem 0.5rem 0.3125rem;
    cursor: pointer;
  `,
  CustomPagingComponent = styled.div<ICustomPagingComponent>`
    width: 3.75rem;
    height: 0.313rem;
    background-color: ${(props) => props.theme.colors.lines};
    transition: all 0.3s;

    ${media.down('sm')} {
      width: 2.5rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackground};
    }

    ${(props) =>
      props.active &&
      `
      background-color: ${props.theme.colors.blueButtonBackground};
      
      `}
  `,
  Text = styled.div`
    height: auto;
    width: 100%;
    min-height: 13.5rem;
    ${media.down('md')} {
      min-height: 17rem;
    }
  `,
  TextCol = styled(Col)`
    ${media.down('md')} {
      margin-left: 1.5rem;
    }
  `;
