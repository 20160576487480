export const recruitmentData = {
  recruitmentLoaded: {
    event: 'virtualPageView',
    virtualPageView: '/recruitment',
  },
  recruitmentLoadedEN: {
    event: 'virtualPageView',
    virtualPageView: '/en/recruitment',
  },
  recruitmentLinkedinClick: {
    event: 'backendMicroconversion',
    category: 'Clicks - Linkedin profile',
  },
  recruitmentFAQ: {
    event: 'backendMicroconversion',
    category: 'Clicks - FAQ',
  },
  recruitmentSliderNav: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  recruitmentOffersBannerClick: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
};
