import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/';
import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

import {
  FooterTop,
  StyledFooter,
  StyledLogo,
  FooterBottom, FooterBottomLink, FooterBottomButton, OffersButton, OfferButtonLink, AllOffers
} from './styles';
import Menu from './menu';

import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import { ReactComponent as OfferIcon } from 'assets/images/header/icon-offer.svg';

export const Footer: FunctionComponent = () => {

  const { lang } = useSelector(languageSelect);
  const baseUrl = `${lang === 'en' ? '/en' : ''}/`;
  const privacyPolicyUrl = `${baseUrl}privacy-policy`;
  const contactUrl = `${baseUrl}contact`;
  const jobOffersUrl = `${baseUrl}job-offers`;

  function handleClick(e) {
    e.preventDefault();

    return false;
  }

  const { t } = useTranslation("common");
  const getMultilingualItem = (key) => t(key);

  return <StyledFooter>
    <FooterTop>
      <Container>
        <Row>
          <Col className={"top-col"} mdOrder={1} order={2} xs={12} md={4}>
            <Row alignItems={"center"}>
              <Col xs={6} md={12}>
                <StyledLogo>
                  <Link to={baseUrl} onMouseUp={() => GTM('footerLogo', 'Logo', baseUrl)}>
                    <Logo />
                  </Link>
                </StyledLogo>
              </Col>
            </Row>
          </Col>
          <Col className={"top-col"} mdOrder={2} order={1} xs={12} md={8}>
            <Menu />
          </Col>
        </Row>
      </Container>
    </FooterTop>
    <FooterBottom>
      <Container>
        <Row alignItems={"center"}>
          <Col xsOrder={3} order={1} xs={12} md={4}>
            <span>© PHILIP MORRIS PRODUCTS S.A.</span>
          </Col>
          <Col xsOrder={2} order={2} xs={12} md={4}>
            <FooterBottomLink to={privacyPolicyUrl} onMouseUp={() => GTM('footerLink', 'Privacy policy', privacyPolicyUrl)}>
              <span><MultilingualText tKey="privatePolicy" area="common" /></span>
            </FooterBottomLink>
            <FooterBottomLink to={contactUrl} onMouseUp={() => GTM('footerLink', 'Contact', contactUrl)}>
              <span><MultilingualText tKey="contact" area="common" /></span>
            </FooterBottomLink>
            <FooterBottomButton className="ot-sdk-show-settings test" onClick={handleClick}>
              <span><MultilingualText tKey="cookieSettingsBanner" area="common" /></span>
            </FooterBottomButton>
          </Col>
          <Col xsOrder={1} order={3} xs={12} md={4}>
            <OfferButtonLink to={jobOffersUrl} onMouseUp={() => { window.scrollTo(0, 0); GTM('footerLink', getMultilingualItem('allJobOffers'), jobOffersUrl) }}>
              <OffersButton>
                <OfferIcon width={20} height={20} /><AllOffers><MultilingualText tKey="allJobOffers" area="common" /></AllOffers>
              </OffersButton>
            </OfferButtonLink>
          </Col>
        </Row>
      </Container>
    </FooterBottom>
  </StyledFooter >;
};
