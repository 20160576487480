import styled from 'styled-components';

export const StyledLanguage = styled.div`
    width: 40%;
    max-width: 9.126rem;
    display: flex;
    justify-content: space-between;
    margin: 3.875rem auto 0 auto;

    a {
      text-align: center;
      flex: 1 auto;
      font-weight: 500;
      letter-spacing: -0.2px;
      color: ${(props) => props.theme.colors.lines};
      text-decoration: none;

      &.active {
        color: ${(props) => props.theme.colors.blueButtonBackground};
      }
    }
  `,
  LangItem = styled.div<{ active: boolean }>`
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.2px;
    cursor: pointer;
    padding: 0 0.2rem;
    color: ${(props) => props.theme.colors.lines};
    ${(props) =>
      props.active &&
      `
    color: ${props.theme.colors.blueButtonBackground};
    `}
  `;
