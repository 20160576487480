import React, { FunctionComponent } from 'react';

import GTM from 'features/services';

import { ContentContainer, Header, ImageBox, MainImage, Linkedin, Position, SmallHeader, HeaderName } from './styles';
import { IRecruitersSlideComponentProps } from 'view/pages/recruitment/interfaces';
import linkedinLogo from 'assets/images/recruitment/linkedin.png';

export const SlideComponent: FunctionComponent<IRecruitersSlideComponentProps> = ({ slide, activeSlide, index, slidesToShow }) => {
  const { team, image, recruiterName, position, linkedinId, header } = slide;
  const linkedinLink = `https://www.linkedin.com/in/${linkedinId}`;

  const onLinkedinClick = () => {
    GTM('recruitmentLinkedinClick', `${header}: ${team} | ${position} | ${recruiterName}`, linkedinLink);
  };

  return (
    <ContentContainer isLastVisible={index >= activeSlide + slidesToShow || index < activeSlide}>
      <SmallHeader>{header}</SmallHeader>
      <Header>{team}</Header>
      <ImageBox>
        <MainImage backgroundImage={image} />
      </ImageBox>
      <HeaderName>{recruiterName}</HeaderName>
      <Position>{position}</Position>
      <a href={linkedinLink} onMouseUp={onLinkedinClick} target="_blank" rel="noopener noreferrer">
        <Linkedin src={linkedinLogo} /></a>
    </ContentContainer>
  );
};
